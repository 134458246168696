import React, { ReactNode, useState } from 'react'
import { Box, makeStyles, Theme } from '@material-ui/core'
import LProgressBar from '../../UProgressBar'
import LButton from '../../UButton'
import ULike from '../../ULike'
import LTitle from '../../UTitle'

const useStyle = makeStyles((theme: Theme) => ({
    root: () => ({
        cursor: 'pointer',
        background: '#FFFFFF',
        border: '1px solid #EBEDEF',
        boxSizing: 'border-box',
        borderRadius: 4,
        padding: '32px 24px',
        width: '100%',
        margin: '0 auto',

        [theme.breakpoints.up('md')]: {
            paddingBottom: 24,
            maxWidth: 'none',
            width: '100%',
        },
    }),
    title: {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 24,
        lineHeight: '120%',
        margin: 0,
    },
    heart: {
        marginLeft: 10,
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    footer: {
        marginTop: 32,
        display: 'flex',
        alignItems: 'center',
    },
    progressBar: {
        flexGrow: 1,
    },
    content: {},
    button: {
        display: 'none',
        marginLeft: 32,
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
    },
}))
export interface TypeTwoProps {
    title: string
    suptitle?: ReactNode
    subtitle?: string
    size?: number
    completed?: boolean
    children?: ReactNode
    onMore?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    onChange?(event: React.MouseEvent): void
    value?: boolean
    fillProgressBar?: string
}

const TypeTwo: React.FC<TypeTwoProps> = ({
    title,
    suptitle,
    subtitle,
    size,
    children,
    completed = false,
    onMore,
    onChange,
    value,
    fillProgressBar = '#3ab2c9',
}: TypeTwoProps) => {
    const classes = useStyle({ completed })
    const [isActive, setIsActive] = useState(false)
    const handleActive = () => {
        setIsActive(!isActive)
    }

    return (
        <Box className={classes.root}>
            <Box className={classes.header}>
                <LTitle.H3 variant="h3v2">{title}</LTitle.H3>
                <ULike
                    value={value || isActive}
                    onChange={onChange || handleActive}
                />
            </Box>
            <Box className={classes.footer}>
                <Box className={classes.progressBar}>
                    <LProgressBar
                        fill={fillProgressBar}
                        size={size}
                        suptitle={suptitle}
                        subtitle={subtitle}
                    />
                </Box>
                {onMore && (
                    <Box className={classes.button}>
                        {!completed && (
                            <LButton.Filled onClick={onMore}>
                                Подробнее
                            </LButton.Filled>
                        )}
                        {completed && (
                            <LButton.Filled2 onClick={onMore}>
                                Пройти повторно
                            </LButton.Filled2>
                        )}
                    </Box>
                )}
            </Box>
            {children && <Box className={classes.content}>{children}</Box>}
        </Box>
    )
}

export default TypeTwo
