import { makeStyles, Theme } from '@material-ui/core/styles'

interface StylesProps {
    withBorder?: boolean
    withBackground?: boolean
    stretched?: boolean
    center?: boolean
}
interface Collector {
    [name: string]: string | number
}
enum Padding {
    largeScreen = 100,
    smallScreen = 50,
}
const useStyles = makeStyles((theme: Theme) => ({
    flex: {
        display: 'flex ',
        flexDirection: 'column',
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
        },
    },
    textWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    textButton: {
        marginTop: '40px',
    },
    section: {
        padding: '64px 0',
        [theme.breakpoints.up('md')]: {
            padding: '80px 0 ',
        },
        [theme.breakpoints.up('xl')]: {
            padding: '132px 0 ',
        },
    },
    sectionTop: {
        padding: '0 0 64px 0',
        [theme.breakpoints.up('md')]: {
            padding: '0 0 80px 0 ',
        },
        [theme.breakpoints.up('xl')]: {
            padding: '0 0 132px 0 ',
        },
    },
    flexImg: {
        maxWidth: ({ withBackground, withBorder }: StylesProps) => {
            if (withBackground) {
                return 372 - Padding.smallScreen * 2
            }
            if (withBorder) {
                return ''
            }
            return 372
        },
        maxHeight: 271,
        width: '100%',
        [theme.breakpoints.up('md')]: {
            maxHeight: 369,
            width: ({ withBackground, withBorder }: StylesProps) => {
                if (withBackground) {
                    return 506 - Padding.largeScreen * 2
                }
                if (withBorder) {
                    return 506 - 2
                }
                return 506
            },
        },
    },
    img: {
        maxWidth: '100%',
    },
    marginRight: {
        [theme.breakpoints.up('lg')]: {
            marginRight: 40,
        },
        marginRight: 0,
    },
    marginLeft: {
        [theme.breakpoints.up('lg')]: {
            marginLeft: 40,
        },
        marginLeft: 0,
    },
    headerBlock: {
        marginBottom: 24,
    },
    mbDownLg: {
        marginBottom: 40,
        [theme.breakpoints.up('lg')]: {
            marginBottom: 0,
        },
    },
    firstOrderDownLg: {
        order: 1,
        [theme.breakpoints.up('lg')]: {
            order: 1,
        },
    },
    secondOrderDownLg: {
        order: 2,
        [theme.breakpoints.up('lg')]: {
            order: 1,
        },
    },
    imgBlock: ({
        withBorder,
        withBackground,
        stretched,
        center,
    }: StylesProps) => {
        const collector: Collector = {
            border: '',
            padding: '0px',
            background: '',
            borderRadius: 0,
        }
        if (stretched) collector.width = '100%'
        if (center) collector.margin = '0 auto'
        if (withBorder) {
            collector.border = '1px solid #B8B8C3 '
            collector.padding = '5px '
            collector.borderRadius = 4
        }
        if (withBackground) {
            collector.background = `rgb(235, 237, 239)`
            collector.padding = Padding.largeScreen
            collector.borderRadius = 5
        }
        return collector
    },
    imgFlexBlock: ({ withBorder, withBackground }: StylesProps) => ({
        maxWidth: '372px',
        width: '100%',
        background: withBackground ? `rgb(235, 237, 239)` : '',
        borderRadius: withBackground || withBorder ? 4 : '',
        border: withBorder ? '1px solid #B8B8C3' : '',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.up('xs')]: {
            padding: () => {
                if (withBorder && !withBackground) {
                    return 1
                }
                if (withBackground) {
                    return Padding.smallScreen
                }
                return ''
            },
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '506px',
            width: '100%',
            padding: () => {
                if (withBorder && !withBackground) {
                    return 1
                }
                if (withBackground) {
                    return Padding.largeScreen
                }
                return ''
            },
        },
    }),
    textBlock: {
        padding: '0 0 24px 0',
    },
}))
export default useStyles
