import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: '82px 72px',
        background: '#FFB427',
        display: 'inline-block',
        [theme.breakpoints.down('sm')]: {
            padding: '30px 12px',
        },
    },
    title: {
        fontWeight: 600,
        marginBottom: 24,
    },
    certificateIdBlock: {
        display: 'flex ',
        marginBottom: 40,
    },
    certificateElem: {
        alignItems: 'center',
        fontSize: 15,
        lineHeight: '140%',
        letterSpacing: 0.1,
        fontWeight: 500,
    },
    certificateId: {
        marginRight: 32,
    },
    certificateDate: {},
    nameBlock: {},
    nameBlockSubtitle: {
        fontWeight: 500,
        fontSize: 10,
        lineHeight: '160%',
        marginBottom: 8,
        marginTop: 40,
        [theme.breakpoints.up('md')]: {
            fontSize: 15,
            marginTop: 88,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 20,
            marginTop: 96,
        },
    },
    nameBlockTitle: {
        fontWeight: 600,
        marginBottom: 40,
        [theme.breakpoints.up('md')]: {
            marginBottom: 88,
            fontWeight: 400,
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: 96,
            fontWeight: 400,
        },
    },
    wrapperCards: {
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
            marginBottom: 88,
        },
    },
    cardBlock: {
        borderRadius: 4,
        marginBottom: 88,
        [theme.breakpoints.up('lg')]: {
            maxWidth: 384,
            marginBottom: 0,
        },
    },
    cardBlockFirst: {
        padding: 24,
        background: 'rgba(0, 0, 0, 0.06)',
        marginBottom: 32,
        [theme.breakpoints.up('lg')]: {
            padding: 40,
            marginBottom: 0,
            marginRight: 32,
        },
    },
    cardBlockSecond: {
        padding: 24,
        border: '1px solid rgba(0, 0, 0, 0.14)',
    },
    firstCardSubtitle: {
        fontWeight: 500,
        fontSize: 10,
        lineHeight: '140%',
        [theme.breakpoints.up('md')]: {
            fontSize: 13,
        },
    },
    firstCardSubtitle2: {
        fontWeight: 500,
        fontSize: 10,
        lineHeight: '140% ',
        marginBottom: 16,
        marginTop: 16,
        [theme.breakpoints.up('md')]: {
            fontSize: 20,
        },
    },
    firstCardTitle1: {
        width: 175,
        marginBottom: 8,
        marginTop: 8,
        [theme.breakpoints.up('md')]: {
            minWidth: 281,
        },
    },
    firstCardTitle2: {
        minWidth: 252,
        [theme.breakpoints.up('md')]: {
            minWidth: 349,
        },
    },
    firstCardAchievements: {
        display: 'flex',
        alignItems: 'center',
    },
    firstCardAchievement: {
        padding: '8px 12px',
        border: '1px solid rgba(17, 17, 17, 0.5) ',
        borderRadius: 2,
        fontWeight: 500,
        fontSize: 10,
        lineHeight: '140%',
        letterSpacing: 0.2,
        color: '#000000',
    },
    firstCardPlus: {
        display: 'block',
        fontWeight: 500,
        fontSize: 13,
        lineHeight: '140%',
        letterSpacing: 0.2,
        color: '#4C4C52',
        margin: '0 10px',
    },
    footBlock: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('xs')]: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
        },
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        [theme.breakpoints.up('xl')]: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
        },
    },
    footBlockInitials: {
        marginBottom: 40,
        [theme.breakpoints.up('md')]: {
            marginBottom: 0,
        },
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
            marginBottom: 96,
        },
        [theme.breakpoints.up('xl')]: {
            display: 'flex',
            marginBottom: 0,
        },
    },
    footBlockInitial: {
        paddingTop: 16,
        borderTop: '1px solid rgba(0,0,0,0.2)',
        marginBottom: 80,
        width: '100%',
        minWidth: 260,
        '&:last-child': {
            marginBottom: 0,
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: 40,
        },
        [theme.breakpoints.up('md')]: {
            width: 325,
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: 0,
            marginRight: 127,
            '&:last-child': {
                marginRight: 0,
            },
        },
    },
    footBlockName: {
        fontWeight: 600,
        marginBottom: 16,
        [theme.breakpoints.up('md')]: {
            fontWeight: 500,
        },
    },
    footBlockPosition: {
        fontWeight: 500,
        fontSize: 13,
        lineHeight: '140%',
        letterSpacing: 0.2,
    },
    footBlockLogo: {
        maxWidth: 142,
        height: 63,
        flexBasis: '100%',
        [theme.breakpoints.up('md')]: {
            marginLeft: '88px',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: 194,
            marginLeft: 0,
        },
        [theme.breakpoints.up('xl')]: {
            marginTop: 25,
        },
    },
}))
export default useStyles
