import React from 'react'
import { PositionType } from '../../../types'
import useStyles from './style'

interface NotificationIndicatorProps extends PositionType {
    count: number
    color: string
}
/**
 * Индификатор уведомлений
 *
 * @param count - Количество уведомлений
 * @param color - Цвет индификатора
 * @param right/top/left/bottom - расположение относительно родительского блока
 */
const NotificationIndicator = ({
    count,
    top = '-10px',
    right = '-10px',
    left = '',
    bottom = '',
    color,
}: NotificationIndicatorProps): React.ReactElement => {
    const classes = useStyles({ top, right, left, bottom, color })
    return (
        <>
            {count > 0 && <span className={classes.notification}>{count}</span>}
        </>
    )
}
export default NotificationIndicator
