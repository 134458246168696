import React, { useEffect } from 'react'
import {
    useCertificates,
    useCertificatesManager,
} from '@/services/certificates'
import Certificate from '../../../components/ui/UCertificate'
import useStyles from './style'
import LTitle from '../../../components/ui/UTitle'

const CertificatePM = (): JSX.Element => {
    const classes = useStyles()
    const CertificatesManager = useCertificatesManager()
    const certificates = useCertificates()
    useEffect(() => {
        CertificatesManager.get()
    }, [])
    return (
        <>
            <LTitle.H2 variant="h2v3" smFont={24} className={classes.title}>
                Сертификаты
            </LTitle.H2>
            <div className={classes.root}>
                {certificates.list.map((certificate) => (
                    <div
                        key={certificate.id}
                        className={classes.certificateBlock}
                    >
                        <Certificate certificate={certificate} />
                    </div>
                ))}
            </div>
        </>
    )
}
export default CertificatePM
