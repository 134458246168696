import { makeStyles } from '@material-ui/core/styles'

interface Props {
    size: number
}

const useStyles = makeStyles(() => ({
    root: {
        width: (props: Props) => `${props.size}px`,
        height: (props: Props) => `${props.size}px`,
        position: 'relative',
    },

    avatar: {
        width: (props: Props) => props.size && `${props.size}px`,
        height: (props: Props) => props.size && `${props.size}px`,
    },

    notification: {
        position: 'absolute',
        top: '-10px',
        right: '-10px',
        width: '28px',
        height: '28px',
        background: '#de1f06',
        borderRadius: '32px',
        textAlign: 'center',
        lineHeight: '28px',
        fontWeight: 500,
        fontSize: '13px',
        letterSpacing: '0.2px',
        color: '#fff',
    },
}))
export default useStyles
