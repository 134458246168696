import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import routes from '@/routes'
import Layout from '../layout'

const LK = (): React.ReactElement => (
    <Layout.Lk>
        <Switch>
            <Route exact path={routes.lk.main.path}>
                <Redirect to={routes.lk.homework.path} />
            </Route>
            <Route {...routes.lk.homework} />
            <Route {...routes.lk.calendar} />
            <Route {...routes.lk.certificate} />
            <Route {...routes.lk.notification} />
            <Route {...routes.lk.myth} />
        </Switch>
    </Layout.Lk>
)
export default LK
