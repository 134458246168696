import React, { ReactNode } from 'react'
import { makeStyles, Theme } from '@material-ui/core'
import UStatus from '../../UStatus'

export interface ITypeOne extends React.HTMLAttributes<HTMLDivElement> {
    picture: ReactNode
    title: string
    text: string
    bottom?: ReactNode
    statusText?: string
    statusColor?: string
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        cursor: 'pointer',
        background: '#F6F7F9',
        border: '1px solid #EBEDEF',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '45px 0 45px 18px',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row-reverse',
        },
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '32px',
            paddingLeft: '0',
        },
    },
    picture: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        height: '300px',
        width: '275px',
        objectFit: 'cover',
        marginLeft: 'auto',
        '& > *': {
            width: 254,
            height: 'auto',
        },
        [theme.breakpoints.up('md')]: {
            marginRight: 0,
        },
        [theme.breakpoints.down('sm')]: {
            alignSelf: 'center',
            marginLeft: '0',
        },
    },
    content: {
        marginTop: 32,
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
            marginRight: 31,
        },
        [theme.breakpoints.down('sm')]: {
            padding: '0 18px',
        },
    },
    tag: {},
    title: {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 24,
        lineHeight: '120%',
        marginBottom: 0,
        marginTop: 16,
    },
    text: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 15,
        lineHeight: '140%',
        letterSpacing: 0.1,
        color: '#4C4C52',
        overflow: 'hidden',
        marginTop: 16,
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '65px',
        },
        [theme.breakpoints.up('md')]: {
            width: '350px',
            height: '65px',
        },
    },
    bottom: {
        marginTop: 20,
    },
}))

/**
 * TypeOne
 *
 * @param picture - картинка (Компонент или верстка)
 * @param title - Заголовок карточки
 * @param text - Текст карточки
 * @param bottom - Нижний элемент (Компонент или верстка)
 */

const TypeOne: React.FC<ITypeOne> = ({
    picture,
    title,
    text,
    bottom = null,
    statusText,
    statusColor,
    ...rest
}: ITypeOne) => {
    const classes = useStyles()
    return (
        <div {...rest} className={classes.root}>
            <div className={classes.picture}>{picture}</div>
            <div className={classes.content}>
                <div className={classes.tag}>
                    {statusText && (
                        <UStatus background={statusColor}>{statusText}</UStatus>
                    )}
                </div>
                <div>
                    <h5 className={classes.title}>{title}</h5>
                    <span className={classes.text}>{text}</span>
                    <div className={classes.bottom}>{bottom}</div>
                </div>
            </div>
        </div>
    )
}
export default TypeOne
