import React from 'react'
import { Link } from 'react-router-dom'
import UButton from '../../components/ui/UButton'
import useStyles from './style'
import error404 from './svg/404.svg'
import LoginAnd404Layout from '../layout/LoginAnd404Layout'

interface INotFound {
    variant: 'cloud' | 'girl'
}
const NotFound: React.FC<INotFound> = ({ variant }: INotFound): JSX.Element => {
    const classes = useStyles()
    return (
        <LoginAnd404Layout variant={variant} needHeader>
            <>
                <img src={error404} alt="404" />
                <p className={classes.description}>
                    Страница не найдена, либо еще не создана
                </p>
                <Link to="/">
                    <UButton size="large" view="ghost">
                        На главную
                    </UButton>
                </Link>
            </>
        </LoginAnd404Layout>
    )
}

export default NotFound
