import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
    section: {
        padding: '0',
        marginTop: '64px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            padding: '80px 16px',
            margin: '0',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '80px 0',
            maxWidth: '710px',
            margin: 'auto',
        },
        [theme.breakpoints.up('xl')]: {
            maxWidth: 'none',
            padding: '80px 0',
        },
    },
    root: {
        marginTop: 32,
        paddingBottom: 80,
        [theme.breakpoints.up('xl')]: {
            paddingBottom: 132,
        },
    },
    titleBlock: {
        marginBottom: 16,
        [theme.breakpoints.up('md')]: {
            display: 'flex ',
            alignItems: 'center',
            marginBottom: 56,
        },
    },
    arrowBackBlock: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block ',
            with: 40,
            height: 40,
            marginRight: 16,
        },
    },
    lessonBlock: {
        marginTop: 32,
    },
    tabPanel: {
        marginBottom: 32,
        [theme.breakpoints.up('md')]: {
            marginBottom: 80,
        },
        [theme.breakpoints.up('xl')]: {
            marginBottom: 60,
        },
    },
    rowBlock: {
        marginTop: -32,
        [theme.breakpoints.up('md')]: {
            marginTop: -40,
        },
        [theme.breakpoints.up('xl')]: {
            marginTop: -60,
        },
    },
    colBlock: {
        padding: '8px !important',
        marginTop: 32,
        [theme.breakpoints.up('md')]: {
            padding: '24px !important',
            marginTop: 40,
        },
        [theme.breakpoints.up('xl')]: {
            marginTop: 60,
        },
    },
}))
export default useStyles
