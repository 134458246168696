import BaseApi from '@/api/BaseApi'
import { AxiosPromise } from 'axios'
import type { TLink, Th5pData } from '@/api/BaseApi'
import { TContent } from '@/utils/Runner'

export type TLessonStatus = 'notStarted' | 'inProgress' | 'passed' | 'notPassed'

export type TLessonEntity = {
    lessonId?: number
    title: string
    status: TLessonStatus
    h5pData: Th5pData[]
    id: string
    examples?: TLink[]
    recomendations?: TLink[]
    content: TContent
    favorited: boolean
    description?: string
}

const LessonApi = {
    get(id: string): AxiosPromise<TLessonEntity> {
        return BaseApi.get(`/lessons/${id}`)
    },
}

export { LessonApi }
