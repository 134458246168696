const VariantsTitles = {
    h1v1: {
        fontFamily: 'Graphik LC TT',
        smFont: 32,
        mdFont: 57,
        lgFont: 57,
        xlFont: 57,
        smWeight: 500,
        mdWeight: 500,
        lgWeight: 500,
        xlWeight: 500,
        smLineHeight: 1.2,
        mdLineHeight: 1.15,
        lgLineHeight: 1.15,
        xlLineHeight: 1.15,
        smLetterSpacing: -0.5,
        mdLetterSpacing: -0.3,
        lgLetterSpacing: -0.3,
        xlLetterSpacing: -0.3,
    },
    h2v1: {
        fontFamily: 'Graphik LC TT',
        smFont: 32,
        mdFont: 32,
        lgFont: 32,
        xlFont: 42,
        smWeight: 500,
        mdWeight: 500,
        lgWeight: 500,
        xlWeight: 500,
        smLineHeight: 1.2,
        mdLineHeight: 1.2,
        lgLineHeight: 1.2,
        xlLineHeight: 1.15,
        smLetterSpacing: -0.4,
        mdLetterSpacing: -0.3,
        lgLetterSpacing: -0.3,
        xlLetterSpacing: -0.3,
    },
    h2v2: {
        fontFamily: 'Graphik LC TT',
        smFont: 32,
        mdFont: 32,
        lgFont: 32,
        xlFont: 32,
        smWeight: 500,
        mdWeight: 500,
        lgWeight: 500,
        xlWeight: 500,
        smLineHeight: 1.2,
        mdLineHeight: 1.2,
        lgLineHeight: 1.2,
        xlLineHeight: 1.2,
        smLetterSpacing: -0.3,
        mdLetterSpacing: -0.3,
        lgLetterSpacing: -0.3,
        xlLetterSpacing: -0.3,
    },
    h2v3: {
        fontFamily: 'Graphik LC TT',
        smFont: 32,
        mdFont: 42,
        lgFont: 42,
        xlFont: 42,
        smWeight: 500,
        mdWeight: 500,
        lgWeight: 500,
        xlWeight: 500,
        smLineHeight: 1.2,
        mdLineHeight: 1.15,
        lgLineHeight: 1.15,
        xlLineHeight: 1.15,
        smLetterSpacing: -0.3,
        mdLetterSpacing: -0.4,
        lgLetterSpacing: -0.4,
        xlLetterSpacing: -0.4,
    },
    h3v1: {
        fontFamily: 'Graphik LC TT',
        smFont: 24,
        mdFont: 24,
        lgFont: 24,
        xlFont: 24,
        smWeight: 500,
        mdWeight: 500,
        lgWeight: 500,
        xlWeight: 500,
        smLineHeight: 1.2,
        mdLineHeight: 1.2,
        lgLineHeight: 1.2,
        xlLineHeight: 1.2,
        smLetterSpacing: -0.2,
        mdLetterSpacing: -0.2,
        lgLetterSpacing: -0.2,
        xlLetterSpacing: -0.2,
    },
    h3v2: {
        fontFamily: 'Graphik LC TT',
        smFont: 24,
        mdFont: 32,
        lgFont: 32,
        xlFont: 32,
        smWeight: 500,
        mdWeight: 500,
        lgWeight: 500,
        xlWeight: 500,
        smLineHeight: 1.2,
        mdLineHeight: 1.2,
        lgLineHeight: 1.2,
        xlLineHeight: 1.2,
        smLetterSpacing: -0.2,
        mdLetterSpacing: -0.3,
        lgLetterSpacing: -0.3,
        xlLetterSpacing: -0.3,
    },
    h3v3: {
        fontFamily: 'Graphik LC TT',
        smFont: 15,
        mdFont: 20,
        lgFont: 20,
        xlFont: 20,
        smWeight: 500,
        mdWeight: 500,
        lgWeight: 500,
        xlWeight: 500,
        smLineHeight: 1.4,
        mdLineHeight: 1.6,
        lgLineHeight: 1.6,
        xlLineHeight: 1.6,
        smLetterSpacing: 0.1,
        mdLetterSpacing: 0,
        lgLetterSpacing: 0,
        xlLetterSpacing: 0,
    },
}
export default VariantsTitles
