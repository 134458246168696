import { makeStyles, Theme } from '@material-ui/core/styles'
import bg from '../../assets/images/main-bg.jpg'

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        [theme.breakpoints.down('lg')]: {
            backgroundImage: `url(${bg})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
        },

        position: 'relative',
        zIndex: 1,
        paddingBottom: 24,
        [theme.breakpoints.up('md')]: {
            paddingBottom: 201,
        },
        [theme.breakpoints.up('lg')]: {
            paddingBottom: 84,
        },
    },

    overlay: {
        display: 'none',
        [theme.breakpoints.down('lg')]: {
            display: 'block',
            position: 'absolute',
            zIndex: -1,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
            background:
                'linear-gradient(180deg, rgba(15, 14, 14, 0.74) 0%, rgba(7, 7, 7, 0.44375) 45.83%, rgba(4, 4, 4, 0.31) 67.71%, rgba(0, 0, 0, 0.1) 100%)',
        },
    },

    headerblock: {
        position: 'relative',
        zIndex: 2,
    },
}))
export default useStyles
