import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { IListState } from '@/store/common'
import {
    FavoritesActions,
    TAddToFavoriteProps,
    TRemoveFromFavoriteProps,
} from './favorites.store'
import { TFavoriteData, TFavoriteType } from './favorites.api'

export const createFavoriteAddAction = <T extends TFavoriteType>(
    name: string,
    type: T
) => {
    const addToFavoriteAction = createAsyncThunk(
        `${name}/addToFavorite`,
        async (data: TFavoriteData[T], { dispatch }) => {
            const props = {
                type,
                data,
            } as TAddToFavoriteProps
            const result = await dispatch(FavoritesActions.add(props))
            if (result.meta.requestStatus === 'rejected') throw Error('error')
            return data.id
        }
    )
    const addToFavoriteHandler = (
        state: IListState<TFavoriteData[T]>,
        action: PayloadAction<TFavoriteData[T]['id']>
    ) => {
        state.list.forEach((item) => {
            if (item.id === action.payload) item.favorited = true
        })
    }
    return [addToFavoriteAction, addToFavoriteHandler] as const
}

export const createFavoriteRemoveAction = <T extends TFavoriteType>(
    name: string,
    type: T
) => {
    const removeFromFavoriteAction = createAsyncThunk(
        `${name}/removeFromFavorite`,
        async (id: TFavoriteData[T]['id'], { dispatch }) => {
            const props = {
                type,
                id,
            } as TRemoveFromFavoriteProps
            const result = await dispatch(FavoritesActions.remove(props))
            if (result.meta.requestStatus === 'rejected') throw Error('error')
            return id
        }
    )
    const removeFromFavoriteHandler = (
        state: IListState<TFavoriteData[T]>,
        action: PayloadAction<TFavoriteData[T]['id']>
    ) => {
        state.list.forEach((item) => {
            if (item.id === action.payload) item.favorited = false
        })
    }
    return [removeFromFavoriteAction, removeFromFavoriteHandler] as const
}
