import React from 'react'
import { Typography, Box } from '@material-ui/core'
import WTitleCourseLink from '@/components/widget/WTitleCourseLink'
import { useFavorites } from '@/services/favorites'
import { useHistory } from 'react-router-dom'
import routes from '@/routes'
import WCardCollection from '@/components/widget/WCard/Collection'
import WCourseLessons from '@/components/widget/WCourseLessons'
import UTabS, { UTab, UTabPanel } from '@/components/ui/UTabs'
import WCardCourse from '@/components/widget/WCard/Course'
import WCardLesson from '@/components/widget/WCard/Lesson'
import UGrid from '@/components/ui/UGrid'
import LTitle from '@/components/ui/UTitle'
import LArrowBack from '@/components/ui/UArrowBack'
import { useLessonsManager } from '@/services/lessons'
import { useCoursesManager } from '@/services/courses'
import { useCollectionsManager } from '@/services/collections'
import useStyles from './style'

const SFavorites: React.FC = () => {
    const classes = useStyles()
    const Favorites = useFavorites()
    const CoursesManager = useCoursesManager()
    const LessonsManager = useLessonsManager()
    const CollectionsManager = useCollectionsManager()

    const history = useHistory()

    const queryParams = new URLSearchParams(history.location.search)

    const tab = queryParams.get('tab') || 'course'

    const handlerChangeTab = (_e: any, value: string) => {
        queryParams.set('tab', value)
        history.replace({
            search: queryParams.toString(),
        })
    }
    return (
        <div className={classes.root}>
            <UGrid.UContainer>
                <Box className={classes.section}>
                    <div className={classes.titleBlock}>
                        <div className={classes.arrowBackBlock}>
                            <LArrowBack to="/" color="#3AB2C9 " />
                        </div>
                        <LTitle.H1>Избранное</LTitle.H1>
                    </div>
                    <div>
                        <UTabS
                            value={tab}
                            className={classes.tabPanel}
                            onChange={handlerChangeTab}
                        >
                            {Favorites.entity &&
                                (Object.keys(Favorites.entity) as Array<
                                    keyof typeof Favorites.entity
                                >).map((key) => (
                                    <UTab
                                        key={key}
                                        label={
                                            Favorites.entity &&
                                            Favorites.entity[key].title
                                        }
                                        value={key}
                                    />
                                ))}
                        </UTabS>

                        {Favorites.entity &&
                            (Object.keys(Favorites.entity) as Array<
                                keyof typeof Favorites.entity
                            >).map((key) => {
                                if (Favorites.entity && key === 'course')
                                    return (
                                        <UTabPanel
                                            index={key}
                                            value={tab}
                                            key={key}
                                        >
                                            <UGrid.URow
                                                className={classes.rowBlock}
                                            >
                                                {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
                                                {Favorites.entity[key].data.map(
                                                    (course) => (
                                                        <UGrid.UCol
                                                            // eslint-disable-next-line react/no-array-index-key
                                                            key={course.id}
                                                            xs={12}
                                                            xl={6}
                                                            className={
                                                                classes.colBlock
                                                            }
                                                        >
                                                            <WCardCourse
                                                                to={routes.course.to(
                                                                    course.id
                                                                )}
                                                                title={
                                                                    course.title
                                                                }
                                                                favorited={
                                                                    course.favorited
                                                                }
                                                                progress={
                                                                    course.progress
                                                                }
                                                                onChangeFavorite={CoursesManager.toggleFavorite(
                                                                    course
                                                                )}
                                                                completed={
                                                                    course.status ===
                                                                    'passed'
                                                                }
                                                                deadline={
                                                                    course
                                                                        .dueDate
                                                                        ?.date
                                                                }
                                                            >
                                                                <WCourseLessons
                                                                    className={
                                                                        classes.lessonBlock
                                                                    }
                                                                    title="Уроки курса"
                                                                >
                                                                    <>
                                                                        {course.lessons?.map(
                                                                            (
                                                                                lesson
                                                                            ) => (
                                                                                <WTitleCourseLink
                                                                                    key={
                                                                                        lesson.id
                                                                                    }
                                                                                    to={routes.lesson.to(
                                                                                        lesson.id
                                                                                    )}
                                                                                    status={
                                                                                        lesson.status ===
                                                                                        'passed'
                                                                                            ? 'completed'
                                                                                            : 'inProgress'
                                                                                    }
                                                                                    title={
                                                                                        lesson.title
                                                                                    }
                                                                                />
                                                                            )
                                                                        )}
                                                                    </>
                                                                </WCourseLessons>
                                                            </WCardCourse>
                                                        </UGrid.UCol>
                                                    )
                                                )}
                                            </UGrid.URow>
                                        </UTabPanel>
                                    )
                                if (Favorites.entity && key === 'collection')
                                    return (
                                        <UTabPanel
                                            index={key}
                                            value={tab}
                                            key={key}
                                        >
                                            <UGrid.URow
                                                className={classes.rowBlock}
                                            >
                                                {Favorites.entity[key].data.map(
                                                    (collection) => (
                                                        <UGrid.UCol
                                                            xs={12}
                                                            xl={6}
                                                            key={collection.id}
                                                            className={
                                                                classes.colBlock
                                                            }
                                                        >
                                                            <WCardCollection
                                                                title={
                                                                    collection.title
                                                                }
                                                                favorited={
                                                                    collection.favorited
                                                                }
                                                                onChangeFavorited={CollectionsManager.toggleFavorite(
                                                                    collection
                                                                )}
                                                                to={routes.collection.to(
                                                                    collection.id
                                                                )}
                                                            >
                                                                <>
                                                                    <Typography
                                                                        variant="body1"
                                                                        style={{
                                                                            paddingBottom: 16,
                                                                            height:
                                                                                '184px',
                                                                            overflow:
                                                                                'hidden',
                                                                        }}
                                                                    >
                                                                        {
                                                                            collection.description
                                                                        }
                                                                    </Typography>
                                                                    <WCourseLessons
                                                                        className={
                                                                            classes.lessonBlock
                                                                        }
                                                                    >
                                                                        <>
                                                                            {collection.courses?.map(
                                                                                (
                                                                                    course
                                                                                ) => (
                                                                                    <WTitleCourseLink
                                                                                        key={
                                                                                            course.id
                                                                                        }
                                                                                        to={routes.course.to(
                                                                                            course.id
                                                                                        )}
                                                                                        status={
                                                                                            course.status ===
                                                                                            'passed'
                                                                                                ? 'completed'
                                                                                                : 'inProgress'
                                                                                        }
                                                                                        title={
                                                                                            course.title
                                                                                        }
                                                                                    />
                                                                                )
                                                                            )}
                                                                            {collection.lessons?.map(
                                                                                (
                                                                                    lesson
                                                                                ) => (
                                                                                    <WTitleCourseLink
                                                                                        to={routes.lesson.to(
                                                                                            lesson.id
                                                                                        )}
                                                                                        key={
                                                                                            lesson.id
                                                                                        }
                                                                                        status={
                                                                                            lesson.status ===
                                                                                            'passed'
                                                                                                ? 'completed'
                                                                                                : 'inProgress'
                                                                                        }
                                                                                        title={
                                                                                            lesson.title
                                                                                        }
                                                                                    />
                                                                                )
                                                                            )}
                                                                            {collection.materials?.map(
                                                                                (
                                                                                    material
                                                                                ) => (
                                                                                    <WTitleCourseLink
                                                                                        to={routes.material.to(
                                                                                            material.id
                                                                                        )}
                                                                                        key={
                                                                                            material.id
                                                                                        }
                                                                                        title={
                                                                                            material.title
                                                                                        }
                                                                                    />
                                                                                )
                                                                            )}
                                                                        </>
                                                                    </WCourseLessons>
                                                                </>
                                                            </WCardCollection>
                                                        </UGrid.UCol>
                                                    )
                                                )}
                                            </UGrid.URow>
                                        </UTabPanel>
                                    )
                                if (Favorites.entity && key === 'lesson')
                                    return (
                                        <UTabPanel
                                            index={key}
                                            value={tab}
                                            key={key}
                                        >
                                            <UGrid.URow
                                                className={classes.rowBlock}
                                            >
                                                {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
                                                {Favorites.entity[key].data.map(
                                                    (lesson) => (
                                                        <UGrid.UCol
                                                            xs={12}
                                                            xl={6}
                                                            // eslint-disable-next-line react/no-array-index-key
                                                            key={lesson.id}
                                                            className={
                                                                classes.colBlock
                                                            }
                                                        >
                                                            <WCardLesson
                                                                to={routes.lesson.to(
                                                                    lesson.id
                                                                )}
                                                                title={
                                                                    lesson.title
                                                                }
                                                                completed={
                                                                    lesson.status ===
                                                                    'passed'
                                                                }
                                                                // onChange={handleClickLike}
                                                                favorited={
                                                                    lesson.favorited
                                                                }
                                                                onChangeFavorite={LessonsManager.toggleFavorite(
                                                                    lesson
                                                                )}
                                                                deadline=""
                                                            />
                                                        </UGrid.UCol>
                                                    )
                                                )}
                                            </UGrid.URow>
                                        </UTabPanel>
                                    )
                                return null
                            })}
                    </div>
                </Box>
            </UGrid.UContainer>
        </div>
    )
}

export default SFavorites
