import { makeStyles, Theme } from '@material-ui/core/styles'
import search from './svg/search.svg'
import searchLight from './svg/searchLight.svg'

interface Props {
    fullWidth: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        background: 'rgba(203, 209, 229, 0.16)',
        border: '0px',
        outline: 'none',
        borderRadius: '124px',
        padding: '10px 20px 10px 38px',
        fontWeight: 500,
        fontSize: '15px',
        lineHeight: '140%',
        letterSpacing: '0.1px',
        width: '100%',
        maxWidth: ({ fullWidth }: Props) => (fullWidth ? '100%' : '689px'),
    },

    common: {
        color: '#66666c',
        backgroundImage: `url(${search})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left',
        backgroundPositionX: '14.5px',
        position: 'relative',

        '&::placeholder': {
            color: '#66666c',
        },
    },

    light: {
        color: '#fff',
        padding: '15px 24px',
        backgroundImage: `url(${searchLight})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right 20px top 50%',
        background: 'rgba(17, 20, 25, 0.16)',
        position: 'relative',

        '&::placeholder': {
            color: '#fff',
        },
    },

    inputbtn: {
        display: 'none',
        borderRadius: '50%',
        background: 'rgba(203, 209, 229, 0.16)',
        width: '51px',
        height: '51px',
        order: 2,
        outline: 'none',
        border: 0,
        minWidth: '51px',
        cursor: 'pointer',
        [theme.breakpoints.down(1024)]: {
            display: 'block',
        },
    },
}))
export default useStyles
