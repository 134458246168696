import React from 'react'
import { useMediaQuery } from '@material-ui/core'
import clsx from 'clsx'
import SHeader from '@/sections/SHeader'
import UGrid from '@/components/ui/UGrid'
import useStyles from './style'
import theme from '../../../theme'

import largeRocket from './svg/manOnRocket/large.svg'
import mediumRocket from './svg/manOnRocket/medium.svg'
import smallRocket from './svg/manOnRocket/small.svg'
import largeGirl from './svg/girl/large.svg'
import mediumGirl from './svg/girl/medium.svg'
import smallGirl from './svg/girl/small.svg'

interface ILoginAnd404Layout {
    needHeader?: boolean
    variant: 'cloud' | 'girl'
    children: React.ReactChild
}
const LoginAnd404Layout: React.FC<ILoginAnd404Layout> = ({
    variant,
    needHeader = false,
    children,
}: ILoginAnd404Layout): JSX.Element => {
    const classes = useStyles({ variant })

    const isScreenDown768 = useMediaQuery(theme.breakpoints.down('sm'))
    const isScreenDown1900 = useMediaQuery(theme.breakpoints.down('lg'))

    const getSvgPath = (): string => {
        if (isScreenDown768 && isScreenDown1900) {
            switch (variant) {
                case 'cloud': {
                    return smallRocket
                }
                case 'girl': {
                    return smallGirl
                }
                default:
                    return ''
            }
        }
        if (isScreenDown1900) {
            switch (variant) {
                case 'cloud': {
                    return mediumRocket
                }
                case 'girl': {
                    return mediumGirl
                }
                default:
                    return ''
            }
        }
        if (!isScreenDown1900) {
            switch (variant) {
                case 'cloud': {
                    return largeRocket
                }
                case 'girl': {
                    return largeGirl
                }
                default:
                    return ''
            }
        }
        return ''
    }

    return (
        <div className={classes.root}>
            {needHeader && (
                <UGrid.UContainer>
                    <SHeader />
                </UGrid.UContainer>
            )}
            <div className={classes.notFoundBlock} />
            <div className={classes.content}>{children}</div>

            {/* DECORATION */}
            <div className={clsx(classes.decoration, classes.decorationRocket)}>
                <img src={getSvgPath()} alt="rocket" />
            </div>
        </div>
    )
}

export default LoginAnd404Layout
