import React from 'react'
import { Box } from '@material-ui/core'
import UGrid from '@/components/ui/UGrid'
import DailyProgress from '@/components/widget/WDailyProgress'
import UTitle from '@/components/ui/UTitle'
import WCardLesson from '@/components/widget/WCard/Lesson'
import { useLessons, useLessonsManager } from '@/services/lessons'
import routes from '@/routes'
import useStyles from './style'

const SProgress = (): JSX.Element => {
    const classes = useStyles()
    const Lessons = useLessons()
    const LessonsManager = useLessonsManager()

    return (
        <Box className={classes.borderBottom}>
            <UGrid.UContainer>
                <Box className={classes.wrapper}>
                    <Box className={classes.section}>
                        <UTitle
                            mdFont={32}
                            lgFont={32}
                            xlFont={42}
                            className={classes.sectionTitle}
                        >
                            Вы недавно смотрели
                        </UTitle>
                        {Lessons.list.slice(0, 2).map((lesson) => {
                            const { favorited, title, id, status } = lesson
                            return (
                                <Box width="100%" mb={3} key={id}>
                                    <WCardLesson
                                        title={title}
                                        completed={status === 'passed'}
                                        to={routes.lesson.to(id)}
                                        favorited={favorited}
                                        onChangeFavorite={LessonsManager.toggleFavorite(
                                            lesson
                                        )}
                                    />
                                </Box>
                            )
                        })}
                    </Box>
                    <Box className={classes.section}>
                        <Box className={classes.progress}>
                            <DailyProgress />
                        </Box>
                    </Box>
                </Box>
            </UGrid.UContainer>
        </Box>
    )
}

export default SProgress
