import UCard from '@/components/ui/UCard'
import UProgressBar from '@/components/ui/UProgressBar'
import moment from 'moment-ru'
import React from 'react'
import type { TSpecializationName } from '@/api/BaseApi'
import { useHistory } from 'react-router-dom'

export type TWCourseCardProps = {
    picture?: string
    title: string
    description?: string
    date?: string
    specialization: string
    specializationId: TSpecializationName
    progress?: number
    to?: string
}

const colorMap = {
    primary: '#00A8D7',
    secondary: '#FFB427',
    common: '#9CA8BE',
} as const

const WCourseCard: React.FC<TWCourseCardProps> = ({
    picture,
    title,
    description,
    progress,
    date,
    specialization,
    specializationId,
    to,
}: TWCourseCardProps) => {
    const history = useHistory()
    return (
        <UCard.TypeOne
            onClick={() => to && history.push(to)}
            picture={<img alt="" src={picture} />}
            title={title}
            statusText={`${specialization} ${progress ? `${progress}%` : ''}`}
            text={description || ''}
            statusColor={colorMap[specializationId]}
            bottom={
                progress && (
                    <UProgressBar
                        size={progress}
                        fill={colorMap[specializationId]}
                        subtitle={
                            date &&
                            `Пройти до ${moment(date).format('D MMMM YYYY')}`
                        }
                    />
                )
            }
        />
    )
}

export default WCourseCard
