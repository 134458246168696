/* eslint-disable @typescript-eslint/unbound-method */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createAwaitAdapter, TAwaitState } from '@/store/common'
import { CoursesActions } from '@/services/courses'
import { LessonsActions } from '@/services/lessons'
import { CollectionsActions } from '@/services/collections'
import { ModuleApi, TModuleEntity } from './module.api'

const NAME = 'module'

const get = createAsyncThunk(`${NAME}/get`, async (id: string) => {
    const module = await ModuleApi.get(id)
    const { data } = module

    return data
})

const ModuleAwaitAdapter = createAwaitAdapter(NAME)

export interface TModuleInitialState extends TAwaitState {
    entity: TModuleEntity | null
}

const initialState: TModuleInitialState = {
    ...ModuleAwaitAdapter.getAwaitState(),
    entity: null,
}
const moduleStore = createSlice({
    name: NAME,
    initialState,
    reducers: {
        clear(state) {
            state.entity = null
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(get.fulfilled, (state, { payload }) => {
                state.entity = payload
            })
            .addCase(
                CoursesActions.addToFavorite.fulfilled,
                (state, { payload }) => {
                    state.entity?.courses?.forEach((course) => {
                        if (course.id === payload) course.favorited = true
                    })
                }
            )
            .addCase(
                LessonsActions.addToFavorite.fulfilled,
                (state, { payload }) => {
                    state.entity?.lessons?.forEach((lesson) => {
                        if (lesson.id === payload) lesson.favorited = true
                    })
                }
            )
            .addCase(
                CollectionsActions.addToFavorite.fulfilled,
                (state, { payload }) => {
                    state.entity?.collections?.forEach((collection) => {
                        if (collection.id === payload)
                            collection.favorited = true
                    })
                }
            )
            .addCase(
                CoursesActions.removeFromFavorite.fulfilled,
                (state, { payload }) => {
                    state.entity?.courses?.forEach((course) => {
                        if (course.id === payload) course.favorited = false
                    })
                }
            )
            .addCase(
                LessonsActions.removeFromFavorite.fulfilled,
                (state, { payload }) => {
                    state.entity?.lessons?.forEach((lesson) => {
                        if (lesson.id === payload) lesson.favorited = false
                    })
                }
            )
            .addCase(
                CollectionsActions.removeFromFavorite.fulfilled,
                (state, { payload }) => {
                    state.entity?.collections?.forEach((collection) => {
                        if (collection.id === payload)
                            collection.favorited = false
                    })
                }
            )
            .addMatcher(
                ModuleAwaitAdapter.isFulfilled,
                ModuleAwaitAdapter.fulfilled
            )
            .addMatcher(
                ModuleAwaitAdapter.isPending,
                ModuleAwaitAdapter.pending
            )
            .addMatcher(
                ModuleAwaitAdapter.isRejected,
                ModuleAwaitAdapter.rejected()
            )
    },
})

export const ModuleActions = {
    ...moduleStore.actions,
    get,
}
export default moduleStore.reducer
