import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center ',
        padding: '16px 0',
        '& + &': {
            borderTop: '1px solid #EBEDEF',
        },
        cursor: 'pointer',
    },
    title: {},
    linkBlock: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'flex-start',
    },
    linkTitle: {
        marginLeft: 10,
        marginRight: 16,
        minWidth: 58,
    },
}))
export default useStyles
