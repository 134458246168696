import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { useUserManager } from '@/services/user'
import routes from '@/routes'
import PrivateRoute from './utils/private-route'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ScrollToTop from './components/ui/UScrollTop'

const App = (): JSX.Element => {
    const userManager = useUserManager()
    useEffect(() => {
        userManager.get()
    }, [])

    return (
        <Router>
            <ScrollToTop />
            <Switch>
                <Route {...routes.login} />
                <PrivateRoute {...routes.main} />
                <PrivateRoute {...routes.module} />
                <PrivateRoute {...routes.favorites} />
                <PrivateRoute {...routes.courses} />
                <PrivateRoute {...routes.lesson} />
                <PrivateRoute {...routes.course} />
                <PrivateRoute {...routes.material} />
                <PrivateRoute {...routes.trainingPrograms} />
                <PrivateRoute {...routes.trainingProgram} />
                <PrivateRoute {...routes.collection} />
                <PrivateRoute {...routes.lk.main} />
                <Route {...routes.notFound} />
            </Switch>
        </Router>
    )
}

export default App
