/* eslint-disable react/prop-types */
import React from 'react'
import { ReactComponent as DownloadIcon } from '@/components/svg/icons/Subtract.svg'
import { makeStyles } from '@material-ui/core'

const useStyle = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {},
    text: {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 20,
        lineHeight: '160%',

        color: '#4FC2D8',

        marginLeft: 14,
    },
}))

export interface IUDownloadButtonProps extends React.ComponentProps<'div'> {
    children: React.ReactNode
}

export const UDownloadButton: React.FC<IUDownloadButtonProps> = ({
    children,
    ...rest
}) => {
    const classes = useStyle()
    return (
        <div {...rest} className={classes.root}>
            <DownloadIcon className={classes.icon} />
            <span className={classes.text}>{children}</span>
        </div>
    )
}
