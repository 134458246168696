import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'

interface ISuccessSvg {
    color?: string
}
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: 24,
        height: 20,
        [theme.breakpoints.down(768)]: {
            width: 14.4,
            height: 12,
        },
    },
}))
const SuccessSvg: React.FC<ISuccessSvg> = ({
    color = '#3AB2C9',
}: ISuccessSvg): React.ReactElement => {
    const classes = useStyles()
    return (
        <svg
            className={classes.root}
            viewBox="0 0 24 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.9462 19.5L6.94518 19.5C6.71835 19.4991 6.49384 19.4518 6.28508 19.3607C6.07634 19.2696 5.88744 19.1364 5.7299 18.969C5.72987 18.9689 5.72984 18.9689 5.72982 18.9689L0.878407 13.8074C0.61269 13.4744 0.478107 13.0516 0.502908 12.6215C0.52803 12.1859 0.714693 11.7773 1.0235 11.4773C1.33211 11.1775 1.73982 11.0082 2.16452 11.0014C2.58339 10.9947 2.99056 11.1464 3.30644 11.4284L6.60135 14.9233L6.96312 15.307L7.32704 14.9253L20.5828 1.02176C20.583 1.02158 20.5832 1.02139 20.5833 1.02121C20.7392 0.858823 20.9247 0.729482 21.1291 0.640224C21.3337 0.550871 21.5534 0.503344 21.7758 0.50017C21.9981 0.496996 22.219 0.538232 22.426 0.621687C22.6329 0.705147 22.8221 0.829291 22.9825 0.987363L23.3335 0.631242L22.9825 0.987365C23.1429 1.14545 23.2714 1.33438 23.3603 1.54355C23.4492 1.75274 23.4967 1.97786 23.4998 2.20603C23.503 2.43419 23.4618 2.66061 23.3787 2.8723C23.2957 3.08398 23.1724 3.27656 23.0164 3.43922L23.0158 3.4399L8.16146 18.986C8.16112 18.9864 8.16077 18.9868 8.16043 18.9871C8.00168 19.151 7.8125 19.2806 7.60427 19.3685C7.3956 19.4567 7.17184 19.5013 6.9462 19.5Z"
                fill={color}
                stroke="#3AB2C9"
            />
        </svg>
    )
}
export default SuccessSvg
