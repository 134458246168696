import React from 'react'
import { Typography, useMediaQuery } from '@material-ui/core'
import LAvatar from '../UAvatar'
import useStyles from './style'
import LButton from '../UButton'
import theme from '../../../theme'
import { UDownloadButton } from '../UDownloadButton'

interface ICompletedTask {
    name: string
    role: string
    file: string
    accompanyingText: string
    src: string
}
const CompletedTask: React.FC<ICompletedTask> = ({
    name,
    role,
    file,
    accompanyingText,
    src,
}: ICompletedTask): React.ReactElement => {
    const classes = useStyles()
    console.log(file, 'file')
    const screenDown768 = useMediaQuery(theme.breakpoints.down('sm'))
    return (
        <div className={classes.root}>
            <div className={classes.head}>
                <div>
                    <LAvatar src={src} alt="man" className={classes.avatar} />
                </div>
                <div>
                    <Typography variant="h3" className={classes.headTitle}>
                        {name}
                    </Typography>
                    <Typography variant="subtitle2">{role}</Typography>
                    <a href="/" className={classes.download}>
                        <UDownloadButton>Скачать</UDownloadButton>
                    </a>
                </div>
            </div>
            <div className={classes.content}>
                <Typography variant="body1" className={classes.text}>
                    {accompanyingText}
                </Typography>
                <div className={classes.btnBlock}>
                    <LButton
                        view="ghost"
                        size="large"
                        className={classes.btn}
                        fullWidth={screenDown768}
                    >
                        Переделать
                    </LButton>
                    <LButton
                        view="filled"
                        size="large"
                        className={classes.btn}
                        fullWidth={screenDown768}
                    >
                        Принять
                    </LButton>
                </div>
            </div>
        </div>
    )
}
export default CompletedTask
