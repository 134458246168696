import { makeStyles } from '@material-ui/core'
import React from 'react'
import { ReactComponent as PlusIcon } from '@/components/svg/icons/plus.svg'

const statusMap = {
    completed: '#B8B8C3',
    missed: '#FF7266',
    common: '#4FC2D8',
    primary: '#2469E0',
    secondary: '#39CF63',
} as const

const useStyle = makeStyles(() => ({
    root: ({ color }) => ({
        padding: '8px 18px',
        backgroundColor: color,
        position: 'relative',
        width: '100%',
    }),
    title: ({ mini }) => ({
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 15,
        lineHeight: '140%',
        letterSpacing: 0.1,
        color: mini ? '#000' : '#FFF',
        position: 'relative',
        zIndex: 1,
    }),
    content: ({ mini }) => ({
        marginTop: 2,
        color: mini ? '#B8B8C3' : '#fff',
        position: 'relative',
        zIndex: 1,
    }),
    progress: ({ progress }: TWEventStyleProps) => ({
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        width: `${progress}%`,
        backgroundColor: 'rgba(0,0,0, .2)',
    }),
    wrap: ({ mini }) => {
        if (mini)
            return {
                minWidth: 280,
                display: 'none',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: '#FFF',
                padding: 24,
                borderRadius: '2px',
                boxShadow:
                    '0px 6px 16px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.04)',

                position: 'absolute',
                left: '50%',
                bottom: '100%',
                transform: 'translateX(-50%)',
                '&:hover': {
                    display: 'flex',
                },
                '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: '100%',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    border: '16px solid transparent',
                    borderTop: '16px solid #FFF',
                },
            }
        return {}
    },
    preview: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover + $wrap': {
            display: 'flex',
        },
    },
    previewIcon: {},
    previewText: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '10px',
        lineHeight: '140%',
        textAlign: 'center',
        letterSpacing: 0.2,

        color: '#FFFFFF',

        marginTop: 7,
    },
}))

interface IWEventProps {
    title: string
    children?: React.ReactNode
    progress?: number
    mini?: boolean
    status?: keyof typeof statusMap
}
type TWEventStyleProps = {
    progress: number
    mini: boolean
    color: string
}

const WEvent = (props: IWEventProps) => {
    const {
        title,
        children,
        progress = 0,
        status = 'common',
        mini = false,
    } = props

    const progressWidth: number | void =
        progress && 100 - Math.max(Math.min(progress, 100), 0)

    const classes = useStyle({
        progress: progressWidth,
        mini,
        color: statusMap[status],
    })
    return (
        <div className={classes.root}>
            {mini && (
                <div className={classes.preview}>
                    <PlusIcon className={classes.previewIcon} />
                    <span className={classes.previewText}>Подробнее</span>
                </div>
            )}
            <div className={classes.wrap}>
                <span className={classes.title}>{title}</span>
                <div className={classes.content}>{children}</div>
                <div className={classes.progress} />
            </div>
        </div>
    )
}

export default WEvent
