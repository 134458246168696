/* eslint-disable react/no-unused-prop-types */
import { makeStyles } from '@material-ui/core'
import { MomentInput } from 'moment-ru'
import React from 'react'

const useStyle = makeStyles(() => ({
    root: ({ size, stepCount }: StyleProps) => ({
        position: 'relative',
        width: `${(size * 100) / stepCount}%`,
        display: 'flex',
    }),
}))

export type TUEventChildrenProps = {
    size: number
    offset: number
    stepCount: number
}
interface ILEventProps {
    children?:
        | React.ReactNode
        | ((props: TUEventChildrenProps) => React.ReactNode)
    from?: MomentInput
    to?: MomentInput
    step?: number
    offset?: number
    size?: number
    stepCount?: number
}

type StyleProps = Required<
    Pick<ILEventProps, 'step' | 'size' | 'offset' | 'stepCount'>
>

const UEvent: React.FC<ILEventProps> = (props: ILEventProps) => {
    const { children, step = 0, size = 0, offset = 0, stepCount = 0 } = props

    const classes = useStyle({
        step,
        size,
        offset,
        stepCount,
    })
    return (
        <div
            ref={(elem) => {
                if (elem) {
                    /* eslint-disable no-param-reassign */
                    elem.style.left = '0'
                    const a = elem.offsetLeft / step
                    const left =
                        -((a * 100) / stepCount) + (offset * 100) / stepCount
                    elem.style.left = `${left}%`
                }
            }}
            className={classes.root}
        >
            {typeof children === 'function'
                ? children({ size, offset, stepCount })
                : children}
        </div>
    )
}

export default UEvent
