import { makeStyles, Theme } from '@material-ui/core/styles'

interface Props {
    liked: boolean
}
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        cursor: 'pointer ',
        outline: 'none',
        border: 'none',
        backgroundColor: 'transparent ',
    },
    headBlock: {
        display: 'flex',
        justifyContent: 'space-between ',
        alignItems: 'center',
    },
    heart: {
        '& path': {
            transition: 'all 0.3s ',
            fill: ({ liked }: Props) => liked && theme.palette.error.main,
        },
    },
}))
export default useStyles
