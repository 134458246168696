import React from 'react'
import UItem from '@/components/ui/UItem'
import { useHistory } from 'react-router-dom'

export enum StatusVariants {
    completed,
    inProgress,
}
enum TitleVariants {
    completed = 'Завершен',
    inProgress = 'Не сдан',
}
interface IWTitleCourseLink {
    status?: keyof typeof StatusVariants
    title: string
    to?: string
}
const WTitleCourseLink: React.FC<IWTitleCourseLink> = ({
    status,
    title,
    to,
}: IWTitleCourseLink): React.ReactElement => {
    const history = useHistory()

    return (
        <UItem.TypeOne
            onClick={(e) => {
                e.stopPropagation()
                if (to) history.push(to)
            }}
            theme={status && StatusVariants[status]}
            title={title}
            arrowText={status && TitleVariants[status]}
        />
    )
}

export default WTitleCourseLink
