import React from 'react'
import useStyles from './style'
import SuccessSvg from './SuccessSvg'
import CheckSvg from './CheckSvg'

enum Variants {
    success = 'success',
    check = 'check',
}
interface ILButtonNotification {
    variant: keyof typeof Variants
}
const LButtonNotification: React.FC<ILButtonNotification> = ({
    variant,
}: ILButtonNotification): React.ReactElement => {
    const classes = useStyles()
    console.log(variant === Variants.check)
    return (
        <div className={classes.root}>
            {variant === Variants.success && <SuccessSvg />}
            {variant === Variants.check && <CheckSvg />}
        </div>
    )
}
export default LButtonNotification
