import React from 'react'
import useStyles from './style'
import { ReactComponent as Heart } from '../../svg/icons/Heart.svg'

interface IULike {
    value: boolean
    onChange?(event: React.MouseEvent): void
}
const ULike: React.FC<IULike> = ({
    value,
    onChange,
}: IULike): React.ReactElement => {
    const classes = useStyles({ liked: value })
    return (
        <div className={classes.headBlock}>
            <button className={classes.root} onClick={onChange} type="button">
                <Heart className={classes.heart} />
            </button>
        </div>
    )
}
export default ULike
