import { makeStyles } from '@material-ui/core'
import React from 'react'

const useStyle = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    marker: ({ color }: { color: string }) => ({
        width: 32,
        height: 32,
        flexShrink: 0,
        borderRadius: 4,
        backgroundColor: color,
    }),
    text: {
        marginLeft: 16,
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 15,
        lineHeight: '140%',
        letterSpacing: 0.1,
        color: '#4C4C52',
    },
}))

interface ILLegendProps {
    color?: string
    children?: React.ReactNode
}

const LLegend: React.FC<ILLegendProps> = (props: ILLegendProps) => {
    const { children, color = '#3AB2C9' } = props
    const classes = useStyle({ color })
    return (
        <div className={classes.root}>
            <div className={classes.marker} />
            {children && <span className={classes.text}>{children}</span>}
        </div>
    )
}

export default LLegend
