import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
    root: {},
    hr: {
        margin: 0,
        border: 0,
        height: 1,
        background: '#EBEDEF ',
    },
}))
export default useStyles
