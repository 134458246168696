/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import UCard from '@/components/ui/UCard'
import { TLessonEntity } from '@/services/lesson'
import { useHistory } from 'react-router-dom'
import useStyles from './style'

interface IWCardLesson {
    progress?: number
    title: string
    completed: boolean
    onMore?(): void
    deadline?: string
    favorited: TLessonEntity['favorited']
    onChangeFavorite?: (favorited: boolean) => void
    to?: string
}
enum StateCardCourse {
    completed = 'Завершён',
    inProgress = 'Не сдан',
}
const WCardLesson: React.FC<IWCardLesson> = ({
    title,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deadline,
    progress,
    completed,
    onMore = () => false,
    favorited,
    onChangeFavorite,
    to,
}: IWCardLesson): React.ReactElement => {
    const classes = useStyles()
    const history = useHistory()

    const changeFavoriteHandler = (e: React.MouseEvent) => {
        e.stopPropagation()
        onChangeFavorite?.(favorited)
    }

    return (
        <div className={classes.root} onClick={() => to && history.push(to)}>
            <UCard.TypeTwo
                title={title}
                suptitle={
                    completed ? (
                        <span className={classes.completed}>
                            {StateCardCourse.completed}
                        </span>
                    ) : (
                        <span className={classes.inProgress}>
                            {StateCardCourse.inProgress}
                        </span>
                    )
                }
                subtitle={deadline}
                size={progress}
                onMore={onMore}
                onChange={changeFavoriteHandler}
                completed={completed}
                value={favorited}
                fillProgressBar="#3ab2c9"
            />
        </div>
    )
}
export default WCardLesson
