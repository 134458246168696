import React, { useEffect } from 'react'
import routes from '@/routes'
import UGrid from '@/components/ui/UGrid'
import SHeader from '@/sections/SHeader'
import SFooter from '@/sections/SFooter'
import BreadCrumbs from '@/components/ui/UBreadCrumbs'
import Runner from '@/utils/Runner'
import LArrowBack from '@/components/ui/UArrowBack'
import LTitle from '@/components/ui/UTitle'
import { Box } from '@material-ui/core'
import { useLessonManager, useLesson } from '@/services/lesson'
import { useParams } from 'react-router-dom'
import useStyles from './style'

const LessonPage = (): JSX.Element => {
    const classes = useStyles()
    const LessonManager = useLessonManager()
    const Lesson = useLesson()

    const params = useParams<{ id: string }>()

    useEffect(() => {
        LessonManager.get(params.id)
        return () => {
            LessonManager.clear()
        }
    }, [])
    return (
        <>
            <SHeader adaptiveMenuView="light" isGoBackForAdaptive />
            <UGrid.UContainer className={classes.wrapper}>
                <div className={classes.breadCrumbs}>
                    <BreadCrumbs>
                        <BreadCrumbs.Item to={routes.main.path}>
                            Главная
                        </BreadCrumbs.Item>
                        <BreadCrumbs.Item to={routes.courses.path}>
                            Все курсы
                        </BreadCrumbs.Item>
                        <BreadCrumbs.Item>
                            {Lesson.entity?.title}
                        </BreadCrumbs.Item>
                    </BreadCrumbs>
                </div>
                <div className={classes.titleBlock}>
                    <div className={classes.arrowBackBlock}>
                        <LArrowBack to="/" color="#4C4C52 " />
                    </div>

                    <LTitle.H2 variant="h2v3">{Lesson.entity?.title}</LTitle.H2>
                </div>
                {Lesson.entity?.description && (
                    <span className={classes.description}>
                        {Lesson.entity?.description}
                    </span>
                )}
                <Box className={classes.editorWrapper}>
                    {Lesson.entity?.content && (
                        <Runner content={Lesson.entity.content} />
                    )}
                </Box>
                {Lesson.entity?.h5pData.map((item) => (
                    <iframe
                        frameBorder="0"
                        src={item.embedUrl}
                        key={item.h5pId}
                        title={item.title}
                    />
                ))}
            </UGrid.UContainer>
            <SFooter />
        </>
    )
}

export default LessonPage
