/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef } from 'react'
import cx from 'classnames'

// import { Typography } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import LButton from '@/components/ui/UButton'
import LTitle from '@/components/ui/UTitle'
import routes from '@/routes'
import UGrid from '@/components/ui/UGrid'
import UPlayButton from '@/components/ui/UPlayButton'
import useStyles from './style'

const Main: React.FC = (): React.ReactElement => {
    const classes = useStyles()
    const videoRef = useRef<HTMLVideoElement>(null)
    const startHandler = () => {
        videoRef.current?.requestFullscreen()
    }

    return (
        <main className={classes.root}>
            <div className={classes.mainBlock}>
                <UGrid.UCol xs={12} xl={6} className={classes.contentMainBlock}>
                    <LTitle.H1 className={classes.titleBlock}>
                        Развивайте навыки с онлайн-курсами от лучших
                        преподавателей
                    </LTitle.H1>
                    <div className={classes.buttonsBlock}>
                        <NavLink
                            className={classes.navLink}
                            to={routes.courses.path}
                        >
                            <LButton
                                size="large"
                                view="filled"
                                className={classes.breakpointsBtn}
                            >
                                Все курсы
                            </LButton>
                        </NavLink>
                        <NavLink
                            className={classes.navLink}
                            to={routes.favorites.path}
                        >
                            <LButton
                                size="large"
                                view="ghost"
                                className={cx(
                                    classes.breakpointsBtn,
                                    classes.breakpointsBtnOutlined
                                )}
                            >
                                Избранное
                            </LButton>
                        </NavLink>
                    </div>
                </UGrid.UCol>
                <UGrid.UCol xl={6} className={classes.playerBlock}>
                    <video
                        className={classes.video}
                        ref={videoRef}
                        preload="auto"
                        autoPlay
                        loop
                        muted
                        src="https://learn-static-dev.lgnd.pw/video/file_example_MP4_1280_10MG.mp4"
                    />
                    <div className={classes.playBtn}>
                        <UPlayButton
                            title="Добейтесь своих целей"
                            onClick={startHandler}
                        />
                    </div>
                </UGrid.UCol>
            </div>
        </main>
    )
}
export default Main
