import UGrid from '@/components/ui/UGrid'
import { useCourse } from '@/services/course'
import React from 'react'

const SCourseEndTest = () => {
    const Course = useCourse()
    return (
        <UGrid.UContainer style={{ padding: '64px 0' }}>
            <iframe
                src={Course.entity?.endTest?.embedUrl}
                title={Course.entity?.endTest?.title}
                frameBorder="0"
            />
        </UGrid.UContainer>
    )
}

export default SCourseEndTest
