import BaseApi, {
    TDateWithDateTime,
    TImage,
    TSpecialization,
} from '@/api/BaseApi'
import { AxiosPromise } from 'axios'
import { TContent } from '../../utils/Runner/index'

export type TLearningProgramItemTypes = 'lesson' | 'practicalTask'
export type TLearningProgramItemStatus =
    | 'notStarted'
    | 'inProgress'
    | 'notPassed'
    | 'passed'
    | 'completed'

export type TLearningProgramItem = {
    // uuid элемента программы обучения
    id: string

    // Тип элемента программы обучения
    type: TLearningProgramItemTypes

    // Название элемента программы обучения
    title: string

    // Описание программы обучения
    description?: string

    // Доступен или нет элемент программы обучения
    accessible: boolean

    // Статус элемента программы обучения
    status: TLearningProgramItemStatus

    dueDate: TDateWithDateTime

    // Оценка выполнения (0 - 100)
    score?: number
}
export type TLearningProgramEntity = {
    id: string
    title: string
    programItems: TLearningProgramItem[]
    status: TLearningProgramItemStatus
    progress: number
    description?: string
    coverImage?: TImage
    content?: TContent
    specialization: TSpecialization
    dueDate: TDateWithDateTime
}

const LearningProgramApi = {
    get(id: string): AxiosPromise<TLearningProgramEntity> {
        return BaseApi.get(`/learningprograms/${id}`)
    },
}

export { LearningProgramApi }
