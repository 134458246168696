import BaseApi from '@/api/BaseApi'
import { AxiosPromise } from 'axios'
import { TLearningProgramEntity } from '../learningProgram'

const LearningProgramsApi = {
    get(): AxiosPromise<TLearningProgramEntity[]> {
        return BaseApi.get('/learningprograms')
    },
}

export { LearningProgramsApi }
