/* eslint-disable @typescript-eslint/unbound-method */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createAwaitAdapter, TAwaitState } from '@/store/common'
import { MaterialApi, TMaterialEntity } from './material.api'

const NAME = 'material'

const get = createAsyncThunk(`${NAME}/get`, async (id: string) => {
    const material = await MaterialApi.get(id)
    const { data } = material

    return data
})

const MaterialAwaitAdapter = createAwaitAdapter(NAME)

export interface TMaterialInitialState extends TAwaitState {
    entity: TMaterialEntity | null
}
const initialState: TMaterialInitialState = {
    entity: null,
    ...MaterialAwaitAdapter.getAwaitState(),
}
const materialStore = createSlice({
    name: NAME,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(get.fulfilled, (state, { payload }) => {
                state.entity = payload
            })
            .addMatcher(
                MaterialAwaitAdapter.isFulfilled,
                MaterialAwaitAdapter.fulfilled
            )
            .addMatcher(
                MaterialAwaitAdapter.isPending,
                MaterialAwaitAdapter.pending
            )
            .addMatcher(
                MaterialAwaitAdapter.isRejected,
                MaterialAwaitAdapter.rejected()
            )
    },
})

export const MaterialActions = {
    get,
}
export default materialStore.reducer
