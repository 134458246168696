import { makeStyles, Theme } from '@material-ui/core/styles'

interface StyleProps {
    type?: string
}

const useStyles = makeStyles((theme: Theme) => ({
    section: (props: StyleProps) => ({
        padding: '0',
        margin: '64px -16px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            padding: '80px 16px',
            margin: '0',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '80px 0',
            maxWidth: '710px',
            margin: 'auto',
            alignItems: props.type === 'all' ? 'center' : 'flex-start',
        },
        [theme.breakpoints.up('xl')]: {
            maxWidth: 'none',
            padding: props.type === 'all' ? '80px 0' : '130px 24px',
        },
    }),
    borderBottom: {
        borderBottom: '1px solid #EBEDEF',
    },

    sectionTitle: (props: StyleProps) => ({
        marginBottom: 32,

        [theme.breakpoints.up('md')]: {
            fontSize: props.type === 'all' ? '57px' : '42px',
            marginRight: 'auto',
            marginBottom: 40,
        },
        [theme.breakpoints.up('xl')]: {
            margin: '0',
        },
    }),
    breakpointsBtn: {
        margin: '32px auto 0 auto',
        display: 'block',
        fontSize: '15px !important',
        fontWeight: 500,

        [theme.breakpoints.up('md')]: {
            fontWeight: 400,
            padding: '12px 32px',
            margin: '32px auto 0 0',
            '&:last-child': {
                backgroundColor: 'transparent !important ',
                border: `2px solid ${theme.palette.primary.main}`,
            },
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '20px !important',
        },
        [theme.breakpoints.up('xl')]: {
            margin: '0',
        },
    },
    titleWrapper: (props: StyleProps) => ({
        marginLeft: props.type === 'all' ? '16px' : '0',
        marginRight: props.type === 'all' ? 'auto' : '0',
        [theme.breakpoints.up('md')]: {
            marginRight: 'auto',
            marginLeft: '0',
        },
        [theme.breakpoints.up('xl')]: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '40px',
        },
    }),
    tabsWrapper: (props: StyleProps) =>
        props?.type === 'all'
            ? {
                  paddingLeft: '16px',
                  marginBottom: '-16px',
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexWrap: 'wrap',
                  width: '100%',
                  [theme.breakpoints.up('md')]: {
                      marginBottom: '0',
                      paddingLeft: '0',
                  },
                  [theme.breakpoints.up('xl')]: {
                      marginBottom: '16px',
                      flexWrap: 'nowrap',
                  },
              }
            : {
                  alignSelf: 'flex-start',
              },
    checkboxesWrapper: {
        maxWidth: '100%',
        display: 'flex',
        justifyContent: 'center',
        width: 'initial',
        flexShrink: 0,
        flexWrap: 'wrap',
        margin: '30px 0',

        [theme.breakpoints.up('md')]: {
            margin: '30px 0',
        },
        [theme.breakpoints.up('lg')]: {
            margin: '30px 0',
        },
        [theme.breakpoints.up('xl')]: {
            margin: '0',
        },
    },
    checkboxesOuterWrapper: {
        display: 'flex',
        marginLeft: 'auto',

        [theme.breakpoints.up('xl')]: {
            maxWidth: '50%',
        },
    },
    checkboxesInnerWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '24px',
        [theme.breakpoints.up('md')]: {},
        [theme.breakpoints.up('xl')]: {
            alignItems: 'center',
            margin: '0 0 0 auto',
        },
    },
}))
export default useStyles
