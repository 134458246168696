import BaseApi from '@/api/BaseApi'
import { AxiosPromise } from 'axios'
import { TLessonEntity } from '../lesson/lesson.api'

const LessonsApi = {
    get(): AxiosPromise<TLessonEntity[]> {
        return BaseApi.get('/lessons')
    },
}

export { LessonsApi }
