import React from 'react'
import { Typography } from '@material-ui/core'
import UStatus from '@/components/ui/UStatus'
import { ProgressView } from './type'
import useStyles from './style'
import LArrowBack from '../../ui/UArrowBack'
import LProgressBar from '../../ui/UProgressBar'

interface WUInfoProgram {
    title: string
    deadline?: string
    typeProgram?: string
    children: React.ReactNode
    progress: number
    progressView?: keyof typeof ProgressView
}
function WInfoProgram({
    title,
    deadline,
    typeProgram = '',
    children,
    progress,
    progressView = ProgressView.asStatus,
}: WUInfoProgram): React.ReactElement {
    const classes = useStyles({ progressView })
    return (
        <div className={classes.root}>
            <div className={classes.overlay} />

            <div className={classes.headBlock}>
                <div className={classes.arrowBlock}>
                    <LArrowBack color="#fff" to="/" />
                </div>
                <Typography variant="h2" className={classes.title}>
                    {title}
                </Typography>
            </div>
            <Typography variant="body1" className={classes.contentText}>
                {children}
            </Typography>
            <div className={classes.progressBlock}>
                {progressView === ProgressView.asProgress ? (
                    <div className={classes.progress}>
                        <LProgressBar
                            size={progress}
                            fill="#FFF"
                            outline="transparent"
                            bg="rgba(76, 76, 82, 0.2)"
                            subtitle={
                                <div className={classes.deadline}>
                                    {deadline}
                                </div>
                            }
                        />
                    </div>
                ) : (
                    <UStatus
                        className={classes.status}
                        color="#000"
                        background="#FFF"
                    >
                        {typeProgram} {progress}%
                    </UStatus>
                )}
            </div>
        </div>
    )
}

export default WInfoProgram
