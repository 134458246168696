/* eslint-disable @typescript-eslint/unbound-method */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createAwaitAdapter, createListAdapter } from '@/store/common'
import { TLearningProgramEntity } from '@/services/learningProgram'
import { LearningProgramsApi } from './learningPrograms.api'

const NAME = 'learningPrograms'

const get = createAsyncThunk(`${NAME}/get`, async () => {
    const learningPrograms = await LearningProgramsApi.get()
    const list = learningPrograms.data

    return {
        list,
    }
})

const LearningProgramsAdapter = createListAdapter<TLearningProgramEntity>()
const LearningProgramsAwaitAdapter = createAwaitAdapter(NAME)
const initialState = LearningProgramsAdapter.getInitialState(
    LearningProgramsAwaitAdapter.getAwaitState()
)
const learningProgramsStore = createSlice({
    name: NAME,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(get.fulfilled, LearningProgramsAdapter.set)
            .addMatcher(
                LearningProgramsAwaitAdapter.isFulfilled,
                LearningProgramsAwaitAdapter.fulfilled
            )
            .addMatcher(
                LearningProgramsAwaitAdapter.isPending,
                LearningProgramsAwaitAdapter.pending
            )
            .addMatcher(
                LearningProgramsAwaitAdapter.isRejected,
                LearningProgramsAwaitAdapter.rejected()
            )
    },
})

export const LearningProgramsActions = {
    get,
}
export default learningProgramsStore.reducer
