import { useAppDispatch } from '@/store'
import { TLessonEntity } from '../lesson/lesson.api'
import { LessonsActions } from './lessons.store'

const useLessonsManager = () => {
    const dispatch = useAppDispatch()

    const get = () => dispatch(LessonsActions.get())

    const addToFavorite = (
        ...args: Parameters<typeof LessonsActions.addToFavorite>
    ) => dispatch(LessonsActions.addToFavorite(...args))

    const removeFromFavorite = (
        ...args: Parameters<typeof LessonsActions.removeFromFavorite>
    ) => dispatch(LessonsActions.removeFromFavorite(...args))

    const toggleFavorite = (lesson: TLessonEntity) => (favorited: boolean) => {
        if (favorited) removeFromFavorite(lesson.id)
        else addToFavorite(lesson)
    }

    return {
        get,
        addToFavorite,
        removeFromFavorite,
        toggleFavorite,
    } as const
}

export { useLessonsManager }
