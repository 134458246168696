import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
    section: {
        // paddingTop: 64,
        // paddingBottom: 48,
        margin: `64px 0`,
        padding: '0',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            padding: '80px 16px',
            margin: '0',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '80px 0',
            maxWidth: '758px',
            margin: 'auto',
        },
        [theme.breakpoints.up('xl')]: {
            maxWidth: 'none',
            padding: '130px 24px',
        },
    },
    wrap: {
        margin: `0 -${theme.spacing(6) / 2}px`,
        [theme.breakpoints.up('md')]: {
            margin: 0,
        },
    },
    borderBottom: {
        borderBottom: '1px solid #EBEDEF',
    },
    sectionTitle: {
        marginBottom: 32,
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            textAlign: 'left',
        },
        [theme.breakpoints.up('lg')]: {
            marginRight: 'auto',
            marginLeft: 24,
        },
    },
    legends: {
        marginTop: 40,
    },
}))
export default useStyles
