import { useAppSelector } from '@/store'
import { THomework } from '../homework/homework.entity'
import { TLearningContentShort } from '../learningContentShort'
import { TPracticalTask } from '../practicalTask'

export type TSuccessHomeworksByTask = {
    task: TPracticalTask
    data: THomework[]
}

export type THomeworksForCheckingEntity = {
    learningContent: TLearningContentShort
    byTasks: TSuccessHomeworksByTask[]
}

const useHomeworkForChecking = () => {
    const homeworkForChecking = useAppSelector(
        (state) => state.homeworkForChecking
    )

    return homeworkForChecking
}

export { useHomeworkForChecking }
