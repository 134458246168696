import BaseApi from '@/api/BaseApi'
import { AxiosPromise } from 'axios'
import { THomeworksForCheckingEntity } from './homeworksForChecking.entity'

const HomeworksForCheckingApi = {
    get(): AxiosPromise<THomeworksForCheckingEntity[]> {
        return BaseApi.get(`/homeworksforchecking/byLearningContentAndTask`)
    },
}

export { HomeworksForCheckingApi }
