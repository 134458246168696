import { useAppDispatch } from '@/store'
import { ModulesActions } from './modules.store'

const useModulesManager = () => {
    const dispatch = useAppDispatch()
    const get = () => dispatch(ModulesActions.get())
    return {
        get,
    }
}

export { useModulesManager }
