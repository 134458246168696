/* eslint-disable react/jsx-props-no-spreading */
import { Button, ButtonProps, makeStyles, Theme } from '@material-ui/core'

import React from 'react'
import clsx from 'clsx'

// ml = medium-large
enum PaddingButton {
    medium = '8px 14px',
    large = '14px 32px',
}
enum FontSizeButton {
    medium = 15,
    large = 20,
}
enum FontWidthButton {
    medium = 500,
    large = 400,
}

type TStyleProps = {
    size: keyof typeof PaddingButton
}
const useStyles = makeStyles((theme: Theme) => ({
    root: ({ size }: TStyleProps) => ({
        textTransform: 'none',
        fontStyle: 'normal',
        fontWeight: FontWidthButton[size],
        fontSize: FontSizeButton[size],
        lineHeight: '140%',
        letterSpacing: 0.1,
        borderRadius: 4,
        padding: PaddingButton[size],
    }),
    filled: {
        backgroundColor: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            borderColor: theme.palette.primary.light,
        },
    },
    filled2: {
        backgroundColor: '#D7D8DA',
        color: '#000000',
        '&:hover': {
            backgroundColor: '#D7D8DA',
        },
    },
    filled3: {
        backgroundColor: '#fff',
        color: 'rgba(62, 62, 66, 0.5)',
        borderRadius: 3,
        padding: '10px 18px!important',
        border: '1px solid #ebedef',
    },
    ghost: {
        border: `1px solid ${theme.palette.primary.main}`,
        color: '#000',
    },
}))

interface IUButtonProps extends ButtonProps {
    size?: 'medium' | 'large'
    view?: 'filled' | 'filled2' | 'filled3' | 'ghost' | 'root'
}

interface IUButton<T> extends React.FC<T> {
    Filled: React.FC<T>
    Filled2: React.FC<T>
    Filled3: React.FC<T>
    Ghost: React.FC<T>
}

const UButton: IUButton<IUButtonProps> = ({
    children,
    size = 'medium',
    view = 'root',
    className = '',
    ...rest
}: IUButtonProps) => {
    const classes = useStyles({ size })
    return (
        <Button
            {...rest}
            className={clsx([classes.root, classes[view], className])}
        >
            {children}
        </Button>
    )
}

UButton.Filled = ({ children, ...rest }: IUButtonProps) => (
    <UButton {...rest} view="filled">
        {children}
    </UButton>
)
UButton.Filled.displayName = 'Filled'

UButton.Filled2 = ({ children, ...rest }: IUButtonProps) => (
    <UButton {...rest} view="filled2">
        {children}
    </UButton>
)
UButton.Filled2.displayName = 'Filled2'

// eslint-disable-next-line react/display-name
UButton.Filled3 = ({ children, ...rest }: IUButtonProps) => (
    <UButton {...rest} view="filled3">
        {children}
    </UButton>
)
UButton.Filled2.displayName = 'Filled3'

UButton.Ghost = ({ children, ...rest }: IUButtonProps) => (
    <UButton {...rest} view="ghost">
        {children}
    </UButton>
)
UButton.Ghost.displayName = 'Ghost'

export default UButton
