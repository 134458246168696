import {
    Login,
    Main,
    NotFound,
    Courses,
    TrainingPrograms,
    ModulePage,
    FavoritesPage,
    CollectionPage,
    TrainingProgramPage,
} from '@/pages'
import React from 'react'
import HomeworkLk from '@/pages/lk/homework'
import CertificateLk from '@/pages/lk/certificate'
import CalendarLk from '@/pages/lk/calendar'
import NotificationLk from '@/pages/lk/notification'
import MythLk from '@/pages/lk/myth'
import LK from '@/pages/lk'
import LessonPage from '@/pages/lesson'
import CoursePage from '@/pages/course'
import MaterialPage from '@/pages/material'

const routes = {
    main: {
        path: '/',
        component: Main,
        exact: true,
    },
    login: {
        path: '/login',
        // eslint-disable-next-line react/display-name
        component: () => <Login variant="cloud" />,
        exact: true,
    },
    favorites: {
        path: '/favorites',
        component: FavoritesPage,
        exact: true,
    },
    module: {
        path: '/modules/:id',
        to: (id: string) => `/modules/${id}`,
        component: ModulePage,
        exact: true,
    },
    lesson: {
        path: '/lessons/:id',
        to: (id: string) => `/lessons/${id}`,
        component: LessonPage,
        exact: true,
    },
    material: {
        path: '/materials/:id',
        to: (id: string) => `/materials/${id}`,
        component: MaterialPage,
        exact: true,
    },
    trainingPrograms: {
        path: '/programs',
        component: TrainingPrograms,
        exact: true,
    },
    trainingProgram: {
        path: '/programs/:id',
        to: (id: string) => `/programs/${id}`,
        component: TrainingProgramPage,
        exact: true,
    },
    collection: {
        path: '/collections/:id',
        to: (id: string) => `/collections/${id}`,
        component: CollectionPage,
        exact: true,
    },
    courses: {
        path: '/courses',
        to: (id: string) => `/courses/${id}`,
        component: Courses,
        exact: true,
    },
    course: {
        path: '/courses/:id',
        to: (id: string) => `/courses/${id}`,
        component: CoursePage,
    },

    // lk - личный кабинет
    lk: {
        main: {
            path: '/lk',
            component: LK,
        },
        homework: {
            path: '/lk/homework',
            component: HomeworkLk,
            exact: true,
        },
        calendar: {
            path: '/lk/calendar',
            component: CalendarLk,
            exact: true,
        },
        certificate: {
            path: '/lk/certificate',
            component: CertificateLk,
            exact: true,
        },
        notification: {
            path: '/lk/notification',
            component: NotificationLk,
            exact: true,
        },
        myth: {
            path: '/lk/myth',
            component: MythLk,
            exact: true,
        },
    },

    // 404
    notFound: {
        path: '*',
        // eslint-disable-next-line react/display-name
        component: () => <NotFound variant="cloud" />,
    },
} as const

export default routes
