import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    titleBlock: {
        marginBottom: '58px ',
        [theme.breakpoints.down(767)]: {
            textAlign: 'center',
        },
    },
    chartBlock: {
        marginBottom: '15px',
        paddingBottom: '48px',
        borderBottom: '1px solid #EBEDEF',
        [theme.breakpoints.down(560)]: {
            paddingBottom: 0,
        },
    },
    totalCategory: {
        display: 'flex',
        marginTop: '58px',
        marginBottom: '40px ',
        flexWrap: 'wrap',
        [theme.breakpoints.down(767)]: {
            marginTop: '32px',
        },
    },
    totalCategoryItem: {
        marginRight: '32px',
        [theme.breakpoints.down(560)]: {
            marginTop: '24px',
        },
    },
    totalCommon: {},
}))
export default useStyles
