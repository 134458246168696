import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
    section: {
        padding: '64px 0 0 0',
        [theme.breakpoints.up('md')]: {
            padding: '80px 0 0 0 ',
            marginBottom: '-24px',
        },
        [theme.breakpoints.up('xl')]: {
            borderTop: 'none',
            padding: '132px 0 ',
        },
    },
}))
export default useStyles
