import React from 'react'
import { Box } from '@material-ui/core'

import UTitle from '@/components/ui/UTitle'
import UTabs, { UTab, UTabPanel } from '@/components/ui/UTabs'
import UGrid from '@/components/ui/UGrid'
import { useHistory } from 'react-router-dom'
import WCourseCard from '@/components/widget/WCourseCard'
import { useLearningPrograms } from '@/services/learningPrograms'
import routes from '@/routes'
import useStyles from './style'

interface ITab {
    id: string
    label: string
    value: string
}
interface IButton {
    id: string
    label: string
    checked: boolean
}
interface ISCoursesProps {
    settings: {
        title: string
        type?: string
        tabs: Array<ITab>
        initialSelectedTab: string
        buttons?: Array<IButton>
        // data?: Array
    }
}

const SLearningProgram: React.FC<ISCoursesProps> = ({
    settings,
}: ISCoursesProps): React.ReactElement => {
    const classes = useStyles({ type: settings?.type })
    const history = useHistory()

    const queryParams = new URLSearchParams(history.location.search)

    const programType = queryParams.get('tab') || settings.initialSelectedTab

    const LearningPrograms = useLearningPrograms()

    const handlerChangeCoursesType = (_e: any, value: string) => {
        queryParams.set('tab', value)
        history.replace({
            search: queryParams.toString(),
        })
    }

    return (
        <Box className={classes.borderBottom}>
            <UGrid.UContainer>
                <Box className={classes.section}>
                    <Box className={classes.titleWrapper}>
                        <UTitle
                            mdFont={32}
                            lgFont={42}
                            xlFont={42}
                            className={classes.sectionTitle}
                        >
                            {settings.title}
                        </UTitle>
                    </Box>
                    <Box className={classes.tabsWrapper}>
                        <UTabs
                            value={programType}
                            onChange={handlerChangeCoursesType}
                        >
                            {settings.tabs.map((tab) => (
                                <UTab
                                    key={tab.id}
                                    label={tab.label}
                                    value={tab.value}
                                />
                            ))}
                        </UTabs>
                    </Box>
                    <Box pt={3} width="100%">
                        <UTabPanel index="" value={programType}>
                            <Box
                                marginX={{ xs: 0, sm: 0, md: 0 }}
                                display={{ xs: 'grid' }}
                                gridTemplateColumns={{
                                    xs: '1fr',
                                    xl: '1fr 1fr',
                                }}
                                gridGap={{
                                    xs: '32px',
                                    md: '40px',
                                    lg: '48px',
                                }}
                            >
                                {LearningPrograms.list
                                    .filter((item) => item.status !== 'passed')
                                    .map((program) => {
                                        const {
                                            id,
                                            title,
                                            progress,
                                            dueDate,
                                            specialization,
                                            description,
                                            coverImage,
                                        } = program
                                        return (
                                            <Box key={id}>
                                                <WCourseCard
                                                    to={routes.trainingProgram.to(
                                                        id
                                                    )}
                                                    picture={coverImage?.url}
                                                    title={title}
                                                    progress={progress}
                                                    description={description}
                                                    date={dueDate.date}
                                                    specialization={
                                                        specialization.title
                                                    }
                                                    specializationId={
                                                        specialization.machineName
                                                    }
                                                />
                                            </Box>
                                        )
                                    })}
                            </Box>
                        </UTabPanel>
                        <UTabPanel index="passed" value={programType}>
                            <Box
                                marginX={{ xs: 0, sm: 0, md: 0 }}
                                display={{ xs: 'grid' }}
                                gridTemplateColumns={{
                                    xs: '1fr',
                                    xl: '1fr 1fr',
                                }}
                                gridGap={{
                                    xs: '32px',
                                    md: '40px',
                                    lg: '48px',
                                }}
                            >
                                {LearningPrograms.list
                                    .filter((item) => item.status === 'passed')
                                    .map((program) => {
                                        const {
                                            id,
                                            title,
                                            progress,
                                            dueDate,
                                            specialization,
                                            description,
                                            coverImage,
                                        } = program
                                        return (
                                            <Box key={id}>
                                                <WCourseCard
                                                    picture={coverImage?.url}
                                                    title={title}
                                                    progress={progress}
                                                    description={description}
                                                    date={dueDate.date}
                                                    specialization={
                                                        specialization.title
                                                    }
                                                    specializationId={
                                                        specialization.machineName
                                                    }
                                                />
                                            </Box>
                                        )
                                    })}
                            </Box>
                        </UTabPanel>
                    </Box>
                </Box>
            </UGrid.UContainer>
        </Box>
    )
}

export default SLearningProgram
