import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        minWidth: 80,
        height: 80,
        display: 'flex',
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px',
        [theme.breakpoints.down(768)]: {
            minWidth: 48,
            height: 48,
            background: 'transparent',
            border: '1px solid #EBEDEF',
        },
    },
}))
export default useStyles
