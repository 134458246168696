import { useAppSelector } from '@/store'

const useUser = () => {
    const user = useAppSelector((state) => state.user)

    return {
        ...user,
        get newNotification() {
            return (user.notifications || []).filter((item) => !item.isRead)
        },
    }
}

export { useUser }
