import React, { useEffect } from 'react'

import SHeader from '@/sections/SHeader'
import SSpeakers from '@/sections/SSpeakers'
import SFooter from '@/sections/SFooter'

import SCourseContent from '@/sections/SCourseContent'
import SCourseHeader from '@/sections/SCourseHeader'
import { useCourseManager } from '@/services/course'
import { useParams } from 'react-router-dom'
import SCourseStartTest from '@/sections/SCourseStartTest'
import SCourseLessons from '@/sections/SCourseLessons'
import SCourseEndTest from '@/sections/SCourseEndTest'
import { useSpeakersManager } from '@/services/speakers'
import useStyles from './style'

const CoursePage = (): JSX.Element => {
    const classes = useStyles()
    const CourseManager = useCourseManager()
    const SpeakersManager = useSpeakersManager()

    const params = useParams<{ id: string }>()

    useEffect(() => {
        CourseManager.get(params.id)
        SpeakersManager.get()
    }, [])

    return (
        <div className={classes.root}>
            <SHeader adaptiveMenuView="primary" isGoBackForAdaptive />
            <SCourseHeader />
            <hr className={classes.hr} />
            <SCourseContent />
            <hr className={classes.hr} />
            <SCourseStartTest />
            <SCourseLessons />
            <SCourseEndTest />
            <hr className={classes.hr} />
            <SSpeakers isModule />
            <SFooter />
        </div>
    )
}
export default CoursePage
