/* eslint-disable @typescript-eslint/unbound-method */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createAwaitAdapter, createListAdapter } from '@/store/common'
import { TUserShort } from '@/api/BaseApi'
import { SpeakersApi } from './speakers.api'

const NAME = 'speakers'

const get = createAsyncThunk(`${NAME}/get`, async () => {
    const speakers = await SpeakersApi.get()
    const list = speakers.data

    return {
        list,
    }
})

const SpeakersAwaitAdapter = createAwaitAdapter(NAME)
const SpeakersAdapter = createListAdapter<TUserShort>()
const initialState = SpeakersAdapter.getInitialState(
    SpeakersAwaitAdapter.getAwaitState()
)

const speakersStore = createSlice({
    name: NAME,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(get.fulfilled, SpeakersAdapter.set)
            .addMatcher(
                SpeakersAwaitAdapter.isFulfilled,
                SpeakersAwaitAdapter.fulfilled
            )
            .addMatcher(
                SpeakersAwaitAdapter.isPending,
                SpeakersAwaitAdapter.pending
            )
            .addMatcher(
                SpeakersAwaitAdapter.isRejected,
                SpeakersAwaitAdapter.rejected()
            )
    },
})

export const SpeakersActions = {
    get,
}
export default speakersStore.reducer
