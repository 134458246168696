import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    linkBlock: {
        display: 'block ',
        marginBottom: 24,
        fontWeight: 500,
        fontSize: 20,
        lineHeight: '160%',
        color: '#4C4C52',
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
        },
    },
    active: {
        color: '#3AB2C9',
        paddingLeft: 20,
        position: 'relative',
        '&::before': {
            content: "''",
            position: 'absolute',
            top: 0,
            left: 0,
            width: 4,
            height: 32,
            backgroundColor: '#3AB2C9',
        },
    },
}))
export default useStyles
