/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react'
import clsx from 'clsx'
import { Typography } from '@material-ui/core'
import LTitle from '@/components/ui/UTitle'
import { Link } from 'react-router-dom'
import LButton from '@/components/ui/UButton'
import {
    AnyButtonBlock,
    ContentInnerCommon,
    ContentInnerIWT,
    HeaderBlock,
    ImageBlock,
    ParagraphBlock,
} from './types'
import useStyles from './style'

interface ParagraphProps {
    block: ParagraphBlock
}
interface HeaderProps {
    block: HeaderBlock
}
interface ImageProps {
    block: ImageBlock
}
interface AnyButtonProps {
    block: AnyButtonBlock
}

export type TContent = Array<ContentInnerIWT | ContentInnerCommon>

const GenerateParagraph: React.FC<ParagraphProps> = ({
    block,
}: ParagraphProps): React.ReactElement => {
    const classes = useStyles({})
    return (
        <Typography variant="body1" className={classes.textBlock}>
            <div
                dangerouslySetInnerHTML={{
                    __html: block.data.text,
                }}
            />
        </Typography>
    )
}
const GenerateImage: React.FC<ImageProps> = ({
    block,
}: ImageProps): React.ReactElement => {
    const { stretched, withBorder, withBackground, center } = block.data
    const classes = useStyles({
        withBorder,
        withBackground,
        stretched,
        center,
    })
    return (
        <div className={classes.imgBlock}>
            <img
                className={classes.img}
                src={block.data.file.url}
                alt={block.data.caption}
            />
        </div>
    )
}
const GenerateHeader: React.FC<HeaderProps> = ({
    block,
}: HeaderProps): React.ReactElement => {
    const classes = useStyles({})
    const title = block.data.text || ''
    return (
        <div className={classes.headerBlock}>
            <LTitle.H2 variant={block.data?.level === 3 ? 'h2v2' : 'h2v3'}>
                <span dangerouslySetInnerHTML={{ __html: title }} />
            </LTitle.H2>
        </div>
    )
}
const GenerateAnyButton: React.FC<AnyButtonProps> = ({
    block,
}: AnyButtonProps): React.ReactElement => {
    // const classes = useStyles({})
    const title = block.data.text || ''
    const link = block.data.link || ''
    return (
        <Link to={link}>
            <LButton view="filled" size="large">
                {title}
            </LButton>
        </Link>
    )
}

const GenerateFlexBlock: React.FC<Pick<ContentInnerIWT, 'blocks'>> = ({
    blocks,
}) => {
    let $text: React.ReactElement
    let $img: React.ReactElement
    let $btn: React.ReactElement | null = null
    if (blocks[0].type === 'image') {
        const { stretched, withBorder, withBackground } = blocks[0].data
        const classes = useStyles({
            withBorder,
            withBackground,
            stretched,
        })
        $img = (
            <div className={clsx(classes.imgFlexBlock, classes.marginRight)}>
                <img
                    className={clsx(classes.flexImg)}
                    src={blocks[0].data.file.url}
                    alt={blocks[0].data.caption}
                />
            </div>
        )
        $text =
            blocks[1].type === 'paragraph' ? (
                <Typography variant="body1" className={classes.textBlock}>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: blocks[1].data.text,
                        }}
                    />
                </Typography>
            ) : (
                <></>
            )

        const btnBlock = blocks.find((block) => block.type === 'AnyButton')
        if (btnBlock?.type === 'AnyButton')
            $btn = <GenerateAnyButton block={btnBlock} />

        return (
            <div className={classes.flex}>
                <div className={classes.mbDownLg}>{$img}</div>
                <div className={classes.textWrapper}>
                    <div>{$text}</div>
                    {$btn && <div className={classes.textButton}>{$btn}</div>}
                </div>
            </div>
        )
    }
    if (blocks[1].type === 'image') {
        const { stretched, withBorder, withBackground } = blocks[1].data
        const classes = useStyles({
            withBorder,
            withBackground,
            stretched,
        })
        $img = (
            <div className={clsx(classes.imgFlexBlock, classes.marginLeft)}>
                <img
                    className={clsx(classes.img, classes.flexImg)}
                    src={blocks[1].data.file.url}
                    alt={blocks[1].data.caption}
                />
            </div>
        )
        $text = (
            <Typography variant="body1" className={classes.textBlock}>
                {blocks[0].data.text}
            </Typography>
        )
        return (
            <div className={classes.flex}>
                <div className={classes.secondOrderDownLg}>{$text}</div>
                <div
                    className={clsx(classes.firstOrderDownLg, classes.mbDownLg)}
                >
                    {$img}
                </div>
            </div>
        )
    }
    return <></>
}

const Runner: React.FC<{ content: TContent }> = ({ content }) => {
    const classes = useStyles({})
    return (
        <>
            {content.map((item, idx) => {
                switch (item.contentType) {
                    case 'image-with-text': {
                        return (
                            <div
                                key={idx}
                                className={clsx(classes.section, {
                                    [classes.sectionTop]: idx === 0,
                                })}
                            >
                                <GenerateFlexBlock blocks={item.blocks} />
                            </div>
                        )
                    }
                    case 'common': {
                        return (
                            <div
                                key={idx}
                                className={clsx(classes.section, {
                                    [classes.sectionTop]: idx === 0,
                                })}
                            >
                                {item.blocks.map((block, idx2) => {
                                    switch (block.type) {
                                        case 'image': {
                                            return (
                                                <GenerateImage
                                                    key={idx2}
                                                    block={block}
                                                />
                                            )
                                        }
                                        case 'paragraph': {
                                            return (
                                                <GenerateParagraph
                                                    key={idx2}
                                                    block={block}
                                                />
                                            )
                                        }
                                        case 'header': {
                                            return (
                                                <GenerateHeader
                                                    key={idx2}
                                                    block={block}
                                                />
                                            )
                                        }
                                        case 'AnyButton': {
                                            return (
                                                <GenerateAnyButton
                                                    block={block}
                                                    key={idx2}
                                                />
                                            )
                                        }
                                        default:
                                            return null
                                    }
                                })}
                            </div>
                        )
                    }
                    default:
                        return null
                }
            })}
        </>
    )
}

export default Runner
