import React, { useState } from 'react'
import { Modal, useMediaQuery } from '@material-ui/core'
import { Link, NavLink } from 'react-router-dom'
import clsx from 'clsx'

import Input from '@/components/ui/UInput'
// import MainLogo from '../main-logo'
import UAvatar from '@/components/ui/UAvatar'
import UButton from '@/components/ui/UButton'
import HeaderLogo from '@/components/ui/ULogo/TypeOne'
import LArrowBack from '@/components/ui/UArrowBack'
import UGrid from '@/components/ui/UGrid'
import theme from '@/theme'
import { useUser } from '@/services/user'
import routes from '@/routes'
import useStyles from './style'

import BurgerBtn from './components/ButtonBurger'
import BurgerMenu from './components/HeaderMenu'

enum HeaderVariants {
    light = 'light',
    primary = 'primary',
}

const colorMenuOverlay = {
    [HeaderVariants.light]: theme.palette.primary.contrastText,
    [HeaderVariants.primary]: theme.palette.secondary.main,
}
const colorMenuComponents = {
    [HeaderVariants.light]: theme.palette.text.primary,
    [HeaderVariants.primary]: theme.palette.primary.contrastText,
}
interface HeaderProps {
    adaptiveDark: boolean
    isGoBackForAdaptive: boolean
    adaptiveMenuView: keyof typeof HeaderVariants
}
// TODO: Будет еще логика возвращения на уровень назад по клику на Link
/**
 * UHeader
 *
 * @param adaptiveDark - Меняет контраст header при тёмном фоне (включая бургер меню)
 * @param isGoBackForAdaptive - Если true меняет лого на стрелку назад
 * @param adaptiveMenuView - Принимает назначению тем (есть 2)
 */

const SHeader: React.FC<Partial<HeaderProps>> = ({
    adaptiveDark = false,
    isGoBackForAdaptive = false,
    adaptiveMenuView = 'light',
}: Partial<HeaderProps>): React.ReactElement => {
    const menuColor = colorMenuOverlay[adaptiveMenuView]
    const menuComponentsColor = adaptiveDark
        ? '#fff'
        : colorMenuComponents[adaptiveMenuView]

    const classes = useStyles({ adaptiveDark, menuColor })

    const screen768 = useMediaQuery(theme.breakpoints.down('md'))

    const [showBurgerMenu, setShowBurgerMenu] = useState(false)
    const handlerClickBurgerBtn = () => {
        setShowBurgerMenu(true)
    }
    const handleCloseBurgerMenu = () => {
        setShowBurgerMenu(false)
    }

    const user = useUser()

    return (
        <UGrid.UContainer className={classes.overlay}>
            <div className={classes.root}>
                {isGoBackForAdaptive && screen768 ? (
                    <LArrowBack
                        to={routes.main.path}
                        color={menuComponentsColor}
                    />
                ) : (
                    <div className={classes.logoblock}>
                        <HeaderLogo isThemeSwitch={adaptiveDark} />
                    </div>
                )}

                <BurgerBtn
                    onClick={handlerClickBurgerBtn}
                    color={menuComponentsColor}
                />
                <Modal open={showBurgerMenu}>
                    <BurgerMenu onClose={handleCloseBurgerMenu} />
                </Modal>

                <div className={classes.headercontents}>
                    <Input
                        placeholder="Поиск по курсам"
                        className={classes.input}
                        isButtonInput={false}
                    />

                    <Input
                        placeholder=""
                        className={classes.input}
                        isButtonInput
                    />

                    <nav>
                        <ul className={classes.nav}>
                            <li
                                className={clsx(
                                    classes.navlink,
                                    classes.allcourses
                                )}
                            >
                                <NavLink to={routes.courses.path}>
                                    <UButton
                                        size="medium"
                                        view="filled3"
                                        style={{
                                            minWidth: '115px ',
                                        }}
                                        className={classes.breakpointBtnLg}
                                    >
                                        Все курсы
                                    </UButton>
                                </NavLink>
                            </li>
                            <li
                                className={clsx(
                                    classes.navlink,
                                    classes.widenavlink
                                )}
                            >
                                <NavLink to={routes.trainingPrograms.path}>
                                    <UButton
                                        size="medium"
                                        view="filled3"
                                        style={{
                                            minWidth: '201px ',
                                        }}
                                        className={classes.breakpointBtnLg}
                                    >
                                        Программы обучения
                                    </UButton>
                                </NavLink>
                            </li>
                            <li className={classes.navlink}>
                                <NavLink to={routes.favorites.path}>
                                    <UButton
                                        size="medium"
                                        view="filled3"
                                        style={{
                                            minWidth: '115px',
                                        }}
                                        className={classes.breakpointBtnLg}
                                    >
                                        Избранное
                                    </UButton>
                                </NavLink>
                            </li>
                        </ul>
                    </nav>

                    <div className={classes.avatarblock}>
                        <Link to={routes.lk.main.path}>
                            <UAvatar
                                size={51}
                                notificationCount={user.newNotification.length}
                                src={user.image?.url}
                                alt="Profile"
                            />
                        </Link>
                    </div>
                </div>
            </div>
        </UGrid.UContainer>
    )
}
export default SHeader
