/* eslint-disable @typescript-eslint/unbound-method */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createAwaitAdapter, createListAdapter } from '@/store/common'
import {
    createFavoriteAddAction,
    createFavoriteRemoveAction,
} from '@/services/favorites'
import { CollectionsApi } from './collections.api'
import { TCollectionEntity } from '../collection'

const NAME = 'collections'

const get = createAsyncThunk(`${NAME}/get`, async () => {
    const collections = await CollectionsApi.get()
    const list = collections.data

    return {
        list,
    }
})

const [addToFavorite, addToFavoriteHandler] = createFavoriteAddAction(
    NAME,
    'collection'
)
const [
    removeFromFavorite,
    removeFromFavoriteHandler,
] = createFavoriteRemoveAction(NAME, 'collection')

const CollectionsAdapter = createListAdapter<TCollectionEntity>()
const CollectionsAwaitAdapter = createAwaitAdapter(NAME)
const initialState = CollectionsAdapter.getInitialState(
    CollectionsAwaitAdapter.getAwaitState()
)
const collectionsStore = createSlice({
    name: NAME,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(get.fulfilled, CollectionsAdapter.set)
            .addCase(addToFavorite.fulfilled, addToFavoriteHandler)
            .addCase(removeFromFavorite.fulfilled, removeFromFavoriteHandler)
            .addMatcher(
                CollectionsAwaitAdapter.isFulfilled,
                CollectionsAwaitAdapter.fulfilled
            )
            .addMatcher(
                CollectionsAwaitAdapter.isPending,
                CollectionsAwaitAdapter.pending
            )
            .addMatcher(
                CollectionsAwaitAdapter.isRejected,
                CollectionsAwaitAdapter.rejected()
            )
    },
})

export const CollectionsActions = {
    get,
    addToFavorite,
    removeFromFavorite,
}
export default collectionsStore.reducer
