import React from 'react'
import useStyles from './style'

// import triangle from './svg/triangle.svg'

interface ButtonProps {
    title: string
    onClick?(e: React.MouseEvent): void
}

const UPlayButton: React.FC<ButtonProps> = ({
    title,
    onClick,
}: ButtonProps): React.ReactElement => {
    const classes = useStyles()
    return (
        <button type="button" className={classes.root} onClick={onClick}>
            <div className={classes.btn}>
                <svg
                    width="12"
                    height="13"
                    viewBox="0 0 12 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M12 6.5L0.75 12.9952L0.750001 0.00480889L12 6.5Z"
                        fill="black "
                    />
                </svg>
            </div>
            <div className={classes.title}>
                <span style={{ display: 'block' }}>{title}</span>
            </div>
        </button>
    )
}
export default UPlayButton
