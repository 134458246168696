import React, { forwardRef } from 'react'
import { NavLink } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'

import UInput from '@/components/ui/UInput'
import NotificationIndicator from '@/components/ui/UNotificationIndicator'
import HeaderLogo from '@/components/ui/ULogo/TypeOne'
import routes from '@/routes'
import useStyles from './style'
import CloseBtn from './components/button-close-menu'

interface BurgerMenuProps {
    onClose: () => void
}

const BurgerMenu = forwardRef<HTMLDivElement, BurgerMenuProps>(
    ({ onClose }: BurgerMenuProps, ref): React.ReactElement => {
        const classes = useStyles()
        return (
            <div ref={ref}>
                <AnimatePresence>
                    <motion.div
                        className={classes.root}
                        initial={{ opacity: 0.1, y: 500 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0.1, y: 500 }}
                        transition={{ duration: 0.3 }}
                    >
                        <div className={classes.menuHeader}>
                            <HeaderLogo color="#fff" />
                            <CloseBtn onClick={onClose} />
                        </div>
                        <div className={classes.menuNav}>
                            <ul className={classes.menuNavList}>
                                <li className={classes.menuNavItem}>
                                    <NavLink
                                        className={classes.menuNavItemLink}
                                        to={routes.courses.path}
                                    >
                                        Все курсы
                                    </NavLink>
                                </li>
                                <li className={classes.menuNavItem}>
                                    <NavLink
                                        className={classes.menuNavItemLink}
                                        to={routes.lk.main.path}
                                    >
                                        <NotificationIndicator
                                            count={3}
                                            color="#2096BD"
                                            right="-80px"
                                            top="-4px"
                                        />
                                        Профиль
                                    </NavLink>
                                </li>
                                <li className={classes.menuNavItem}>
                                    <NavLink
                                        className={classes.menuNavItemLink}
                                        to={routes.lk.notification.path}
                                    >
                                        Уведомления
                                    </NavLink>
                                </li>
                                <li className={classes.menuNavItem}>
                                    <NavLink
                                        className={classes.menuNavItemLink}
                                        to={routes.trainingPrograms.path}
                                    >
                                        Программы обучения
                                    </NavLink>
                                </li>
                                <li className={classes.menuNavItem}>
                                    <NavLink
                                        className={classes.menuNavItemLink}
                                        to={routes.favorites.path}
                                    >
                                        Избранное
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                        <UInput placeholder="Поиск по курсам" light fullWidth />
                    </motion.div>
                </AnimatePresence>
            </div>
        )
    }
)
BurgerMenu.displayName = 'BurgerMenu'
export default BurgerMenu
