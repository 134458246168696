import { useAppDispatch } from '@/store'
import { MaterialActions } from './material.store'

const useMaterialManager = () => {
    const dispatch = useAppDispatch()
    const get = (id: string) => dispatch(MaterialActions.get(id))
    return {
        get,
    }
}

export { useMaterialManager }
