/* eslint-disable @typescript-eslint/unbound-method */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createAwaitAdapter, TAwaitState } from '@/store/common'
import {
    LearningProgramApi,
    TLearningProgramEntity,
} from './learningProgram.api'

const NAME = 'learningProgram'

const get = createAsyncThunk(`${NAME}/get`, async (id: string) => {
    const learningProgram = await LearningProgramApi.get(id)
    const { data } = learningProgram

    return data
})

const LearningProgramAwaitAdapter = createAwaitAdapter(NAME)

export interface TLearningProgramInitialState extends TAwaitState {
    entity: TLearningProgramEntity | null
}

const initialState: TLearningProgramInitialState = {
    entity: null,
    ...LearningProgramAwaitAdapter.getAwaitState(),
}
const learningProgramStore = createSlice({
    name: NAME,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(get.fulfilled, (state, { payload }) => {
                state.entity = payload
            })
            .addMatcher(
                LearningProgramAwaitAdapter.isFulfilled,
                LearningProgramAwaitAdapter.fulfilled
            )
            .addMatcher(
                LearningProgramAwaitAdapter.isPending,
                LearningProgramAwaitAdapter.pending
            )
            .addMatcher(
                LearningProgramAwaitAdapter.isRejected,
                LearningProgramAwaitAdapter.rejected()
            )
    },
})

export const LearningProgramActions = {
    get,
}
export default learningProgramStore.reducer
