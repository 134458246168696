/* eslint-disable @typescript-eslint/unbound-method */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createAwaitAdapter, TAwaitState } from '@/store/common'
import { TUserShort } from '@/api/BaseApi'
import { SpeakerApi } from './speaker.api'

const NAME = 'speaker'

const get = createAsyncThunk(`${NAME}/get`, async (id: string) => {
    const speaker = await SpeakerApi.get(id)
    const { data } = speaker

    return data
})

const SpeakerAwaitAdapter = createAwaitAdapter(NAME)

export type TSpeakerInitialState = TAwaitState & Partial<TUserShort>

const initialState: TSpeakerInitialState = {
    ...SpeakerAwaitAdapter.getAwaitState(),
}
const speakerStore = createSlice({
    name: NAME,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(get.fulfilled, (state, { payload }) => ({
                ...state,
                ...payload,
            }))
            .addMatcher(
                SpeakerAwaitAdapter.isFulfilled,
                SpeakerAwaitAdapter.fulfilled
            )
            .addMatcher(
                SpeakerAwaitAdapter.isPending,
                SpeakerAwaitAdapter.pending
            )
            .addMatcher(
                SpeakerAwaitAdapter.isRejected,
                SpeakerAwaitAdapter.rejected()
            )
    },
})

export const SpeakerActions = {
    get,
}
export default speakerStore.reducer
