import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('xl')]: {
            flexWrap: 'wrap',
        },
    },
    title: {
        fontWeight: 500,
        fontSize: 32,
        lineHeight: '120%',
        letterSpacing: -0.3,
        color: '#000000',
        marginBottom: 56,
    },
    certificateBlock: {
        maxWidth: 706,
        width: '100%',
        marginBottom: 48,
        [theme.breakpoints.up('xl')]: {
            maxWidth: 518,
            marginRight: 32,
        },
    },
}))
export default useStyles
