import React from 'react'

import AvatarBlock from '@/components/ui/UAvatar'
import useStyles from './style'

interface SpeakerProps {
    name: string
    roleName: string
    image: string
}

const Speaker: React.FC<SpeakerProps> = ({
    name,
    roleName,
    image,
}: SpeakerProps): React.ReactElement => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <AvatarBlock
                size={128}
                src={image}
                alt="Speaker"
                className={classes.adaptiveAvatar}
            />

            <div className={classes.name}>{name}</div>
            <div className={classes.position}>{roleName}</div>
        </div>
    )
}
export default React.memo(Speaker)
