/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import { makeStyles, Theme } from '@material-ui/core'
import cn from 'classnames'

import React, { useState } from 'react'

interface ILCheckboxProps {
    label: string
    checked?: boolean
    onChange?(event: React.ChangeEvent<HTMLInputElement>): void
}

const useStyles = makeStyles((theme: Theme) => ({
    label: {
        display: 'block',
        backgroundColor: '#f6f7f9',
        borderRadius: '2px',
        padding: '6px 8px',
        fontSize: '10px',
        lineHeight: '14px',
        fontWeight: 500,
        cursor: 'pointer',
        [theme.breakpoints.up('md')]: {
            padding: '8px 16px',
            fontSize: '15px',
            lineHeight: '21px',
        },
    },
    checked: {
        backgroundColor: '#00a8d7',
        color: '#fff',
    },
}))

const LCheckbox = ({
    label,
    checked,
    onChange,
}: ILCheckboxProps): React.ReactElement => {
    const classes = useStyles()
    const [isActive, setIsActive] = useState(false)
    const handleActive = () => {
        setIsActive(!isActive)
    }

    return (
        <label
            className={cn(classes.label, {
                [classes.checked]: checked || isActive,
            })}
        >
            <input
                type="checkbox"
                hidden
                onChange={onChange || handleActive}
                checked={checked || isActive}
            />
            {label}
        </label>
    )
}

export default LCheckbox
