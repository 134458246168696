import React from 'react'
import { Typography } from '@material-ui/core'
import clsx from 'clsx'
import useStyles from './style'
// Todo ILesson нужно будет вынести в глобальные типы
// interface ILesson {
//     lessonId: number
//     id: string
//     title: string
//     // 'notStarted' | 'inProgress' | 'completed'
//     status: string
//     to: string
// }
interface IUCourseLessons {
    title?: string
    children: React.ReactChild
    className?: string
}
const WCourseLessons: React.FC<IUCourseLessons> = ({
    children,
    title,
    className,
}: IUCourseLessons): React.ReactElement => {
    const classes = useStyles()
    return (
        <div className={clsx(classes.root, className)}>
            {title && (
                <Typography variant="subtitle2" className={classes.title}>
                    {title}
                </Typography>
            )}
            <div className={classes.content}>{children}</div>
        </div>
    )
}
export default WCourseLessons
