/* eslint-disable @typescript-eslint/unbound-method */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createAwaitAdapter, createListAdapter } from '@/store/common'
import {
    createFavoriteAddAction,
    createFavoriteRemoveAction,
} from '@/services/favorites'
import { CoursesApi } from './courses.api'
import { TCourseEntity } from '../course'

const NAME = 'courses'

const get = createAsyncThunk(`${NAME}/get`, async () => {
    const courses = await CoursesApi.get()
    const list = courses.data

    return {
        list,
    }
})

const [addToFavorite, addToFavoriteHandler] = createFavoriteAddAction(
    NAME,
    'course'
)
const [
    removeFromFavorite,
    removeFromFavoriteHandler,
] = createFavoriteRemoveAction(NAME, 'course')

const CoursesAwaitAdapter = createAwaitAdapter(NAME)
const CoursesAdapter = createListAdapter<TCourseEntity>()
const initialState = CoursesAdapter.getInitialState(
    CoursesAwaitAdapter.getAwaitState()
)

const coursesStore = createSlice({
    name: NAME,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(get.fulfilled, CoursesAdapter.set)
            .addCase(addToFavorite.fulfilled, addToFavoriteHandler)
            .addCase(removeFromFavorite.fulfilled, removeFromFavoriteHandler)
            .addMatcher(
                CoursesAwaitAdapter.isFulfilled,
                CoursesAwaitAdapter.fulfilled
            )
            .addMatcher(
                CoursesAwaitAdapter.isPending,
                CoursesAwaitAdapter.pending
            )
            .addMatcher(
                CoursesAwaitAdapter.isRejected,
                CoursesAwaitAdapter.rejected()
            )
    },
})

export const CoursesActions = {
    get,
    addToFavorite,
    removeFromFavorite,
}
export default coursesStore.reducer
