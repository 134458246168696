import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
    section: {
        padding: '64px 0 0 0',
        [theme.breakpoints.up('md')]: {
            padding: '80px 0 60px 0',
        },
        //   [theme.breakpoints.up('lg')]: {
        //       padding: '80px 0 60px 0',
        //   },
        [theme.breakpoints.up('xl')]: {
            padding: '132px 0 112px 0',
        },
        // borderTop: '1px solid #EBEDEF',
        borderBottom: '1px solid #EBEDEF',
    },
    collectionWrapper: {
        margin: `0 -${theme.spacing(4) / 2}px`,
        [theme.breakpoints.up('md')]: {
            margin: '0',
        },
    },
    content: {},
    titleBlock: {},
    title: {
        marginBottom: 24,
    },
    subtitle: {
        marginBottom: 62,
    },
    rowBlock: {
        marginTop: -32,
        [theme.breakpoints.up('md')]: {
            marginTop: -40,
        },
        [theme.breakpoints.up('xl')]: {
            marginTop: -60,
        },
    },
    colBlock: {
        marginTop: 32,
        [theme.breakpoints.up('md')]: {
            marginTop: 40,
        },
        [theme.breakpoints.up('xl')]: {
            marginTop: 60,
        },
    },
    lessonBlock: {
        marginTop: 32,
    },
}))
export default useStyles
