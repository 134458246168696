import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
    footerblock: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    root: {
        padding: '48px 0 56px',
    },
    logoBlock: {},

    links: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },

    linksitems: {
        listStyle: 'none',
        display: 'flex',
        alignItems: 'center',
    },

    linksitem: {
        marginLeft: '30px',
    },

    linksdom: {
        marginTop: '18px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '15px',
        lineHeight: '140%',
        letterSpacing: '0.1px',
        color: '#b8b8c3',
        fontFamily: 'NormsPro Regular TT',
    },
}))
export default useStyles
