/* eslint-disable @typescript-eslint/unbound-method */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createAwaitAdapter, TAwaitState } from '@/store/common'
import { FavoritesApi, TFavoriteData, TFavoriteEntity } from './favorites.api'

const NAME = 'favorites'

const get = createAsyncThunk(`${NAME}/get`, async () => {
    const favorites = await FavoritesApi.get()
    const entity = favorites.data

    return entity
})

type TFavoritePropsTemplate = {
    [K in keyof TFavoriteData]: {
        type: K
        data: TFavoriteData[K]
    }
}

export type TAddToFavoriteProps = TFavoritePropsTemplate[keyof TFavoritePropsTemplate]

const add = createAsyncThunk(
    `${NAME}/add`,
    async (props: TAddToFavoriteProps) => {
        const { data } = props
        const { id } = data
        await FavoritesApi.add({ contentId: id })
        return props
    }
)

type TFavoriteRemovePropsTemplate = {
    [K in keyof TFavoriteData]: {
        type: K
        id: TFavoriteData[K]['id']
    }
}
export type TRemoveFromFavoriteProps = TFavoriteRemovePropsTemplate[keyof TFavoriteRemovePropsTemplate]

const remove = createAsyncThunk(
    `${NAME}/remove`,
    async (props: TRemoveFromFavoriteProps) => {
        await FavoritesApi.remove({ contentId: props.id })
        return props
    }
)

const FavoritesAwaitAdapter = createAwaitAdapter(NAME)
export interface TFavoriteInitialState extends TAwaitState {
    entity: TFavoriteEntity | null
}

const initialState: TFavoriteInitialState = {
    ...FavoritesAwaitAdapter.getAwaitState(),
    entity: null,
}

const favoritesStore = createSlice({
    name: NAME,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(get.fulfilled, (state, { payload }) => {
                state.entity = payload
            })
            .addCase(add.fulfilled, (state, { payload }) => {
                if (state.entity)
                    state.entity[payload.type].data = [
                        ...state.entity[payload.type].data,
                        payload.data,
                    ]
            })
            .addCase(remove.fulfilled, (state, { payload }) => {
                if (state.entity)
                    state.entity[payload.type].data.forEach((item, index) => {
                        if (item.id === payload.id && state.entity)
                            state.entity[payload.type].data.splice(index, 1)
                    })
            })
            .addMatcher(
                FavoritesAwaitAdapter.isPending,
                FavoritesAwaitAdapter.pending
            )
            .addMatcher(
                FavoritesAwaitAdapter.isRejected,
                FavoritesAwaitAdapter.rejected()
            )
    },
})

export const FavoritesActions = {
    get,
    add,
    remove,
}
export default favoritesStore.reducer
