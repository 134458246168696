import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        overflow: 'auto',
        display: 'flex',
        backgroundColor: '#F6F7F9',
        borderTop: '1px solid #EBEDEF',
    },
    root: {
        display: 'flex',
        justifyContent: 'center',

        [theme.breakpoints.down('sm')]: {
            overflowX: 'auto',
            justifyContent: 'center',
            width: 'initial',
            flexShrink: 0,
        },
    },
    linkBlock: {
        display: 'block ',
        padding: '24px 0',
        fontWeight: 400,
        fontSize: 20,
        lineHeight: '160%',
        color: '#4C4C52',
        '& + &': {
            // marginRight: 0,
            marginLeft: 72,
        },
        [theme.breakpoints.down('md')]: {
            flexShrink: 0,
            '& + &': {
                // marginRight: 0,
                marginLeft: 40,
            },
        },
        [theme.breakpoints.down('sm')]: {
            '& + &': {
                // marginRight: 0,
                marginLeft: 16,
            },
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
            padding: '16px 0',
        },
    },
    active: {
        color: '#2096BD',
        position: 'relative',
        '&::before': {
            content: "''",
            position: 'absolute ',
            bottom: 0,
            left: 0,
            width: '100%',
            height: 4,
            backgroundColor: '#3AB2C9',
        },
    },
}))
export default useStyles
