import React from 'react'

import { NavLink } from 'react-router-dom'
import UGrid from '@/components/ui/UGrid'
import routes from '@/routes'
import useStyles from './style'

const LNavLinkNavigation = () => {
    const classes = useStyles()
    return (
        <div
            style={{ overflow: 'auto', display: 'flex' }}
            className={classes.wrapper}
        >
            <UGrid.UContainer className={classes.root}>
                <div style={{ display: 'inline-flex', margin: '0 auto' }}>
                    <NavLink
                        to={routes.lk.homework.path}
                        className={classes.linkBlock}
                        activeClassName={classes.active}
                    >
                        Проверка ДЗ
                    </NavLink>
                    <NavLink
                        to={routes.lk.notification.path}
                        className={classes.linkBlock}
                        activeClassName={classes.active}
                    >
                        Уведомления
                    </NavLink>
                    <NavLink
                        to={routes.lk.certificate.path}
                        className={classes.linkBlock}
                        activeClassName={classes.active}
                    >
                        Сертификаты
                    </NavLink>
                    <NavLink
                        to={routes.lk.calendar.path}
                        className={classes.linkBlock}
                        activeClassName={classes.active}
                    >
                        Календарь событий
                    </NavLink>
                    <NavLink
                        to={routes.lk.myth.path}
                        className={classes.linkBlock}
                        activeClassName={classes.active}
                    >
                        МИФ
                    </NavLink>
                </div>
            </UGrid.UContainer>
        </div>
    )
}
export default LNavLinkNavigation
