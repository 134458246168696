import { Grid, withStyles } from '@material-ui/core'
import React from 'react'

const UCol = withStyles(() => ({
    root: {
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
    },
}))(({ children, ...rest }: React.ComponentProps<typeof Grid>) => (
    <Grid {...rest} item>
        {children}
    </Grid>
))

export default UCol
