import { makeStyles, Theme } from '@material-ui/core/styles'

interface Props {
    dark: boolean
    color: string
}
const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        outline: 'none',
        width: '52px ',
        height: '52px',
        display: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'block',
        },
    },
    burgerblock: {
        display: 'none',
        position: 'relative',
        cursor: 'pointer',
        outline: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'block',
            textAlign: 'center',
            borderRadius: '50%',
        },
    },

    burgerelem: {
        width: '32px',
        height: '3px',
        backgroundColor: ({ dark, color }: Props) => {
            if (color.length > 0) {
                return color
            }
            if (dark) {
                return '#000'
            }
            return '#fff'
        },
        '&:first-child': {
            marginBottom: 9,
        },
    },
}))
export default useStyles
