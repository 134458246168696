import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    wrapper: {
        marginTop: 40,
        [theme.breakpoints.up('md')]: {
            marginTop: 84,
        },
    },
    hr: {
        margin: 0,
        border: 0,
        height: 0,
        [theme.breakpoints.up('xl')]: {
            height: 1,
            background: '#EBEDEF ',
        },
    },
    titleBlock: {
        marginBottom: 16,
        [theme.breakpoints.up('md')]: {
            display: 'flex ',
            alignItems: 'center',
            marginBottom: 56,
        },
    },
    arrowBackBlock: {
        display: 'none',

        [theme.breakpoints.up('md')]: {
            background: ' #F6F7F9',
            borderRadius: '4px',
            display: 'block ',
            padding: 5,
            width: 40,
            height: 40,
            marginRight: 16,
        },
    },
    breadCrumbs: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            marginBottom: 40,
            display: 'block ',
        },
    },
}))
export default useStyles
