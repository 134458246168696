import BaseApi, { TImage } from '@/api/BaseApi'
import { AxiosPromise } from 'axios'
import type { TCourseEntity } from '@/services/course'
import type { TSpecialization } from '@/api/BaseApi'
import type { TLessonEntity } from '@/services/lesson'
import { TContent } from '@/utils/Runner'
import { TCollectionEntity } from '../collection'

export type TTag = {
    id: string
    machineName: string
    title: string
}

export type TCategory = {
    // uuid категории
    id: string

    // Машинное имя категории
    machineName: string

    // Название категории
    title: string
}
export type TModuleEntity = {
    moduleId?: number
    title: string
    href?: string
    courses?: TCourseEntity[]

    // uuid модуля
    id: string

    // Машинное имя модуля
    machineName?: string
    category?: TCategory
    specialization: TSpecialization
    content?: TContent
    lessons?: TLessonEntity[]
    tags: TTag[]
    coverImage: TImage
    description?: string
    collections?: TCollectionEntity[]
}

const ModuleApi = {
    get(id: string): AxiosPromise<TModuleEntity> {
        return BaseApi.get(`/modules/${id}`)
    },
}

export { ModuleApi }
