import { useAppDispatch } from '@/store'
import { FavoritesActions } from './favorites.store'

const useFavoritesManager = () => {
    const dispatch = useAppDispatch()

    const get = () => dispatch(FavoritesActions.get())

    const addToFavorite = (...args: Parameters<typeof FavoritesActions.add>) =>
        dispatch(FavoritesActions.add(...args))

    const removeFromFavorite = (
        ...args: Parameters<typeof FavoritesActions.remove>
    ) => dispatch(FavoritesActions.remove(...args))

    const toggleFavorite = (...args: Parameters<typeof addToFavorite>) => (
        favorited: boolean
    ) => {
        if (favorited)
            removeFromFavorite({
                id: args[0].data.id,
                type: args[0].type,
            })
        else addToFavorite(...args)
    }

    return {
        get,
        addToFavorite,
        removeFromFavorite,
        toggleFavorite,
    }
}

export { useFavoritesManager }
