/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react'
import UGrid from '@/components/ui/UGrid'
import UMain from '@/components/ui/UMain/TypeOne'
import SHeader from '../SHeader'
import useStyles from './style'

// TODO: Нужно будет перенести playerBtn когда появиться player
const MainContent: React.FC = (): React.ReactElement => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <div className={classes.headerblock}>
                <SHeader adaptiveDark />
            </div>
            <UGrid.UContainer>
                <UMain />
            </UGrid.UContainer>
        </div>
    )
}
export default MainContent
