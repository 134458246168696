import React from 'react'
import { Typography } from '@material-ui/core'
import useStyles from './style'
import AppTheme from '../../../../theme'
import LTitle from '../../UTitle'
import { ReactComponent as ArrowRight } from '../../../svg/ArrowRight.svg'

const ColorVariants = [
    AppTheme.palette.success.main,
    AppTheme.palette.text.hint,
] as const
interface ITitleCourseLink extends React.HTMLAttributes<HTMLDivElement> {
    theme?: 0 | 1
    title: string
    arrowText?: string
    onClickArrow?: (event: React.MouseEvent) => void
}
const TypeOne: React.FC<ITitleCourseLink> = ({
    theme = 0,
    title,
    arrowText = '',
    onClickArrow = () => undefined,
    ...rest
}: ITitleCourseLink): React.ReactElement => {
    const classes = useStyles()
    return (
        <div {...rest} className={classes.root}>
            <div>
                <LTitle.H3 variant="h3v3" className={classes.title}>
                    {title}
                </LTitle.H3>
            </div>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div onClick={onClickArrow} className={classes.linkBlock}>
                <Typography
                    variant="subtitle1"
                    className={classes.linkTitle}
                    style={{
                        color: ColorVariants[theme],
                    }}
                >
                    {arrowText}
                </Typography>
                <ArrowRight />
            </div>
        </div>
    )
}

export default TypeOne
