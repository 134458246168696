import React from 'react'
import { Avatar } from '@material-ui/core'

import useStyles from './style'
import NotificationIndicator from '../UNotificationIndicator'

const DefaultProps = {
    className: '' as '' | string,
}
type DefaultPropsType = Readonly<typeof DefaultProps>

interface AvatarProps extends Partial<DefaultPropsType> {
    notificationCount?: number
    size?: number
    src?: string
    alt?: string
    children?: React.ReactChild
}
/**
 * Автар
 * @param size - Диаметр аватара
 * @param src - Картинка аватара
 * @param alt - Алтернативны текст картинки
 * @param notificationCount - Количество уведомлений, если они есть
 */

const AvatarBlock: React.FC<AvatarProps> = ({
    notificationCount = 0,
    size = 0,
    src = '',
    alt = '',
    className = '',
    children,
    ...rest
}: AvatarProps): React.ReactElement => {
    const classes = useStyles({ size })
    if (children) {
        return (
            <Avatar {...rest} className={`${classes.avatar} ${className}`}>
                {children}
            </Avatar>
        )
    }
    return (
        <div className={`${classes.root} ${className}`}>
            <Avatar
                {...rest}
                className={`${classes.avatar} ${className}`}
                src={src}
                alt={alt}
            />
            {notificationCount > 0 && (
                <NotificationIndicator
                    count={notificationCount}
                    color="#DE1F06"
                />
            )}
        </div>
    )
}
export default AvatarBlock
