/* eslint-disable jsx-a11y/no-static-element-interactions */
import moment from 'moment-ru'
import { Typography } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom'
import UCard from '../../../ui/UCard'
import useStyles from './style'

type IWCardCourse = {
    title: string
    favorited: boolean
    completed: boolean
    children: React.ReactNode
    progress?: number
    deadline?: string
    onChangeFavorite?(favorited: boolean): void
    to?: string
}

const WCardCourse: React.FC<IWCardCourse> = ({
    title,
    completed,
    children,
    deadline,
    progress = 0,
    favorited,
    onChangeFavorite,
    to,
}: IWCardCourse): React.ReactElement => {
    const classes = useStyles()
    const history = useHistory()

    const addToFavoriteHandler = (e: React.MouseEvent) => {
        e.stopPropagation()
        onChangeFavorite?.(favorited)
    }
    return (
        <div className={classes.root} onClick={() => to && history.push(to)}>
            <UCard.TypeTwo
                onChange={addToFavoriteHandler}
                value={favorited}
                title={title}
                subtitle={
                    deadline &&
                    `Пройти до ${moment(deadline).format('DD MMMM YYYY')}`
                }
                completed={completed}
                size={progress}
                fillProgressBar="#3ab2c9"
            >
                <Typography variant="subtitle1" className={classes.title}>
                    Уроки курсов
                </Typography>
                <div>{children}</div>
            </UCard.TypeTwo>
        </div>
    )
}
export default WCardCourse
