import React from 'react'
import LTitle from '@/components/ui/UTitle'
import LEventCalendar, {
    UEvent,
    UEventLine,
} from '@/components/ui/UEventCalendar'
import WEvent from '@/components/widget/WEvent'
import WLegendLabelList from '@/components/widget/WLegendLabelList'
import { useUser } from '@/services/user'
import useStyles from './style'

const CalendarLk = (): JSX.Element => {
    const classes = useStyles()
    const User = useUser()
    return (
        <>
            <LTitle.H2 variant="h2v3" smFont={24} className={classes.title}>
                Календарь событий
            </LTitle.H2>
            <div className={classes.wrap}>
                <LEventCalendar>
                    {User.calendar?.map((event) => {
                        const {
                            description,
                            title,
                            start,
                            end,
                            id,
                            speaker,
                        } = event
                        return (
                            <UEventLine
                                key={id}
                                avatar={
                                    speaker.image && (
                                        <img src={speaker.image.url} alt="" />
                                    )
                                }
                            >
                                <UEvent from={start.date} to={end.date}>
                                    {({ size }) => (
                                        <WEvent
                                            title={title}
                                            mini={size < 2}
                                            progress={event.progress}
                                            status={
                                                event.status ||
                                                event.specialization
                                                    ?.machineName
                                            }
                                        >
                                            {description}
                                        </WEvent>
                                    )}
                                </UEvent>
                            </UEventLine>
                        )
                    })}
                </LEventCalendar>
            </div>
            <div className={classes.legend}>
                <WLegendLabelList />
            </div>
        </>
    )
}
export default CalendarLk
