import { useAppDispatch } from '@/store'
import { CourseActions } from './course.store'

const useCourseManager = () => {
    const dispatch = useAppDispatch()
    const get = (id: string) => dispatch(CourseActions.get(id))
    return {
        get,
    }
}

export { useCourseManager }
