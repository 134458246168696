import React from 'react'
import { Link } from 'react-router-dom'

interface IArrowBack {
    color: string
    to: string
}
const LArrowBack: React.FC<IArrowBack> = ({
    color,
    to,
}: IArrowBack): React.ReactElement => (
    <Link
        style={{ maxWidth: '40px', maxHeight: '40px', display: 'block' }}
        to={to}
    >
        <svg
            width="100%"
            height="inherit"
            viewBox="0 0 40 40 "
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.08325 20.4574L32.0833 20.4574"
                stroke={color}
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.1663 30.4979L7.08293 20.4579L17.1663 10.4163"
                stroke={color}
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    </Link>
)
export default LArrowBack
