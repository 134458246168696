/* eslint-disable react/require-default-props */
import React, { ReactNode } from 'react'
import './index.scss'

interface Props {
    size?: number
    suptitle?: ReactNode
    subtitle?: ReactNode
    fill?: string
    outline?: string
    bg?: string
}
/**
 * Прогресс бар
 *
 * @param size - процент заполнения бара (от 1 до 100)
 * @param suptitle - Заголовок сверху
 * @param subtitle - текст снизу
 * @param view - альтернативный вид бара (доступно: 2, 3)
 * @param fill - альтернативный вид заполнителя (доступно: 2, 3, 4)
 */
const LProgressBar: React.FC<Props> = (props: Props) => {
    const {
        size = 0,
        suptitle = '',
        subtitle = '',
        fill = '#6bd689',
        outline = '#ebedef;',
        bg = '#FFF',
    } = props
    const width: number = Math.max(Math.min(size, 100), 0)

    return (
        <div className="LProgressBar">
            <span className="LProgressBar__text LProgressBar__top">
                {suptitle}
            </span>
            {!!size && (
                <div
                    className="LProgressBar__bar"
                    style={{ borderColor: outline, backgroundColor: bg }}
                >
                    <div
                        className="LProgressBar__fill"
                        style={{
                            width: `${width}%`,
                            backgroundColor: fill,
                        }}
                    />
                </div>
            )}
            {subtitle && (
                <>
                    <span className="LProgressBar__text LProgressBar__bottom">
                        {subtitle}
                    </span>
                </>
            )}
        </div>
    )
}

export default LProgressBar
