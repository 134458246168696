import UGrid from '@/components/ui/UGrid'
import React from 'react'
import Runner from '@/utils/Runner'
import { useCourse } from '@/services/course'
import useStyles from './style'

const SCourseContent = () => {
    const classes = useStyles()
    const Course = useCourse()
    return (
        <UGrid.UContainer>
            <div className={classes.section}>
                {Course.entity?.content && (
                    <Runner content={Course.entity.content} />
                )}
            </div>
        </UGrid.UContainer>
    )
}
export default SCourseContent
