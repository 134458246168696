import { useAppDispatch } from '@/store'
import { HomeworksForCheckingActions } from './homeworksForChecking.store'

const useHomeworksForCheckingManager = () => {
    const dispatch = useAppDispatch()
    const get = () => dispatch(HomeworksForCheckingActions.get())
    return {
        get,
    }
}

export { useHomeworksForCheckingManager }
