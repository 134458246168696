/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Box, makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import VariantsTitles from './title.style'

// Вариантов h1 - 1 {h1v1}
// Вариантов h2 - 3 {h2v1 h2v2 h2v3}
// Вариантов h3 - 3 {h3v1 h232 h3v3}
// lg - up 1024
// xl - up 1920
// md - up 768
// sm - up 0

enum Titles {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
}
enum Variants {
    h1v1,
    h2v1,
    h2v2,
    h2v3,
    h3v1,
    h3v2,
    h3v3,
}

const getVariantStyle = (variant: keyof typeof Variants) =>
    VariantsTitles[variant]

interface TStyleProps {
    variant?: keyof typeof Variants
    fontFamily?: string
    smFont?: number
    mdFont?: number
    lgFont?: number
    xlFont?: number
    smLineHeight?: number
    mdLineHeight?: number
    lgLineHeight?: number
    xlLineHeight?: number
    smLetterSpacing?: number
    mdLetterSpacing?: number
    lgLetterSpacing?: number
    xlLetterSpacing?: number
    smWeight?: 400 | 500 | 600
    mdWeight?: 400 | 500 | 600
    lgWeight?: 400 | 500 | 600
    xlWeight?: 400 | 500 | 600
}

interface MaterialStyle extends TStyleProps {
    variant: keyof typeof Variants
}
const useStyles = makeStyles((theme: Theme) => ({
    root: ({
        variant,
        smFont,
        mdFont,
        lgFont,
        xlFont,
        smWeight,
        mdWeight,
        lgWeight,
        xlWeight,
        fontFamily,
        smLineHeight,
        mdLineHeight,
        lgLineHeight,
        xlLineHeight,
        smLetterSpacing,
        mdLetterSpacing,
        lgLetterSpacing,
        xlLetterSpacing,
    }: MaterialStyle) => ({
        [theme.breakpoints.down(768)]: {
            fontFamily: fontFamily || getVariantStyle(variant).fontFamily,
            fontSize: smFont || getVariantStyle(variant).smFont,
            fontWeight: smWeight || getVariantStyle(variant).smWeight,
            letterSpacing:
                smLetterSpacing || getVariantStyle(variant).smLetterSpacing,
            lineHeight: smLineHeight || getVariantStyle(variant).smLineHeight,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: mdFont || getVariantStyle(variant).mdFont,
            fontWeight: mdWeight || getVariantStyle(variant).mdWeight,
            letterSpacing:
                mdLetterSpacing || getVariantStyle(variant).mdLetterSpacing,
            lineHeight: mdLineHeight || getVariantStyle(variant).mdLineHeight,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: lgFont || getVariantStyle(variant).lgFont,
            fontWeight: lgWeight || getVariantStyle(variant).lgWeight,
            letterSpacing:
                lgLetterSpacing || getVariantStyle(variant).lgLetterSpacing,
            lineHeight: lgLineHeight || getVariantStyle(variant).lgLineHeight,
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: xlFont || getVariantStyle(variant).xlFont,
            fontWeight: xlWeight || getVariantStyle(variant).xlWeight,
            letterSpacing:
                xlLetterSpacing || getVariantStyle(variant).xlLetterSpacing,
            lineHeight: xlLineHeight || getVariantStyle(variant).xlLineHeight,
        },
    }),
}))
interface ILTitleProps extends TStyleProps {
    children: React.ReactNode
    tag?: keyof typeof Titles
    className?: string
}
interface H1Props extends ILTitleProps {
    variant?: 'h1v1'
}
interface H2Props extends ILTitleProps {
    variant?: 'h2v1' | 'h2v2' | 'h2v3'
}
interface H3Props extends ILTitleProps {
    variant?: 'h3v1' | 'h3v2' | 'h3v3'
}
interface ILTitle<T> extends React.FC<T> {
    H1: React.FC<H1Props>
    H2: React.FC<H2Props>
    H3: React.FC<H3Props>
}
const LTitle: ILTitle<ILTitleProps> = ({
    children,
    variant = 'h1v1',
    smFont,
    mdFont,
    lgFont,
    xlFont,
    smWeight,
    mdWeight,
    lgWeight,
    xlWeight,
    fontFamily,
    tag = 'h1',
    className = '',
    smLineHeight,
    mdLineHeight,
    lgLineHeight,
    xlLineHeight,
    smLetterSpacing,
    mdLetterSpacing,
    lgLetterSpacing,
    xlLetterSpacing,
}: ILTitleProps): React.ReactElement => {
    const classes = useStyles({
        variant,
        smFont,
        mdFont,
        lgFont,
        xlFont,
        smWeight,
        mdWeight,
        lgWeight,
        xlWeight,
        fontFamily,
        smLineHeight,
        mdLineHeight,
        lgLineHeight,
        xlLineHeight,
        smLetterSpacing,
        mdLetterSpacing,
        lgLetterSpacing,
        xlLetterSpacing,
    })
    return (
        <>
            <Box component={tag} className={clsx(classes.root, className)}>
                {children}
            </Box>
        </>
    )
}
LTitle.H1 = ({ children, variant = 'h1v1', tag = 'h1', ...rest }: H1Props) => {
    const classes = useStyles({ variant, ...rest })
    return (
        <>
            <Box component={tag} className={clsx(classes.root, rest.className)}>
                {children}
            </Box>
        </>
    )
}
LTitle.H2 = ({ children, variant = 'h2v1', tag = 'h2', ...rest }: H2Props) => {
    const classes = useStyles({ variant, ...rest })
    return (
        <>
            <Box component={tag} className={clsx(classes.root, rest.className)}>
                {children}
            </Box>
        </>
    )
}
LTitle.H3 = ({ children, variant = 'h3v1', tag = 'h3', ...rest }: H3Props) => {
    const classes = useStyles({ variant, ...rest })
    return (
        <>
            <Box component={tag} className={clsx(classes.root, rest.className)}>
                {children}
            </Box>
        </>
    )
}

export default LTitle
