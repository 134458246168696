import { useAppDispatch } from '@/store'
import { LearningProgramsActions } from './learningPrograms.store'

const useLearningProgramsManager = () => {
    const dispatch = useAppDispatch()
    const get = () => dispatch(LearningProgramsActions.get())
    return {
        get,
    }
}

export { useLearningProgramsManager }
