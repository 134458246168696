import { useAppDispatch } from '@/store'
import { TCollectionEntity } from '../collection/collection.api'
import { CollectionsActions } from './collections.store'

const useCollectionsManager = () => {
    const dispatch = useAppDispatch()

    const get = () => dispatch(CollectionsActions.get())

    const addToFavorite = (
        ...args: Parameters<typeof CollectionsActions.addToFavorite>
    ) => dispatch(CollectionsActions.addToFavorite(...args))

    const removeFromFavorite = (
        ...args: Parameters<typeof CollectionsActions.removeFromFavorite>
    ) => dispatch(CollectionsActions.removeFromFavorite(...args))

    const toggleFavorite = (collections: TCollectionEntity) => (
        favorited: boolean
    ) => {
        if (favorited) removeFromFavorite(collections.id)
        else addToFavorite(collections)
    }

    return {
        get,
        addToFavorite,
        removeFromFavorite,
        toggleFavorite,
    } as const
}

export { useCollectionsManager }
