import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import SHeader from '@/sections/SHeader'
import SSpeakers from '@/sections/SSpeakers'
import SFooter from '@/sections/SFooter'
import SLPInfoProgram from '@/sections/SLPInfoProgram'
import SLPContent from '@/sections/SLPContent'
import { useSpeakersManager } from '@/services/speakers'

import { useLearningProgramManager } from '@/services/learningProgram'
import useStyles from './style'

const LearningProgramPage = (): JSX.Element => {
    const classes = useStyles()
    const params = useParams<{ id: string }>()
    const learningProgramManager = useLearningProgramManager()
    const speakersManager = useSpeakersManager()

    useEffect(() => {
        speakersManager.get()
        learningProgramManager.get(params.id)
    }, [])
    return (
        <div className={classes.root}>
            <SHeader adaptiveMenuView="primary" isGoBackForAdaptive />
            <SLPInfoProgram />
            <SLPContent />
            <hr className={classes.hr} />
            <SSpeakers isModule />
            <SFooter />
        </div>
    )
}
export default LearningProgramPage
