import React from 'react'
import { withStyles, Tabs, Tab, Theme } from '@material-ui/core'

interface UTabPanelProps {
    children?: React.ReactNode
    index: number | string
    value: number | string
}

const UTabPanel: React.FC<UTabPanelProps> = (props: UTabPanelProps) => {
    const { children, value, index, ...rest } = props

    return (
        <div hidden={value !== index} id={`a11y-tabpanel-${index}`} {...rest}>
            {value === index && children}
        </div>
    )
}

const UTabs = withStyles((theme: Theme) => ({
    root: {
        minHeight: 0,
        [theme.breakpoints.up('md')]: {
            width: '100%',
        },
    },
    indicator: {
        borderBottom: '3px solid #7FC9DE',
    },
}))(Tabs)

const UTab = withStyles((theme: Theme) => ({
    root: {
        minWidth: 30,
        minHeight: 0,
        textTransform: 'none',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 15,
        lineHeight: '140%',
        letterSpacing: 0.1,
        color: '#B8B8C3',
        padding: 0,
        paddingBottom: 8,
        '& + &': {
            marginLeft: 18,
        },

        [theme.breakpoints.up('md')]: {
            paddingBottom: 16,
            fontSize: 24,
            fontWeight: 500,
            lineHeight: '120%',
            letterSpacing: -0.2,
            '& + &': {
                marginLeft: 40,
            },
        },
    },
    selected: {
        color: '#000000',
    },
}))(Tab)

export default UTabs
export { UTab, UTabPanel }
