import React from 'react'
import { Typography } from '@material-ui/core'
import useStyles from './style'
import UStatus from '../UStatus'
import LTitle from '../UTitle'

const statusMap = {
    common: {
        text: 'Оповещение',
        color: '#4FC2D8',
    },
    newCourse: {
        text: 'Новый курс!',
        color: '#4FC2D8',
    },
    reminder: {
        text: 'Напоминание',
        color: '#4FC2D8',
    },
    taskAccepted: {
        text: 'Принято ДЗ',
        color: '#39CF63',
    },
    taskNotAccepted: {
        text: 'ДЗ не принято',
        color: '#F5075A',
    },
    deadline: {
        text: 'Истекает дэдлайн!',
        color: '#F5075A',
    },
    certificate: {
        text: 'У вас новый сертификат!',
        color: '#39CF63',
    },
} as const

// TODO: Регулировать статус от входящих пропсов
// TODO Это виджет
interface IUNotification {
    title: string
    description: string
    status: keyof typeof statusMap
    date: string
    children?: React.ReactNode
}
const UNotification: React.FC<IUNotification> = ({
    title,
    description,
    status,
    date,
    children,
}: IUNotification): React.ReactElement => {
    const isChildren = !!children
    const classes = useStyles({ isChildren })
    return (
        <div className={classes.root}>
            <div>
                <div className={classes.statusBlock}>
                    <UStatus
                        className={classes.status}
                        background={statusMap[status].color}
                    >
                        {statusMap[status].text}
                    </UStatus>
                    <Typography variant="subtitle1" className={classes.date}>
                        {date}
                    </Typography>
                </div>
                <LTitle.H3 className={classes.title}>{title}</LTitle.H3>
                <Typography variant="body2" className={classes.description}>
                    {description}
                </Typography>
            </div>
            {isChildren && (
                <div className={classes.childrenBlock}>{children}</div>
            )}
        </div>
    )
}
export default UNotification
