import { useAppDispatch } from '@/store'
import { TCourseEntity } from '../course/course.api'
import { CoursesActions } from './courses.store'

const useCoursesManager = () => {
    const dispatch = useAppDispatch()

    const get = () => dispatch(CoursesActions.get())

    const addToFavorite = (
        ...args: Parameters<typeof CoursesActions.addToFavorite>
    ) => dispatch(CoursesActions.addToFavorite(...args))

    const removeFromFavorite = (
        ...args: Parameters<typeof CoursesActions.removeFromFavorite>
    ) => dispatch(CoursesActions.removeFromFavorite(...args))

    const toggleFavorite = (course: TCourseEntity) => (favorited: boolean) => {
        if (favorited) removeFromFavorite(course.id)
        else addToFavorite(course)
    }

    return {
        get,
        addToFavorite,
        removeFromFavorite,
        toggleFavorite,
    }
}

export { useCoursesManager }
