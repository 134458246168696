/* eslint-disable @typescript-eslint/unbound-method */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createAwaitAdapter, createListAdapter } from '@/store/common'
import { CalendarEventsApi, TCalendarEventEntity } from './calendarEvents.api'

const NAME = 'CalendarEvents'

const get = createAsyncThunk(`${NAME}/get`, async () => {
    const CalendarEvents = await CalendarEventsApi.get()
    const list = CalendarEvents.data

    return {
        list,
    }
})

const CalendarEventsAwaitAdapter = createAwaitAdapter(NAME)
const CalendarEventsAdapter = createListAdapter<TCalendarEventEntity>()
const initialState = CalendarEventsAdapter.getInitialState(
    CalendarEventsAwaitAdapter.getAwaitState()
)

const calendarEventsStore = createSlice({
    name: NAME,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(get.fulfilled, CalendarEventsAdapter.set)
            .addMatcher(
                CalendarEventsAwaitAdapter.isFulfilled,
                CalendarEventsAwaitAdapter.fulfilled
            )
            .addMatcher(
                CalendarEventsAwaitAdapter.isPending,
                CalendarEventsAwaitAdapter.pending
            )
            .addMatcher(
                CalendarEventsAwaitAdapter.isRejected,
                CalendarEventsAwaitAdapter.rejected()
            )
    },
})

export const CalendarEventsActions = {
    get,
}
export default calendarEventsStore.reducer
