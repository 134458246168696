import React from 'react'
import clsx from 'clsx'

import useInput from '../../../hooks/useInput'
import useStyles from './style'

type DefaultProps = {
    className: string
    light: boolean
    isButtonInput: boolean
    fullWidth: boolean
}
type DefaultPropsType = Readonly<DefaultProps>

type InputProps = {
    placeholder: string
} & Partial<DefaultPropsType>

const Input: React.FC<InputProps> = ({
    placeholder,
    className = '',
    light = false,
    isButtonInput = false,
    fullWidth = false,
}: InputProps): React.ReactElement => {
    const classes = useStyles({ fullWidth })
    const search = useInput('')

    return (
        <>
            {!isButtonInput && (
                <input
                    className={clsx({
                        [classes.root]: true,
                        [classes.common]: !light,
                        [className]: true,
                        [classes.light]: light,
                    })}
                    type="input"
                    placeholder={placeholder}
                    {...search.bind}
                />
            )}

            {isButtonInput && (
                <button className={classes.inputbtn} type="button">
                    <svg
                        width="27"
                        height="27"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6.08332 1.83341C3.73611 1.83341 1.83332 3.7362 1.83332 6.08342C1.83332 8.43062 3.73611 10.3334 6.08332 10.3334C8.43053 10.3334 10.3333 8.43062 10.3333 6.08342C10.3333 3.7362 8.43053 1.83341 6.08332 1.83341ZM0.416656 6.08342C0.416656 2.9538 2.95371 0.416748 6.08332 0.416748C9.21294 0.416748 11.75 2.9538 11.75 6.08342C11.75 9.21303 9.21294 11.7501 6.08332 11.7501C2.95371 11.7501 0.416656 9.21303 0.416656 6.08342Z"
                            fill="#3AB2C9"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9.12412 9.12422C9.40074 8.84759 9.84924 8.84759 10.1259 9.12422L14.3759 13.3742C14.6525 13.6508 14.6525 14.0993 14.3759 14.3759C14.0992 14.6526 13.6507 14.6526 13.3741 14.3759L9.12412 10.1259C8.8475 9.84933 8.8475 9.40084 9.12412 9.12422Z"
                            fill="#3AB2C9"
                        />
                    </svg>
                </button>
            )}
        </>
    )
}
export default Input
