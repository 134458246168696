import BaseApi from '@/api/BaseApi'
import { AxiosPromise } from 'axios'
import type { TModuleEntity } from '@/services/module'

const ModulesApi = {
    get(): AxiosPromise<TModuleEntity[]> {
        return BaseApi.get('/modules')
    },
}

export { ModulesApi }
