import { useAppDispatch } from '@/store'
import { CertificatesActions } from './certificates.store'

const useCertificatesManager = () => {
    const dispatch = useAppDispatch()
    const get = () => dispatch(CertificatesActions.get())
    return {
        get,
    }
}

export { useCertificatesManager }
