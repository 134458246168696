import React, { useState } from 'react'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
    Crosshair,
    Hint,
    HorizontalGridLines,
    LineMarkSeries,
    VerticalBarSeries,
    VerticalBarSeriesPoint,
    XAxis,
    XYPlot,
    YAxis,
} from 'react-vis'

import 'react-vis/dist/style.css'
import './style.scss'
import clsx from 'clsx'
import useStyles from './style'

// import useStyles from './style';

type DataProps = {
    x: number
    y: number
    id: number
}

interface ChartProps {
    height: number
    width: number
    data: Array<DataProps>
    mainColor: string
    gridColor: string
    spaceBetweenColumns: number
    showLineSeries?: boolean
    center?: boolean
}
/**
 * График
 * @param height - Высота видимой области графика
 * @param width - Ширина видимой области графика
 * @param data - Преобразованный массив, в котором вместо time будет y, также x = id - 1
 * @param mainColor - Цвет столбцов
 * @param gridColor - Цвет горизонтальных линий
 * @param spaceBetweenColumns - Расстояние между стобцами (от 0 до 1)
 * @param showLineSeries - объединяет вершины столбцов линией для наглядности представления
 * @param center - располагает график по центру блока
 */
// EXAMPLE DATA:
// const data = [
//     { x: 0, y: 4, id: 1 },
//     { x: 1, y: 8, id: 2 },
//     { x: 2, y: 0.5, id: 3 },
//     { x: 3, y: 0.5, id: 4 },
//     { x: 4, y: 6.7, id: 5 },
//     { x: 5, y: 0.3, id: 6 },
//     { x: 6, y: 0, id: 7 },
// ]
const Chart: React.FC<ChartProps> = ({
    height,
    width,
    data,
    mainColor,
    gridColor,
    spaceBetweenColumns,
    showLineSeries,
    center,
}: ChartProps): React.ReactElement => {
    const classes = useStyles()
    const [
        hoveredNode,
        setHoveredNode,
    ] = useState<null | VerticalBarSeriesPoint>(null)
    const labelsX = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']
    return (
        <XYPlot
            height={height}
            width={width}
            onMouseLeave={() => {
                setHoveredNode(null)
            }}
            className={clsx({
                // ma - margin: auto
                ma: center,
            })}
        >
            <HorizontalGridLines style={{ stroke: gridColor }} />
            <VerticalBarSeries
                data={data}
                barWidth={spaceBetweenColumns}
                color={mainColor}
                onValueMouseOut={() => {
                    setHoveredNode(null)
                }}
                onNearestX={(d: VerticalBarSeriesPoint) => {
                    setHoveredNode(d)
                }}
            />
            <XAxis tickFormat={(x: number) => labelsX[x]} tickSize={0} />
            <YAxis tickFormat={(y: number) => `${y}ч`} tickSize={0} />
            {hoveredNode && (
                <Hint value={hoveredNode}>
                    <div className={classes.hint}>
                        <h3>{hoveredNode.y}ч</h3>
                    </div>
                </Hint>
            )}
            {showLineSeries && <LineMarkSeries data={data} />}
            <Crosshair />
        </XYPlot>
    )
}
export default Chart
