import { useAppDispatch } from '@/store'
import { CalendarEventsActions } from './calendarEvents.store'

const useCalendarEventsManager = () => {
    const dispatch = useAppDispatch()
    const get = () => dispatch(CalendarEventsActions.get())
    return {
        get,
    }
}

export { useCalendarEventsManager }
