import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    linkRoot: {
        display: 'flex',
        alignItems: 'center',
    },
    link: {
        display: 'inline-block',
        color: '#B8B8C3',
        fontWeight: 400,
        fontSize: 20,
        lineHeight: '160%',
    },
    dotBlock: {
        display: 'flex',
        justifyContent: 'center',
        padding: '0 16px ',
    },
    dot: {
        display: 'inline-block',
        width: 4,
        height: 4,
        backgroundColor: '#B8B8C3',
        borderRadius: '50%',
    },
    active: {},
}))
export default useStyles
