import BaseApi from '@/api/BaseApi'
import { AxiosPromise } from 'axios'
import type { TNotificationEntity } from '@/services/notifications'
import type { TFavoriteEntity } from '@/services/favorites'
import type { TCalendarEventEntity } from '@/services/calendarEvents'
import type { TDepartament, TImage } from '@/api/BaseApi'

export type TUserEntity = {
    // User UUID
    id: string

    // Имя
    name: string

    // E-mail пользователя
    email: string
    notifications?: TNotificationEntity[]
    calendar?: TCalendarEventEntity[]
    favorites?: TFavoriteEntity[]
    departament: TDepartament
    image?: TImage
}

const UserApi = {
    get(): AxiosPromise<TUserEntity> {
        return BaseApi.get('/user')
    },
}

export { UserApi }
