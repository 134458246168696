import { useAppDispatch } from '@/store'
import { ModuleActions } from './module.store'

const useModuleManager = () => {
    const dispatch = useAppDispatch()
    const get = (id: string) => dispatch(ModuleActions.get(id))
    const clear = () => dispatch(ModuleActions.clear())
    return {
        get,
        clear,
    }
}

export { useModuleManager }
