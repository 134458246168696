import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: '#fff ',
        padding: 56,
        [theme.breakpoints.down(768)]: {
            padding: 12,
        },
    },
    avatar: {
        width: 80,
        height: 80,
        marginRight: 32,
        [theme.breakpoints.down(768)]: {
            width: 52,
            height: 52,
        },
    },
    head: {
        display: 'flex',
        marginRight: 32,
        marginBottom: 32,
    },
    headTitle: {
        [theme.breakpoints.down(768)]: {
            fontSize: 20,
        },
    },
    text: {
        marginBottom: 32,
        maxWidth: 813,
        fontSize: 15,
        [theme.breakpoints.down(768)]: {
            maxWidth: '100%',
        },
    },
    content: {
        paddingLeft: 112,
        [theme.breakpoints.down(768)]: {
            paddingLeft: 0,
        },
    },
    btnBlock: {},
    btn: {
        marginLeft: 16,
        '&:first-child': {
            marginLeft: 0,
        },
        [theme.breakpoints.down(768)]: {
            marginLeft: 0,
            '&:first-child': {
                marginBottom: 16,
            },
        },
    },
    download: {
        display: 'block',
        marginTop: 8,
    },
}))
export default useStyles
