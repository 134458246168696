import { makeStyles, Theme } from '@material-ui/core/styles'
import bg from '../../../../assets/images/main-bg.jpg'

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        [theme.breakpoints.down('lg')]: {
            backgroundImage: `url(${bg})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
        },
    },

    overlay: {
        [theme.breakpoints.down('lg')]: {
            position: 'absolute',
            zIndex: 1,
            width: '100%',
            height: '100%',
            background:
                'linear-gradient(180deg, rgba(15, 14, 14, 0.74) 0%, rgba(7, 7, 7, 0.44375) 45.83%, rgba(4, 4, 4, 0.31) 67.71%, rgba(0, 0, 0, 0.1) 100%)',
        },
    },

    headerBlock: {
        position: 'relative',
        zIndex: 2,
    },

    mainBlock: {
        marginTop: '81px',
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            justifyContent: 'center',
            flexDirection: 'column',
            marginTop: 78,
        },
        [theme.breakpoints.down('md')]: {
            marginTop: 207,
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            flexDirection: 'column',
            marginTop: 109,
        },
    },
    titleBlock: {
        maxWidth: '706px ',
        marginRight: '0px',
        marginBottom: '48px',
        [theme.breakpoints.down('lg')]: {
            textAlign: 'center',
            color: '#fff',
            marginBottom: '48px',
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '40px',
            maxWidth: '315px',
            margin: 'auto',
        },
    },

    buttonsBlock: {
        display: 'flex',
        marginRight: '55px',
        marginBottom: '96px',
        [theme.breakpoints.down('lg')]: {
            justifyContent: 'center',
            marginRight: '0px',
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            marginBottom: '120px',
            alignItems: 'center',
        },
    },

    contentMainBlock: {
        [theme.breakpoints.down('lg')]: {
            margin: 'auto',
        },
        [theme.breakpoints.down('sm')]: {
            margin: 0,
        },
    },

    playerBlock: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up('xl')]: {
            position: 'relative',
        },
    },
    playBtn: {
        [theme.breakpoints.up('xl')]: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
    },
    breakpointsBtn: {
        display: 'block',
        fontWeight: 400,
        marginRight: '24px',
        border: `2px solid ${theme.palette.primary.main}`,

        [theme.breakpoints.down('sm')]: {
            marginRight: 'auto',
            maxWidth: 324,
            margin: 'auto',
            fontWeight: 500,
            width: '100% !important ',
            fontSize: '15px !important',
            marginBottom: '24px',
        },
    },
    navLink: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    breakpointsBtnOutlined: {
        [theme.breakpoints.down('lg')]: {
            color: '#fff',
            backgroundColor: 'transparent !important ',
            border: `2px solid ${theme.palette.primary.main}`,
        },
    },
    video: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: '100%',
        width: '100%',
        objectPosition: 'center',
        objectFit: 'cover',
        zIndex: -1,
        [theme.breakpoints.up('xl')]: {
            position: 'static',
        },
    },
}))
export default useStyles
