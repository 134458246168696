import BaseApi from '@/api/BaseApi'
import { AxiosPromise } from 'axios'
import { TContent } from '../../utils/Runner/index'
import { Th5pData, TLink } from '../../api/BaseApi/types'

export type TMaterialEntity = {
    id: string
    h5pData?: Th5pData[]
    content?: TContent
    // Видео/аудио примеры
    examples?: TLink[]
    // Рекомендации
    recomendations?: TLink[]
    title: string
}

const MaterialApi = {
    get(id: string): AxiosPromise<TMaterialEntity> {
        return BaseApi.get(`/materials/${id}`)
    },
}

export { MaterialApi }
