import BaseApi, { TSpecializationName, TUserShort } from '@/api/BaseApi'
import { AxiosPromise } from 'axios'
import type {
    TDateWithDateTime,
    TActionLink,
    TSpecialization,
} from '@/api/BaseApi'

export type TCalendarEventsRequest = {
    dateStart?: string
    dateEnd?: string
    specializationName?: TSpecializationName
}
export type TCalendarEventStatus = 'completed' | 'missed'
export type TCalendarEventEntity = {
    // uuid события
    id: string
    status?: TCalendarEventStatus
    created: string
    updated: string
    description?: string
    location?: string
    start: TDateWithDateTime
    end: TDateWithDateTime
    eventType?: string
    title: string
    action?: TActionLink
    specialization?: TSpecialization
    speaker: TUserShort
    progress?: number
}

const CalendarEventsApi = {
    get(params?: TCalendarEventsRequest): AxiosPromise<TCalendarEventEntity[]> {
        return BaseApi.get('/calendar', { params })
    },
}

export { CalendarEventsApi }
