import { makeStyles } from '@material-ui/core'
import React from 'react'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
    root: ({ color, background }: { color: string; background: string }) => ({
        display: 'inline-block',
        padding: '6px 10px',
        backgroundColor: background,
        borderRadius: 4,
        lineHeight: '140% ',
        fontSize: 15,
        letterSpacing: 0.1,
        color,
        fontWeight: 400,
    }),
}))

interface IUStatusProps {
    children?: React.ReactNode
    color?: string
    background?: string
    className?: string
}

/**
 *
 * @param children Текст статуса
 * @param  color Тип статуса (0,1,2)
 */
const UStatus: React.FC<IUStatusProps> = (props: IUStatusProps) => {
    const {
        children,
        background = '#3AB2C9',
        color = '#FFF',
        className,
    } = props
    const classes = useStyles({ color, background })
    return <div className={clsx(classes.root, className)}>{children}</div>
}

export default UStatus
