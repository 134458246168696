import React, { useEffect } from 'react'

import Header from '@/sections/SHeader'
import SCourses from '@/sections/SCourses'
import UFooter from '@/sections/SFooter'
import { useModulesManager } from '@/services/modules'

const MainPage = (): JSX.Element => {
    const ModulesManager = useModulesManager()
    useEffect(() => {
        ModulesManager.get()
    }, [])
    const coursesSettings = {
        type: 'all',
        title: 'Все курсы',
        initialSelectedTab: 'primary',
        tabs: [
            { id: '0', label: 'Профильные', value: 'primary' },
            { id: '1', label: 'Надпрофильные', value: 'secondary' },
            { id: '2', label: 'Общие', value: 'common' },
        ],
        buttons: [
            { id: '0', label: 'Технологии продаж', checked: true },
            { id: '1', label: 'Строительство', checked: false },
            { id: '2', label: 'Архитектура', checked: true },
            { id: '3', label: 'Психология', checked: false },
        ],
    }
    return (
        <>
            <Header />
            <SCourses settings={coursesSettings} />
            <UFooter />
        </>
    )
}

export default MainPage
