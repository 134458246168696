import BaseApi from '@/api/BaseApi'
import { AxiosPromise } from 'axios'
import type { TLessonEntity } from '@/services/lesson'
import { TContent } from '../../utils/Runner/index'
import { TMaterialEntity } from '../material/material.api'
import { TCourseEntity } from '../course'

export type TCollectionEntity = {
    description?: string
    lessons?: TLessonEntity[]
    courses?: TCourseEntity[]
    id: string
    title: string
    favorited: boolean
    materials?: TMaterialEntity[]
    content?: TContent
}

const CollectionApi = {
    get(id: string): AxiosPromise<TCollectionEntity> {
        return BaseApi.get(`/collections/${id}`)
    },
}

export { CollectionApi }
