/* eslint-disable @typescript-eslint/unbound-method */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createAwaitAdapter, TAwaitState } from '@/store/common'
import { LessonApi, TLessonEntity } from './lesson.api'

const NAME = 'lesson'

const get = createAsyncThunk(`${NAME}/get`, async (id: string) => {
    const lesson = await LessonApi.get(id)
    const { data } = lesson

    return data
})

const LessonAwaitAdapter = createAwaitAdapter(NAME)

export interface ILessonInitialState extends TAwaitState {
    entity: TLessonEntity | null
}
const initialState: ILessonInitialState = {
    entity: null,
    ...LessonAwaitAdapter.getAwaitState(),
}
const lessonStore = createSlice({
    name: NAME,
    initialState,
    reducers: {
        clear(state) {
            state.entity = null
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(get.fulfilled, (state, { payload }) => {
                state.entity = payload
            })
            .addMatcher(
                LessonAwaitAdapter.isFulfilled,
                LessonAwaitAdapter.fulfilled
            )
            .addMatcher(
                LessonAwaitAdapter.isPending,
                LessonAwaitAdapter.pending
            )
            .addMatcher(
                LessonAwaitAdapter.isRejected,
                LessonAwaitAdapter.rejected()
            )
    },
})

export const LessonActions = {
    ...lessonStore.actions,
    get,
}
export default lessonStore.reducer
