import { makeStyles } from '@material-ui/core/styles'
import theme from '../../../theme'

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        marginTop: 83,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
        },
    },
    title: {
        [theme.breakpoints.up('lg')]: {
            marginBottom: 40,
            fontWeight: 500,
            fontSize: 24,
            lineHeight: '120%',
            letterSpacing: -0.2,
        },
    },
    leftSide: {
        width: 320,
        [theme.breakpoints.down('lg')]: {
            width: '100%',
            marginBottom: 48,
        },
    },
    rightSide: {
        paddingLeft: 47,
        borderLeft: '1px solid #EBEDEF ',
        flexGrow: 1,
        maxWidth: 'calc(100% - 320px)',
        [theme.breakpoints.down('lg')]: {
            border: 'none ',
            paddingLeft: 0,
            maxWidth: '100%',
        },
    },
    hiddenIfDown1920: {
        [theme.breakpoints.down('lg')]: {
            display: 'none ',
        },
    },
    showIfDown1920: {
        display: 'none ',
        [theme.breakpoints.down('lg')]: {
            display: 'block ',
        },
    },
    panelBar: {
        position: 'fixed',
        zIndex: 10,
        width: '100%',
        bottom: 0,
    },
}))
export default useStyles
