import React from 'react'
import { Box } from '@material-ui/core'

import UGrid from '@/components/ui/UGrid'
import WInfoProgram from '@/components/widget/WInfoProgram'
import { useLearningProgram } from '@/services/learningProgram'
import moment from 'moment-ru'
import useStyles from './style'

const SLPInfoProgram = (): JSX.Element => {
    const classes = useStyles()
    const LearningProgram = useLearningProgram()
    return (
        <Box className={classes.section}>
            <UGrid.UContainer>
                <WInfoProgram
                    title={LearningProgram.entity?.title || ''}
                    deadline={`Пройти до ${moment(
                        LearningProgram.entity?.dueDate.date
                    ).format('DD MMMM YYYY')}`}
                    progress={LearningProgram.entity?.progress || 0}
                    typeProgram={LearningProgram.entity?.specialization.title}
                >
                    <>{LearningProgram.entity?.description}</>
                </WInfoProgram>
            </UGrid.UContainer>
        </Box>
    )
}

export default SLPInfoProgram
