import { makeStyles, Theme } from '@material-ui/core/styles'
import manOnRocket from './svg/manOnRocket.svg'
import manOnRocket1024 from './svg/manOnRocket1024.svg'
import manOnRocket768 from './svg/manOnRocket768.svg'
import { ProgressView } from './type'

interface Props {
    progressView: keyof typeof ProgressView
}
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: '#00A8D7',
        backdropFilter: 'blur(8px)',
        padding: '48px 40px',
        backgroundImage: `url('${manOnRocket}')`,
        backgroundPosition: 'left 560px top 20px',
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.down('md')]: {
            padding: 0,
            marginLeft: -24,
            marginRight: -24,
            paddingRight: 24,
            paddingTop: 45,
            paddingLeft: 24,
            paddingBottom: 46,
            backgroundImage: 'none',
        },
        position: 'relative',
        zIndex: 2,
    },
    overlay: {
        [theme.breakpoints.down('md')]: {
            position: 'absolute ',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            // opacity: '0.3',
            backgroundImage: `url('${manOnRocket1024}')`,
            backgroundPosition: 'right bottom -120px',
            backgroundRepeat: 'no-repeat',
            zIndex: 1,
        },
        [theme.breakpoints.down('sm')]: {
            position: 'absolute ',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            // opacity: '0.3',
            backgroundImage: `url('${manOnRocket768}')`,
            backgroundPosition: 'right bottom',
            backgroundRepeat: 'no-repeat',
            zIndex: 1,
        },
    },
    headBlock: {
        display: 'flex ',
    },
    title: {
        color: theme.palette.primary.contrastText,
        marginLeft: 24,
        marginBottom: 16,
        zIndex: 2,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
        },
    },
    contentText: {
        color: theme.palette.primary.contrastText,
        maxWidth: 512,
        marginBottom: 16,
        position: 'relative',
        zIndex: 2,
    },
    progressBlock: {
        maxWidth: 490,
        position: 'relative',
        zIndex: 2,
    },
    progressBlockText: {
        marginTop: 16,
        color: theme.palette.primary.contrastText,
        zIndex: 2,
    },
    arrowBlock: {
        zIndex: 2,
        [theme.breakpoints.down('md')]: {
            display: 'none ',
        },
    },
    status: {
        display: ({ progressView }: Props) => {
            if (progressView === ProgressView.asStatus) {
                return 'inline-block'
            }
            return 'none'
        },
        fontWeight: 500,
        maxWidth: 154,
    },
    progress: {
        display: ({ progressView }: Props) => {
            if (progressView === ProgressView.asProgress) {
                return 'block'
            }
            return 'none'
        },
    },
    deadline: {
        fontWeight: 400,
        fontSize: 15,
        lineHeight: '140%',
        color: '#fff',
    },
}))
export default useStyles
