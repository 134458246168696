import { makeStyles, Theme } from '@material-ui/core/styles'

// interface Props {
// isChildren: boolean
// }
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: 32,
        background: '#F6F7F9',
        borderRadius: 4,
        alignItems: 'center',
        position: 'relative',
        border: '1px solid transparent',
        [theme.breakpoints.down(768)]: {
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
        },
        '&:hover': {
            border: '1px solid #EBEDEF',
            '& $childrenBlock': {
                cursor: 'pointer',
                opacity: 1,
            },
        },
    },
    statusBlock: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 16,
    },
    date: {
        color: theme.palette.text.secondary,
        [theme.breakpoints.down(768)]: {
            fontSize: 10,
            color: theme.palette.text.hint,
        },
    },
    status: {
        marginRight: 16,
    },
    title: {
        marginBottom: 16,
    },
    description: {
        fontSize: 15,
        [theme.breakpoints.down(768)]: {
            fontSize: 13,
            marginBottom: 16,
        },
    },
    childrenBlock: {
        opacity: 0,
        transition: 'opacity .3s',
    },
}))
export default useStyles
