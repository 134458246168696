import React from 'react'
import LButton from '../../components/ui/UButton'
import useStyles from './style'
import LoginAnd404Layout from '../layout/LoginAnd404Layout'
import { ReactComponent as Logo } from '../../images/logo.svg'

interface INotFound {
    variant: 'cloud' | 'girl'
}
const Login: React.FC<INotFound> = ({ variant }: INotFound): JSX.Element => {
    const classes = useStyles()
    return (
        <LoginAnd404Layout variant={variant}>
            <div className={classes.root}>
                <Logo />
                <LButton size="large" view="filled" className={classes.btn}>
                    Войти
                </LButton>
            </div>
        </LoginAnd404Layout>
    )
}

export default Login
