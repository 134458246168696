/* eslint-disable @typescript-eslint/no-unused-vars */
import { Container, Theme, withStyles } from '@material-ui/core'
import React from 'react'

const UContainer = withStyles((theme: Theme) => ({
    // maxWidthXs: {
    //     maxWidth: '100% !important',
    //     width: '100%',
    // },
    // maxWidthMd: {
    //     [theme.breakpoints.up('md')]: {
    //         maxWidth: `${710 + theme.spacing(6)}px !important`,
    //         width: '100%',
    //     },
    // },
    // maxWidthLg: {
    //     [theme.breakpoints.up('lg')]: {
    //         maxWidth: `${710 + theme.spacing(6)}px !important`,
    //         width: '100%',
    //     },
    // },
    maxWidthXl: {
        maxWidth: `${1460 + theme.spacing(6)}px !important`,
        [theme.breakpoints.up('xl')]: {
            maxWidth: `${1460 + theme.spacing(6)}px !important`,
            width: '100%',
        },
    },
}))(({ children, ...rest }: React.ComponentProps<typeof Container>) => (
    <Container maxWidth="xl" {...rest}>
        {children}
    </Container>
))

export default UContainer
