import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex ',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: 0,
        },
    },
    title: {
        marginBottom: 40,
    },
}))
export default useStyles
