/* eslint-disable @typescript-eslint/unbound-method */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createAwaitAdapter, createListAdapter } from '@/store/common'
import { HomeworksForCheckingApi } from './homeworksForChecking.api'
import { THomeworksForCheckingEntity } from './homeworksForChecking.entity'

const NAME = 'homeworksforchecking'

const get = createAsyncThunk(`${NAME}/get`, async () => {
    const homeworksForChecking = await HomeworksForCheckingApi.get()
    const list = homeworksForChecking.data

    return {
        list,
    }
})

const HomeworksForCheckingAwaitAdapter = createAwaitAdapter(NAME)
const HomeworksForCheckingAdapter = createListAdapter<THomeworksForCheckingEntity>()

const initialState = HomeworksForCheckingAdapter.getInitialState(
    HomeworksForCheckingAwaitAdapter.getAwaitState()
)

const homeworksForCheckingStore = createSlice({
    name: NAME,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(get.fulfilled, HomeworksForCheckingAdapter.set)
            .addMatcher(
                HomeworksForCheckingAwaitAdapter.isFulfilled,
                HomeworksForCheckingAwaitAdapter.fulfilled
            )
            .addMatcher(
                HomeworksForCheckingAwaitAdapter.isPending,
                HomeworksForCheckingAwaitAdapter.pending
            )
            .addMatcher(
                HomeworksForCheckingAwaitAdapter.isRejected,
                HomeworksForCheckingAwaitAdapter.rejected()
            )
    },
})

export const HomeworksForCheckingActions = {
    get,
}

export default homeworksForCheckingStore.reducer
