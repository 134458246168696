/* eslint-disable @typescript-eslint/unbound-method */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createAwaitAdapter, TAwaitState } from '@/store/common'
import { TUserEntity, UserApi } from './user.api'

const NAME = 'user'

const get = createAsyncThunk(`${NAME}/get`, async () => {
    const user = await UserApi.get()
    const { data } = user

    return data
})

const UserAwaitAdapter = createAwaitAdapter(NAME)

export type TUserInitialState = TAwaitState & Partial<TUserEntity>

const initialState: TUserInitialState = {
    ...UserAwaitAdapter.getAwaitState(),
}

const userStore = createSlice({
    name: NAME,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(get.fulfilled, (state, { payload }) => ({
                ...state,
                ...payload,
            }))
            .addMatcher(
                UserAwaitAdapter.isFulfilled,
                UserAwaitAdapter.fulfilled
            )
            .addMatcher(UserAwaitAdapter.isPending, UserAwaitAdapter.pending)
            .addMatcher(
                UserAwaitAdapter.isRejected,
                UserAwaitAdapter.rejected()
            )
    },
})

export const UserActions = {
    get,
}
export default userStore.reducer
