import React from 'react'
import routes from '@/routes'
import LTitle from '@/components/ui/UTitle'
import { Box } from '@material-ui/core'
import WCardLesson from '@/components/widget/WCard/Lesson'
import UGrid from '@/components/ui/UGrid'
import { useModule } from '@/services/module'
import { useLessonsManager } from '@/services/lessons'
import useStyles from './style'

const SLessonsModule: React.FC = () => {
    const classes = useStyles()
    const Module = useModule()
    const LessonsManager = useLessonsManager()

    if (!Module.entity?.lessons) {
        return null
    }
    return (
        <div className={classes.section}>
            <UGrid.UContainer>
                <div className={classes.titleBlock}>
                    <LTitle.H2 variant="h2v3" className={classes.title}>
                        Уроки модуля
                    </LTitle.H2>
                </div>
                <div className={classes.content}>
                    <UGrid.URow className={classes.rowBlock}>
                        {Module.entity?.lessons?.map((lesson) => {
                            const { id, title, status, favorited } = lesson
                            return (
                                <UGrid.UCol
                                    xs={12}
                                    key={id}
                                    xl={6}
                                    className={classes.colBlock}
                                >
                                    <Box className={classes.lessonWrapper}>
                                        <WCardLesson
                                            to={routes.lesson.to(id)}
                                            title={title}
                                            completed={status === 'passed'}
                                            favorited={favorited}
                                            onChangeFavorite={LessonsManager.toggleFavorite(
                                                lesson
                                            )}
                                        />
                                    </Box>
                                </UGrid.UCol>
                            )
                        })}
                    </UGrid.URow>
                </div>
            </UGrid.UContainer>
        </div>
    )
}

export default SLessonsModule
