/* eslint-disable @typescript-eslint/unbound-method */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createAwaitAdapter, createListAdapter } from '@/store/common'
import {
    createFavoriteAddAction,
    createFavoriteRemoveAction,
} from '@/services/favorites'
import { LessonsApi } from './lessons.api'
import { TLessonEntity } from '../lesson/lesson.api'

const NAME = 'lessons'

const get = createAsyncThunk(`${NAME}/get`, async () => {
    const lessons = await LessonsApi.get()
    const list = lessons.data

    return {
        list,
    }
})

const [addToFavorite, addToFavoriteHandler] = createFavoriteAddAction(
    NAME,
    'lesson'
)
const [
    removeFromFavorite,
    removeFromFavoriteHandler,
] = createFavoriteRemoveAction(NAME, 'lesson')

const LessonsAdapter = createListAdapter<TLessonEntity>()
const LessonsAwaitAdapter = createAwaitAdapter(NAME)
const initialState = LessonsAdapter.getInitialState(
    LessonsAwaitAdapter.getAwaitState()
)
const lessonsStore = createSlice({
    name: NAME,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(get.fulfilled, LessonsAdapter.set)
            .addCase(addToFavorite.fulfilled, addToFavoriteHandler)
            .addCase(removeFromFavorite.fulfilled, removeFromFavoriteHandler)
            .addMatcher(
                LessonsAwaitAdapter.isFulfilled,
                LessonsAwaitAdapter.fulfilled
            )
            .addMatcher(
                LessonsAwaitAdapter.isPending,
                LessonsAwaitAdapter.pending
            )
            .addMatcher(
                LessonsAwaitAdapter.isRejected,
                LessonsAwaitAdapter.rejected()
            )
    },
})

export const LessonsActions = {
    get,
    addToFavorite,
    removeFromFavorite,
}
export default lessonsStore.reducer
