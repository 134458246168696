/* eslint-disable @typescript-eslint/unbound-method */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createAwaitAdapter, createListAdapter } from '@/store/common'
import { CertificatesApi, TCertificateEntity } from './certificates.api'

const NAME = 'certificates'

const get = createAsyncThunk(`${NAME}/get`, async () => {
    const certificates = await CertificatesApi.get()
    const list = certificates.data

    return {
        list,
    }
})

const CertificatesAdapter = createListAdapter<TCertificateEntity>()
const CertificatesAwaitAdapter = createAwaitAdapter(NAME)
const initialState = CertificatesAdapter.getInitialState(
    CertificatesAwaitAdapter.getAwaitState()
)
const certificatesStore = createSlice({
    name: NAME,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(get.fulfilled, (state, { payload }) => ({
                ...state,
                ...payload,
            }))
            .addMatcher(
                CertificatesAwaitAdapter.isFulfilled,
                CertificatesAwaitAdapter.fulfilled
            )
            .addMatcher(
                CertificatesAwaitAdapter.isPending,
                CertificatesAwaitAdapter.pending
            )
            .addMatcher(
                CertificatesAwaitAdapter.isRejected,
                CertificatesAwaitAdapter.rejected()
            )
    },
})

export const CertificatesActions = {
    get,
}
export default certificatesStore.reducer
