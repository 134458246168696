import React from 'react'
import { Box } from '@material-ui/core'

import UCheckbox from '@/components/ui/UCheckbox'

import UTitle from '@/components/ui/UTitle'
import UTabs, { UTab, UTabPanel } from '@/components/ui/UTabs'
import UButton from '@/components/ui/UButton'
import UGrid from '@/components/ui/UGrid'
import routes from '@/routes'
import { Link, useHistory } from 'react-router-dom'
import { useModules } from '@/services/modules'
import WCourseCard from '@/components/widget/WCourseCard'
import { TTag } from '@/services/module'
import useStyles from './style'

interface ITab {
    id: string
    label: string
    value: string
}
interface IButton {
    id: string
    label: string
    checked: boolean
}
interface ISCoursesProps {
    settings: {
        title: string
        type?: string
        tabs: Array<ITab>
        initialSelectedTab: string
        buttons?: Array<IButton>
        // data?: Array
    }
}

const SCourses: React.FC<ISCoursesProps> = ({
    settings,
}: ISCoursesProps): React.ReactElement => {
    const classes = useStyles({ type: settings?.type })
    const history = useHistory()

    const queryParams = new URLSearchParams(history.location.search)

    const moduleType = queryParams.get('tab') || settings.initialSelectedTab
    const moduleName = queryParams.get('module') || null

    const Modules = useModules()

    const handlerChangeCoursesType = (_e: any, value: string) => {
        queryParams.set('tab', value)
        history.replace({
            search: queryParams.toString(),
        })
    }
    const handlerChangeModule = (value: string) => () => {
        if (queryParams.get('module') === value) queryParams.delete('module')
        else queryParams.set('module', value)
        history.replace({
            search: queryParams.toString(),
        })
    }
    const modules = Modules.list.filter(
        (item) =>
            item.specialization.machineName === moduleType ||
            (moduleType === '' &&
                (item.tags.some((tag) => tag.id === moduleName) ||
                    moduleName === null))
    )
    const tags = Modules.list.reduce((prev: TTag[], cur) => {
        // eslint-disable-next-line no-param-reassign
        cur.tags.forEach((tag) => {
            if (prev.every((item) => item.id !== tag.id)) prev.push(tag)
        })
        return prev
    }, [])

    return (
        <Box className={classes.borderBottom}>
            <UGrid.UContainer>
                <Box className={classes.section}>
                    <Box className={classes.titleWrapper}>
                        <UTitle
                            mdFont={32}
                            lgFont={42}
                            xlFont={42}
                            className={classes.sectionTitle}
                        >
                            {settings.title}
                        </UTitle>
                        {settings?.type !== 'all' && (
                            <Box display={{ xs: 'none', xl: 'block' }}>
                                <Link to={routes.courses.path}>
                                    <UButton
                                        size="large"
                                        view="ghost"
                                        className={classes.breakpointsBtn}
                                    >
                                        Показать все курсы
                                    </UButton>
                                </Link>
                            </Box>
                        )}
                    </Box>
                    <Box className={classes.tabsWrapper}>
                        <UTabs
                            value={moduleType}
                            onChange={handlerChangeCoursesType}
                        >
                            {settings.tabs.map((tab) => (
                                <UTab
                                    key={tab.id}
                                    label={tab.label}
                                    value={tab.value}
                                />
                            ))}
                        </UTabs>
                        {settings?.buttons && (
                            <Box className={classes.checkboxesOuterWrapper}>
                                <Box className={classes.checkboxesWrapper}>
                                    <Box
                                        className={
                                            classes.checkboxesInnerWrapper
                                        }
                                    >
                                        {tags.map((tag) => (
                                            <UCheckbox
                                                key={tag.id}
                                                label={tag.title}
                                                onChange={handlerChangeModule(
                                                    tag.id
                                                )}
                                                checked={tag.id === moduleName}
                                            />
                                        ))}
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </Box>
                    <Box pt={3} width="100%">
                        {settings.tabs.map((tab) => (
                            <UTabPanel
                                key={tab.id}
                                index={tab.value}
                                value={moduleType}
                            >
                                <Box
                                    marginX={{ xs: 0, sm: 0, md: 0 }}
                                    display={{ xs: 'grid' }}
                                    gridTemplateColumns={{
                                        xs: '1fr',
                                        xl: '1fr 1fr',
                                    }}
                                    gridGap={{
                                        xs: '32px',
                                        md: '40px',
                                        lg: '48px',
                                    }}
                                >
                                    {modules.map((module) => {
                                        const {
                                            id,
                                            title,
                                            coverImage,
                                            description,
                                            specialization,
                                        } = module
                                        return (
                                            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                                            <div
                                                key={id}
                                                onClick={() => {
                                                    history.push(
                                                        routes.module.to(id)
                                                    )
                                                }}
                                            >
                                                <WCourseCard
                                                    picture={coverImage?.url}
                                                    title={title}
                                                    description={description}
                                                    specialization={
                                                        specialization.title
                                                    }
                                                    specializationId={
                                                        specialization.machineName
                                                    }
                                                />
                                            </div>
                                        )
                                    })}
                                </Box>
                            </UTabPanel>
                        ))}
                        {settings?.type !== 'all' && (
                            <Box display={{ xs: 'block', xl: 'none' }}>
                                <UButton
                                    size="large"
                                    view="ghost"
                                    className={classes.breakpointsBtn}
                                >
                                    Показать все курсы
                                </UButton>
                            </Box>
                        )}
                    </Box>
                </Box>
            </UGrid.UContainer>
        </Box>
    )
}

export default SCourses
