import BaseApi from '@/api/BaseApi'
import { AxiosPromise } from 'axios'
import type { TCourseEntity } from '@/services/course'

const CoursesApi = {
    get(): AxiosPromise<TCourseEntity[]> {
        return BaseApi.get('/courses')
    },
}

export { CoursesApi }
