import React from 'react'
import { IconButton } from '@material-ui/core'
import NotificationIndicator from '@/components/ui/UNotificationIndicator'
import useStyles from './style'

type DefaultProps = {
    onClick: () => void
    dark: boolean
    color: string
}
type BurgerBtnProps = Partial<DefaultProps>

// TODO: Доделать компонент
const BurgerBtn: React.FC<BurgerBtnProps> = ({
    onClick,
    dark = false,
    color = '',
}: BurgerBtnProps): React.ReactElement => {
    const classes = useStyles({ dark, color })
    return (
        <IconButton className={classes.wrapper} onClick={onClick}>
            <div className={classes.burgerblock}>
                {/* TODO: пробросить счетчик через пропсы */}

                <div className={classes.burgerelem} />
                <div className={classes.burgerelem} />
                <NotificationIndicator
                    count={3}
                    top="-20px"
                    right="-15px"
                    color="#DE1F06"
                />
            </div>
        </IconButton>
    )
}
export default BurgerBtn
