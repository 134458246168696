/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

type IPrivateRoute = RouteProps

const PrivateRoute = ({ ...rest }: IPrivateRoute): JSX.Element => {
    const isAuthenticated = true

    if (isAuthenticated) return <Route {...rest} />

    return <Redirect to="/" />
}

export default PrivateRoute
