import { useAppDispatch } from '@/store'
import { UserActions } from './user.store'

const useUserManager = () => {
    const dispatch = useAppDispatch()
    const get = () => dispatch(UserActions.get())
    return {
        get,
    }
}

export { useUserManager }
