import { makeStyles } from '@material-ui/core/styles'

interface Props {
    top: string
    left: string
    right: string
    bottom: string
    color: string
}

const useStyles = makeStyles(() => ({
    notification: {
        position: 'absolute',
        left: (props: Props) => `${props.left}`,
        bottom: (props: Props) => `${props.bottom}`,
        top: (props: Props) => `${props.top}`,
        right: (props: Props) => `${props.right}`,
        width: '28px',
        height: '28px',
        background: (props: Props) => `${props.color}`,
        borderRadius: '32px',
        textAlign: 'center',
        lineHeight: '28px',
        fontWeight: 500,
        fontSize: '13px',
        letterSpacing: '0.2px',
        color: '#fff',
    },
}))
export default useStyles
