import BaseApi, { TUserShort } from '@/api/BaseApi'
import { AxiosPromise } from 'axios'

const SpeakerApi = {
    get(id: string): AxiosPromise<TUserShort> {
        return BaseApi.get(`/speaker/${id}`)
    },
}

export { SpeakerApi }
