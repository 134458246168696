import React from 'react'
import clsx from 'clsx'
import moment from 'moment-ru'
import { TCertificateEntity } from '@/services/certificates'
import useStyles from './style'
import LTitle from '../UTitle'
import { ReactComponent as Logo } from '../../../images/logo.svg'

type TCertificate = {
    certificate: TCertificateEntity
}
const UCertificate: React.FC<TCertificate> = ({
    certificate,
}: TCertificate): React.ReactElement => {
    const classes = useStyles()
    const formattedDate = moment(certificate.date).format('DD MMMM YYYY')
    return (
        <div className={classes.root}>
            <LTitle.H2 variant="h2v3" className={classes.title} smFont={35}>
                Сертификат
            </LTitle.H2>
            <div className={classes.certificateIdBlock}>
                <div
                    className={clsx(
                        classes.certificateElem,
                        classes.certificateId
                    )}
                >
                    {certificate.displayId}
                </div>
                <div
                    className={clsx(
                        classes.certificateElem,
                        classes.certificateDate
                    )}
                >
                    {formattedDate}
                </div>
            </div>
            <div className={classes.nameBlock}>
                <div className={classes.nameBlockSubtitle}>
                    {certificate.subjectPrefix}
                </div>
                <LTitle.H1
                    smFont={19}
                    mdFont={38}
                    className={classes.nameBlockTitle}
                >
                    {certificate.subject.name}
                </LTitle.H1>
            </div>
            <div className={classes.wrapperCards}>
                {certificate.object.titlePrefix && (
                    <div
                        className={clsx(
                            classes.cardBlock,
                            classes.cardBlockFirst
                        )}
                    >
                        <div className={classes.firstCardSubtitle}>
                            {certificate.object.titlePrefix}
                        </div>
                        <LTitle.H3
                            variant="h3v2"
                            smFont={20}
                            className={classes.firstCardTitle1}
                        >
                            {certificate.object.title}
                        </LTitle.H3>
                        <div className={classes.firstCardAchievements}>
                            {certificate.object.actions?.map((action, idx) =>
                                idx > 0 ? (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={idx}
                                    >
                                        <span className={classes.firstCardPlus}>
                                            +
                                        </span>
                                        <div
                                            className={
                                                classes.firstCardAchievement
                                            }
                                        >
                                            {action}
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={idx}
                                        className={classes.firstCardAchievement}
                                    >
                                        {action}
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                )}
                {certificate.object.level && (
                    <div
                        className={clsx(
                            classes.cardBlock,
                            classes.cardBlockSecond
                        )}
                    >
                        <LTitle.H3
                            variant="h3v2"
                            smFont={20}
                            className={classes.firstCardTitle2}
                        >
                            {`Уровень - ${
                                certificate.object.level ?? 'Без уровня'
                            }`}
                        </LTitle.H3>
                        <div className={classes.firstCardSubtitle2}>
                            Программа пройдена
                            {certificate.object.progress
                                ? ` на ${certificate.object.progress}%`
                                : null}
                        </div>
                        <div className={classes.firstCardSubtitle}>
                            {certificate.object.duration}
                        </div>
                    </div>
                )}
            </div>
            <div className={classes.footBlock}>
                <div className={classes.footBlockInitials}>
                    {certificate.signatures?.map((signature, idx) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={idx} className={classes.footBlockInitial}>
                            <LTitle.H3
                                variant="h3v1"
                                smFont={19}
                                className={classes.footBlockName}
                            >
                                {signature.name}
                            </LTitle.H3>
                            <div className={classes.footBlockPosition}>
                                {signature.role}
                            </div>
                        </div>
                    ))}
                </div>
                <div className={classes.footBlockLogo}>
                    <Logo width="100%" height="100%" />
                </div>
            </div>
        </div>
    )
}
export default UCertificate
