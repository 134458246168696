import React, { useState } from 'react'

interface ICustomEvent<T> {
    target: {
        value: T
        [key: string]: any
    }
    [key: string]: any
}
type TUseInput<T> = {
    value: T
    setValue: React.Dispatch<React.SetStateAction<T>>
    bind: {
        value: T
        onChange(e: ICustomEvent<T>): void
    }
}

const useInput = <T>(initVal: T): TUseInput<T> => {
    const [value, setValue] = useState<T>(initVal)
    const handlerChange = (e: ICustomEvent<T>): void => setValue(e.target.value)
    return {
        value,
        setValue,
        bind: {
            value,
            onChange: handlerChange,
        },
    }
}

export default useInput
