import React, { useEffect } from 'react'

import UGrid from '@/components/ui/UGrid'
import SHeader from '@/sections/SHeader'
import SFooter from '@/sections/SFooter'
import BreadCrumbs from '@/components/ui/UBreadCrumbs'
import Runner from '@/utils/Runner'
import LArrowBack from '@/components/ui/UArrowBack'
import LTitle from '@/components/ui/UTitle'
import { Box } from '@material-ui/core'
import { useMaterialManager, useMaterial } from '@/services/material'
import { useParams } from 'react-router-dom'
import useStyles from './style'

const MaterialPage = (): JSX.Element => {
    const classes = useStyles()
    const MaterialManager = useMaterialManager()
    const Material = useMaterial()

    const params = useParams<{ id: string }>()
    // const [isPlaying, setIsPlaying] = useState(false)

    // const handlePlay = () => {
    //     setIsPlaying((isPlayingInner) => !isPlayingInner)
    // }
    useEffect(() => {
        MaterialManager.get(params.id)
    }, [])
    return (
        <>
            <SHeader adaptiveMenuView="light" isGoBackForAdaptive />
            <UGrid.UContainer className={classes.wrapper}>
                <div className={classes.breadCrumbs}>
                    <BreadCrumbs>
                        <BreadCrumbs.Item to="/">Все курсы</BreadCrumbs.Item>
                        <BreadCrumbs.Item to="/">Менеджмент</BreadCrumbs.Item>
                        <BreadCrumbs.Item to="/">
                            Название модуля
                        </BreadCrumbs.Item>
                        <BreadCrumbs.Item to="/">
                            Название урока
                        </BreadCrumbs.Item>
                        <BreadCrumbs.Item>Материал урока</BreadCrumbs.Item>
                    </BreadCrumbs>
                </div>
                <div className={classes.titleBlock}>
                    <div className={classes.arrowBackBlock}>
                        <LArrowBack to="/" color="#4C4C52 " />
                    </div>

                    <LTitle.H2 variant="h2v3">
                        {Material.entity?.title}
                    </LTitle.H2>
                </div>
                <Box className={classes.editorWrapper}>
                    {Material.entity?.content && (
                        <Runner content={Material.entity.content} />
                    )}
                </Box>
                {Material.entity?.h5pData?.map((item) => (
                    <iframe
                        frameBorder="0"
                        src={item.embedUrl}
                        key={item.h5pId}
                        title={item.title}
                    />
                ))}
            </UGrid.UContainer>
            <SFooter />
        </>
    )
}

export default MaterialPage
