import BaseApi from '@/api/BaseApi'
import { AxiosPromise } from 'axios'
import { TCollectionEntity } from '../collection'
import { TCourseEntity } from '../course'
import { TLessonEntity } from '../lesson'

export type TFavoriteData = {
    course: TCourseEntity
    collection: TCollectionEntity
    lesson: TLessonEntity
}
export type TFavoriteType = keyof TFavoriteData

export type TFavoriteEntity = {
    [P in keyof TFavoriteData]: {
        title: string
        type: P
        data: TFavoriteData[P][]
    }
}

export type TFavoriteAddRequest = {
    contentId: string
}
export type TFavoriteRemoveRequest = {
    contentId: string
}

const FavoritesApi = {
    get(): AxiosPromise<TFavoriteEntity> {
        return BaseApi.get('/favoritesgrouped')
    },
    add(body: TFavoriteAddRequest): AxiosPromise<TFavoriteEntity> {
        return BaseApi.post(`/favorites/?contentId=${body.contentId}`)
    },
    remove(data: TFavoriteRemoveRequest): AxiosPromise<string> {
        return BaseApi.delete(`/favorites/?contentId=${data.contentId}`)
    },
}

export { FavoritesApi }
