import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
    root: {},
    title: {
        marginBottom: 16,
    },
    content: {},
}))
export default useStyles
