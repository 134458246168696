import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        fontWeight: 500,
    },
    titleblock: {
        marginBottom: '8px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '13px',
        lineHeight: '140%',

        letterSpacing: 0.2,

        color: '#B8B8C3',
        [theme.breakpoints.up('md')]: {
            fontSize: 15,
        },
    },
    small: {
        display: 'inline-block ',
        fontSize: '35px',
        lineHeight: '115%',
        letterSpacing: '-0.4px',
        [theme.breakpoints.down(560)]: {
            fontSize: 24,
        },
    },
    large: {
        display: 'inline-block',
        fontSize: '42px',
        lineHeight: '115%',
        letterSpacing: '-0.4px',
        marginRight: '8px',
        [theme.breakpoints.down(560)]: {
            fontSize: 24,
        },
    },
    primary: {
        color: '#3AB2C9',
    },
    secondary: {
        color: '#B8B8C3',
    },
    total: {
        color: '#000',
    },
}))
export default useStyles
