import BaseApi from '@/api/BaseApi'
import { AxiosPromise } from 'axios'
import { TDepartament, TImage } from '../../api/BaseApi/types'

export type TSignature = {
    name: string
    role?: string
}

export type TObject = {
    type: string
    title: string
    titlePrefix: string
    actions?: string[]
    level?: string
    progress?: number
    duration?: string
}

export type TSubject = {
    id: string
    email: string
    name: string
    departament: TDepartament
    image?: TImage
}

export type TCertificateEntity = {
    id: string
    color?: string
    date: string
    signatures?: TSignature[]
    displayId: string
    subjectPrefix: string
    object: TObject
    subject: TSubject
}

const CertificatesApi = {
    get(): AxiosPromise<TCertificateEntity[]> {
        return BaseApi.get('/certificates')
    },
}

export { CertificatesApi }
