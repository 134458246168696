import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
    description: {
        fontSize: '24px',
        maxWidth: 264,
        margin: 'auto ',
        fontStyle: 'normal',
        fontWeight: 400,
        marginTop: 32,
        marginBottom: 32,
        [theme.breakpoints.down(768)]: {
            fontSize: '20px',
        },
    },
}))
export default useStyles
