import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '24px',
        background: 'rgba(17, 17, 17, 0.5)',
        boxShadow:
            '0px 7px 2px rgba(12, 43, 33, 0.02), 0px 8px 32px rgba(21, 8, 37, 0.16)',
        backdropFilter: 'blur(40px)',
        borderRadius: '4px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        color: '#fff',
        outline: 'none',
        border: 'none',
    },

    btn: {
        width: '46.5px',
        height: '46.5px',
        background: '#fff',
        borderRadius: '50%',
        display: 'flex',
        flexShrink: 0,
        justifyContent: 'center',
        alignItems: 'center',
    },
    title: {
        fontWeight: 600,
        fontSize: 15,
        marginLeft: '24.5px',
        flexShrink: 0,
        [theme.breakpoints.up('md')]: {
            fontSize: 20,
        },
    },
}))

export default useStyles
