import React, { useState } from 'react'
import { Typography, useMediaQuery } from '@material-ui/core'
import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'
import LAvatar from '../../ui/UAvatar'
import useStyles from './style'
import theme from '../../../theme'
import { btnAnimate, taskBlockAnimate } from './animate'
import LTitle from '../../ui/UTitle'
// TODO: Сделать кнопку скачать.
// TODO: Сделать анимацию-лесенку потом.

interface ICategoryTask {
    id: number
    title: string
    description: string
    countTasks: number
}
interface ICompletedTask {
    taskId: number
    fromId: number
    fromName: string
    fromSrc: string
    fromRole: string
    fromFile: string
    fromText: string
}
interface IWHomeworkCard {
    categoryTask: ICategoryTask
    completedTasks: ICompletedTask[]
    children: React.ReactChild
}

// Количество отображаемых аватаров
enum CountShowingAvatars {
    largeScreen = 5,
    smallScreen = 7,
}
const WHomeworkCard: React.FC<IWHomeworkCard> = ({
    categoryTask,
    completedTasks,
    children,
}: IWHomeworkCard): React.ReactElement => {
    const classes = useStyles({
        widthWhenOnePeople: completedTasks.length === 1,
    })
    const screenDown768 = useMediaQuery(theme.breakpoints.down('sm'))
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getNumberDisplayedAvatar = (): number => {
        if (screenDown768) {
            return CountShowingAvatars.smallScreen
        }
        return CountShowingAvatars.largeScreen
    }
    const [showTasks, setShowTasks] = useState(false)
    const handleClickAdditional = () => {
        setShowTasks(!showTasks)
    }
    return (
        <>
            <div className={classes.root}>
                <div className={classes.content}>
                    <LTitle.H2 className={classes.contentTitle}>
                        {categoryTask.title}
                    </LTitle.H2>
                    <Typography
                        variant="body2"
                        className={classes.contentDescription}
                    >
                        {categoryTask.description}
                    </Typography>
                    <div className={classes.countCompletedTasks}>
                        <Typography
                            variant="subtitle2"
                            component="div"
                            className={classes.countCompletedTasksText}
                        >
                            новых домашних заданий
                        </Typography>
                        <Typography variant="subtitle2" component="span">
                            {categoryTask.countTasks}
                        </Typography>
                    </div>
                    <div
                        className={classes.additionalBlock}
                        onClick={handleClickAdditional}
                        role="button"
                        onKeyDown={handleClickAdditional}
                        tabIndex={0}
                    >
                        <AnimatePresence>
                            <motion.div
                                variants={btnAnimate}
                                initial="downArrow"
                                animate={showTasks ? 'downArrow' : 'upArrow'}
                                transition={{ duration: 0.3 }}
                                className={classes.additionalBtn}
                            >
                                <svg
                                    width="14"
                                    height="8"
                                    viewBox="0 0 14 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M7.70711 7.70711C7.31658 8.09763 6.68342 8.09763 6.29289 7.70711L0.292894 1.70711C-0.0976312 1.31658 -0.0976312 0.683417 0.292894 0.292893C0.683418 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7 5.58579L12.2929 0.292894C12.6834 -0.0976301 13.3166 -0.09763 13.7071 0.292894C14.0976 0.683418 14.0976 1.31658 13.7071 1.70711L7.70711 7.70711Z"
                                        fill="#D8D8DA"
                                    />
                                </svg>
                            </motion.div>
                        </AnimatePresence>
                        <div>{showTasks ? 'свернуть' : 'подробнее'}</div>
                    </div>
                </div>
                <div className={classes.wrapperPeople}>
                    <div className={classes.blockPeople}>
                        {((completedTasks.length > 7 && screenDown768) ||
                            (completedTasks.length > 5 && !screenDown768)) && (
                            <div
                                className={clsx(
                                    classes.personWrapper,
                                    classes.avatarCounter
                                )}
                            >
                                <LAvatar
                                    alt="people"
                                    className={clsx(
                                        classes.avatar,
                                        classes.avatarBg
                                    )}
                                >
                                    {`+${
                                        completedTasks.length -
                                        getNumberDisplayedAvatar()
                                    }`}
                                </LAvatar>
                            </div>
                        )}
                        {completedTasks.map((person, i) => (
                            <div
                                className={classes.personWrapper}
                                key={person.taskId}
                                style={{ order: i }}
                            >
                                <LAvatar
                                    alt="people"
                                    src={person.fromSrc}
                                    className={clsx(classes.avatar)}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <AnimatePresence>
                {showTasks && (
                    <motion.div
                        variants={taskBlockAnimate}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        transition={{
                            duration: 0.3,
                            delay: 0.1,
                        }}
                        className={classes.tasksBlock}
                    >
                        {children}
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    )
}
export default WHomeworkCard
