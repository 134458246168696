import { makeStyles, Theme } from '@material-ui/core/styles'
import bgCloud from './svg/bg/clouds.svg'
import bgQuestions from './svg/bg/questions.svg'

interface Props {
    variant: 'cloud' | 'girl'
}
const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    notFoundBlock: {
        position: 'absolute',
        top: 0,
        height: '100vh ',
        width: '100% ',
        zIndex: -3,
        backgroundImage: ({ variant }: Props) =>
            variant === 'cloud' ? `url('${bgCloud}')` : `url('${bgQuestions}')`,
        backgroundPosition: 'cover',
    },
    content: {
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) ',
        zIndex: 2,
    },
    decoration: {
        position: 'absolute',
        zIndex: -2,
    },
    decorationRocket: {
        animation: `$AnimatedRocket infinite 4500ms ${theme.transitions.easing.easeInOut}`,
        bottom: 0,
        right: 55,
        [theme.breakpoints.down(1024)]: {
            bottom: -100,
            right: 0,
        },
    },
    '@keyframes AnimatedRocket': {
        '0%': {
            transform: 'translateY(-50px)',
        },
        '50%': {
            transform: 'translateY(0)',
        },
        '100%': {
            transform: 'translateY(-50px)',
        },
    },
}))
export default useStyles
