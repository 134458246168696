import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core'
import theme from '@/theme'

interface ICheckSvg {
    color?: string
}
const useStyles = makeStyles(() => ({
    root: {
        width: 33,
        height: 28,
        [theme.breakpoints.down(768)]: {
            width: 12,
            height: 15,
        },
    },
}))
const CheckSvg: React.FC<ICheckSvg> = ({
    color = '#3AB2C9',
}: ICheckSvg): React.ReactElement => {
    const classes = useStyles()
    const screenDown768 = useMediaQuery(theme.breakpoints.down('sm'))
    return screenDown768 ? (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.75 12.2739L4.75 12.2739"
                stroke="#4FC2D8"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.7002 18.2985L19.7502 12.2745L13.7002 6.24951"
                stroke="#4FC2D8"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ) : (
        <svg
            className={classes.root}
            viewBox="0 0 33 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M31.5 13.5488L1.5 13.5488"
                stroke="#3AB2C9"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.4004 25.5975L31.5004 13.5495L19.4004 1.49951"
                stroke={color}
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
export default CheckSvg
