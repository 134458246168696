import UContainer from './Container'
import URow from './Row'
import UCol from './Col'

export default {
    UContainer,
    URow,
    UCol,
}

export { UContainer, UCol, URow }
