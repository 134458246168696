import React from 'react'
import UTitle from '@/components/ui/UTitle'
import theme from '@/theme'
import { useMediaQuery } from '@material-ui/core'
import { useCollection } from '@/services/collection'
import WCourseLessons from '@/components/widget/WCourseLessons'
import WTitleCourseLink from '@/components/widget/WTitleCourseLink'
import WCardCourse from '@/components/widget/WCard/Course'
import UGrid from '@/components/ui/UGrid'
import routes from '@/routes'
import useStyles from './style'

const SCollectionCourse: React.FC = () => {
    const view = useMediaQuery(theme.breakpoints.up('md'))
    const classes = useStyles({ view })
    const Collection = useCollection()

    if (!Collection.entity?.courses) {
        return null
    }
    return (
        <div className={classes.section}>
            <UGrid.UContainer>
                <div className={classes.titleBlock}>
                    <UTitle.H2 variant="h2v3" className={classes.title}>
                        Курсы коллекции
                    </UTitle.H2>
                </div>
                <div className={classes.content}>
                    <UGrid.URow className={classes.rowBlock}>
                        {Collection.entity?.courses?.map((course) => {
                            const {
                                id,
                                title,
                                favorited,
                                lessons,
                                progress,
                                status,
                                dueDate,
                            } = course
                            return (
                                <UGrid.UCol
                                    key={id}
                                    xs={12}
                                    xl={12}
                                    className={classes.colBlock}
                                >
                                    <WCardCourse
                                        to={routes.course.to(id)}
                                        title={title}
                                        favorited={favorited}
                                        progress={progress}
                                        completed={status === 'passed'}
                                        deadline={dueDate?.date}
                                    >
                                        <WCourseLessons>
                                            <>
                                                {lessons &&
                                                    lessons.map((lesson) => (
                                                        <WTitleCourseLink
                                                            to={routes.lesson.to(
                                                                lesson.id
                                                            )}
                                                            key={lesson.id}
                                                            status={
                                                                lesson.status ===
                                                                'passed'
                                                                    ? 'completed'
                                                                    : 'inProgress'
                                                            }
                                                            title={lesson.title}
                                                        />
                                                    ))}
                                            </>
                                        </WCourseLessons>
                                    </WCardCourse>
                                </UGrid.UCol>
                            )
                        })}
                    </UGrid.URow>
                </div>
            </UGrid.UContainer>
        </div>
    )
}

export default SCollectionCourse
