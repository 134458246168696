/* eslint-disable @typescript-eslint/unbound-method */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createAwaitAdapter, TAwaitState } from '@/store/common'
import { CourseApi, TCourseEntity } from './course.api'
import { LessonsActions } from '../lessons'

const NAME = 'course'

const get = createAsyncThunk(`${NAME}/get`, async (id: string) => {
    const course = await CourseApi.get(id)
    const { data } = course

    return data
})

const CourseAwaitAdapter = createAwaitAdapter(NAME)

export interface ICourseInitialState extends TAwaitState {
    entity: TCourseEntity | null
}

const initialState: ICourseInitialState = {
    entity: null,
    ...CourseAwaitAdapter.getAwaitState(),
}
const courseStore = createSlice({
    name: NAME,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(get.fulfilled, (state, { payload }) => {
                state.entity = payload
            })
            .addCase(
                LessonsActions.addToFavorite.fulfilled,
                (state, { payload }) => {
                    state.entity?.lessons?.forEach((lesson) => {
                        if (lesson.id === payload) lesson.favorited = true
                    })
                }
            )
            .addCase(
                LessonsActions.removeFromFavorite.fulfilled,
                (state, { payload }) => {
                    state.entity?.lessons?.forEach((lesson) => {
                        if (lesson.id === payload) lesson.favorited = false
                    })
                }
            )
            .addMatcher(
                CourseAwaitAdapter.isFulfilled,
                CourseAwaitAdapter.fulfilled
            )
            .addMatcher(
                CourseAwaitAdapter.isPending,
                CourseAwaitAdapter.pending
            )
            .addMatcher(
                CourseAwaitAdapter.isRejected,
                CourseAwaitAdapter.rejected()
            )
    },
})

export const CourseActions = {
    get,
}
export default courseStore.reducer
