import { IconButton } from '@material-ui/core'
import React from 'react'

interface CloseBtnProps {
    // eslint-disable-next-line
  onClick: () => any
}

const CloseBtn: React.FC<CloseBtnProps> = ({
    onClick,
}: CloseBtnProps): React.ReactElement => (
    <IconButton
        edge="start"
        color="inherit"
        onClick={onClick}
        aria-label="close"
    >
        <svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.00014 23.0001L23.6276 0.372702L25.7489 2.49402L3.12146 25.1214L1.00014 23.0001Z"
                fill="white"
            />
            <path
                d="M3.00023 0L25.6276 22.6274L23.5063 24.7487L0.878906 2.12132L3.00023 0Z"
                fill="white"
            />
        </svg>
    </IconButton>
)
export default CloseBtn
