import { useAppDispatch } from '@/store'
import { LearningProgramActions } from './learningProgram.store'

const useLearningProgramManager = () => {
    const dispatch = useAppDispatch()
    const get = (id: string) => dispatch(LearningProgramActions.get(id))
    return {
        get,
    }
}

export { useLearningProgramManager }
