import React from 'react'
import { Link } from 'react-router-dom'
import useStyles from './style'

interface IBreadCrumbs {
    children?:
        | React.ReactElement<BreadCrumbsItem>[]
        | React.ReactElement<BreadCrumbsItem>
}

interface ILBreadCrumbs<T> extends React.FC<T> {
    Item: React.FC<BreadCrumbsItem>
}
interface BreadCrumbsItem {
    children: React.ReactNode
    to?: string
}
const BreadCrumbs: ILBreadCrumbs<IBreadCrumbs> = ({
    children,
}: IBreadCrumbs): React.ReactElement => {
    const classes = useStyles()
    return <div className={classes.root}>{children}</div>
}
BreadCrumbs.Item = ({ children, to }: BreadCrumbsItem) => {
    const classes = useStyles()
    if (to) {
        return (
            <div className={classes.linkRoot}>
                <Link to={to} className={classes.link}>
                    {children}
                </Link>
                <div className={classes.dotBlock}>
                    <span className={classes.dot} />
                </div>
            </div>
        )
    }
    return <div className={classes.link}>{children}</div>
}
BreadCrumbs.Item.displayName = 'BreadCrumbsItem'

export default BreadCrumbs
