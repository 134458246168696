import React from 'react'
import LTitle from '@/components/ui/UTitle'
import WCardLesson from '@/components/widget/WCard/Lesson'
import { useCourse } from '@/services/course'
import UGrid from '../../components/ui/UGrid'
import useStyles from './style'

const SCourseLessons = (): JSX.Element => {
    const classes = useStyles()
    const Course = useCourse()

    return (
        <div className={classes.section}>
            <UGrid.UContainer>
                <div className={classes.titleBlock}>
                    <LTitle.H2 variant="h2v3" className={classes.title}>
                        Уроки курса
                    </LTitle.H2>
                </div>
                <div className={classes.content}>
                    <UGrid.URow className={classes.rowBlock}>
                        {Course.entity?.lessons?.map((lesson) => {
                            const { id, status, favorited, title } = lesson
                            return (
                                <UGrid.UCol
                                    xs={12}
                                    key={id}
                                    xl={6}
                                    className={classes.colBlock}
                                >
                                    <WCardLesson
                                        title={title}
                                        completed={status === 'passed'}
                                        favorited={favorited}
                                    />
                                </UGrid.UCol>
                            )
                        })}
                    </UGrid.URow>
                </div>
            </UGrid.UContainer>
        </div>
    )
}

export default SCourseLessons
