import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    programBlock: {
        border: 'none',
        padding: '20px 0px',
        [theme.breakpoints.up('xl')]: {
            padding: '40px 0px',
            borderBottom: '1px solid #EBEDEF',
        },
    },
    title: {
        marginTop: '-40px',
        fontWeight: 500,
        fontSize: 32,
        lineHeight: '120%',
        letterSpacing: -0.3,
        color: '#000000',
        marginBottom: 56,
        [theme.breakpoints.down('md')]: {
            marginTop: 0,
        },
        [theme.breakpoints.down('sm')]: {
            fontWeight: 400,
            fontSize: 24,
            marginBottom: 24,
        },
        [theme.breakpoints.up('xl')]: {
            marginTop: 0,
        },
    },
    tabPanel: {},
}))
export default useStyles
