import BaseApi from '@/api/BaseApi'
import { AxiosPromise } from 'axios'
import type { TLessonEntity } from '@/services/lesson'
import type {
    TDateWithDateTime,
    Th5pData,
    TImage,
    TSpecialization,
} from '@/api/BaseApi/types'
import { TContent } from '@/utils/Runner'

export type TCourseStatus = 'notStarted' | 'inProgress' | 'notPassed' | 'passed'

export type TCourseEntity = {
    courseId?: number
    title: string
    status: TCourseStatus
    lessons?: TLessonEntity[]

    // Описание курса
    description?: string
    specialization: TSpecialization
    dueDate?: TDateWithDateTime

    // Прогресс от 0 до 100
    progress?: number
    coverImage?: TImage

    // uuid курса
    id: string

    content?: TContent
    startTest?: Th5pData
    endTest?: Th5pData

    favorited: boolean
}

const CourseApi = {
    get(id: string): AxiosPromise<TCourseEntity> {
        return BaseApi.get(`/courses/${id}`)
    },
}

export { CourseApi }
