import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import lessons from '@/services/lessons/lessons.store'
import certificates from '@/services/certificates/certificates.store'
import lesson from '@/services/lesson/lesson.store'
import modules from '@/services/modules/modules.store'
import oneModule from '@/services/module/module.store'
import courses from '@/services/courses/courses.store'
import course from '@/services/course/course.store'
import favorites from '@/services/favorites/favorites.store'
import notifications from '@/services/notifications/notifications.store'
import calendarEvents from '@/services/calendarEvents/calendarEvents.store'
import user from '@/services/user/user.store'
import speakers from '@/services/speakers/speakers.store'
import speaker from '@/services/speaker/speaker.store'
import learningPrograms from '@/services/learningPrograms/learningPrograms.store'
import learningProgram from '@/services/learningProgram/learningProgram.store'
import collections from '@/services/collections/collections.store'
import collection from '@/services/collection/collection.store'
import homeworkForChecking from '@/services/homeworksForChecking/homeworksForChecking.store'
import material from '@/services/material/material.store'

const store = configureStore({
    reducer: {
        lesson,
        lessons,
        certificates,
        modules,
        oneModule,
        course,
        courses,
        favorites,
        notifications,
        calendarEvents,
        user,
        speakers,
        speaker,
        learningPrograms,
        learningProgram,
        collections,
        collection,
        homeworkForChecking,
        material,
    } as const,
    devTools: process.env.NODE_ENV === 'development',
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export default store
