import { makeStyles, Theme } from '@material-ui/core/styles'

interface Props {
    widthWhenOnePeople: boolean
}
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        color: '#fff',
        margin: '0 -24px',
        [theme.breakpoints.up('md')]: {
            margin: '0',
            flexDirection: 'row',
        },
    },
    content: {
        padding: '32px 24px 0 24px',
        width: '100%',
        background: '#00A8D7',
        backdropFilter: 'blur(8px)',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            paddingTop: 48,
            paddingLeft: 40,
            paddingBottom: 30.8,
            marginRight: -5,
        },
    },
    contentTitle: {
        // fontSize: 24,
        color: theme.palette.primary.contrastText,
        marginBottom: 32,
    },
    contentDescription: {
        color: theme.palette.primary.contrastText,
        maxWidth: 502,
        marginBottom: 32,
        paddingRight: 16,
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
        },
    },
    countCompletedTasks: {
        background: '#31B7DD',
        borderRadius: '203px',
        maxWidth: 265,
        position: 'relative',
        padding: '8px 20px 8px 50px',
        color: '#fff',
        marginTop: 'auto',
        marginBottom: 18,
        '& span': {
            width: 38,
            height: 38,
            backgroundColor: '#fff',
            color: theme.palette.text.primary,
            borderRadius: '50%',
            position: 'absolute',
            top: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center ',
        },
    },
    countCompletedTasksText: {
        color: '#fff',
    },
    additionalBlock: {
        marginLeft: 5,
        display: 'flex',
        cursor: 'pointer',
        maxWidth: 120,
        userSelect: 'none',
        outline: 'none',
    },
    additionalBtn: {
        marginRight: 10,
    },
    wrapperPeople: {
        padding: '32px 24px 32px 24px',
        background: '#00A8D7',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            paddingTop: 45,
            paddingBottom: 52,
            paddingLeft: 45,
            paddingRight: 45,
            backgroundColor: '#009ED7',
            width: ({ widthWhenOnePeople }: Props) =>
                widthWhenOnePeople ? null : 304,
            flexShrink: 0,
        },
        [theme.breakpoints.up('lg')]: {
            // paddingLeft: 48,
            // paddingRight: 48,
        },
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    blockPeople: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: '0 -5px',
        marginTop: -32,
        [theme.breakpoints.up('lg')]: {
            margin: '0 -24px',
            marginTop: -32,
        },
    },
    personWrapper: {
        width: 52,
        height: 52,
        flexBasis: 52,
        flexShrink: 0,
        boxSizing: 'content-box',
        '&:nth-child(n+9)': {
            display: 'none',
        },
        padding: '0 16px',
        paddingTop: 32,
        [theme.breakpoints.up('md')]: {
            width: 80,
            height: 80,
            '&:nth-child(n+7)': {
                display: 'none',
            },
        },
        [theme.breakpoints.up('lg')]: {
            padding: '0 24px',
            paddingTop: 32,
        },
    },
    avatar: {
        width: 80,
        height: 80,
        color: '#fff',
        borderRadius: '50%',
        [theme.breakpoints.down('sm')]: {
            width: 52,
            height: 52,
        },
    },
    avatarBg: {
        backgroundColor: '#fff',
        color: '#000',
        fontFamily: 'Graphik LC TT ',
        fontWeight: 600,
        fontSize: 14,
        [theme.breakpoints.up('md')]: {
            fontSize: 24,
        },
    },
    avatarCounter: {
        order: 999,
        [theme.breakpoints.up('md')]: {
            order: 1,
        },
    },
    lastOrder768: {
        [theme.breakpoints.up('sm')]: {
            order: 1,
        },
    },
    tasksBlock: {
        background: '#F6F7F9',
        margin: '0 -24px',
        padding: 24,
        [theme.breakpoints.up('md')]: {
            margin: '0',
        },
    },
    wrapperTask: {
        marginBottom: 16,
    },
}))
export default useStyles
