import { makeStyles } from '@material-ui/core'
import React from 'react'
import ULegend from '@/components/ui/ULegend'

const useStyle = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'flex-start',
        margin: -13,
        [theme.breakpoints.up('md')]: {
            display: 'inline-flex',
            flexDirection: 'column',
            alignItems: 'stretch',
        },
        [theme.breakpoints.up('xl')]: {
            flexDirection: 'row',
        },
    },
    label: {
        flexBasis: '100%',
        padding: 13,
        [theme.breakpoints.up('xl')]: {},
        [theme.breakpoints.up('md')]: {
            flexBasis: 'initial',
        },
    },
    section: {
        display: 'flex',
        flexBasis: '50%',
        flexWrap: 'wrap',
        [theme.breakpoints.up('md')]: {
            flexBasis: 'initial',
            '& + &': {
                borderTop: '1px solid #EBEDEF',
            },
        },
        [theme.breakpoints.up('xl')]: {
            '& + &': {
                borderTop: 'none',
                borderLeft: '1px solid #EBEDEF',
            },
        },
    },
}))

const LabelMap = {
    profile: '#4FC2D8',
    unProfile: '#FF8A05',
    common: '#9CA8BE',
    ended: '#B8B8C3',
    skip: '#FF7266',
} as const

const WLegendLabelList = () => {
    const classes = useStyle()

    return (
        <div className={classes.root}>
            <div className={classes.section}>
                <div className={classes.label}>
                    <ULegend color={LabelMap.profile}>Профильные</ULegend>
                </div>
                <div className={classes.label}>
                    <ULegend color={LabelMap.unProfile}>Надпрофильные</ULegend>
                </div>
                <div className={classes.label}>
                    <ULegend color={LabelMap.common}>Общие</ULegend>
                </div>
            </div>
            <div className={classes.section}>
                <div className={classes.label}>
                    <ULegend color={LabelMap.ended}>Завершенные</ULegend>
                </div>
                <div className={classes.label}>
                    <ULegend color={LabelMap.skip}>Пропущенные</ULegend>
                </div>
            </div>
        </div>
    )
}

export default WLegendLabelList
