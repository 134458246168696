/* eslint-disable @typescript-eslint/unbound-method */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createAwaitAdapter, createListAdapter } from '@/store/common'
import { TNotificationEntity, NotificationsApi } from './notifications.api'

const NAME = 'notifications'

const get = createAsyncThunk(`${NAME}/get`, async () => {
    const notifications = await NotificationsApi.get()
    const list = notifications.data

    return {
        list,
    }
})

const NotificationsAwaitAdapter = createAwaitAdapter(NAME)
const NotificationsAdapter = createListAdapter<TNotificationEntity>()
const initialState = NotificationsAdapter.getInitialState(
    NotificationsAwaitAdapter.getAwaitState()
)

const notificationsStore = createSlice({
    name: NAME,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(get.fulfilled, NotificationsAdapter.set)
            .addMatcher(
                NotificationsAwaitAdapter.isFulfilled,
                NotificationsAwaitAdapter.fulfilled
            )
            .addMatcher(
                NotificationsAwaitAdapter.isPending,
                NotificationsAwaitAdapter.pending
            )
            .addMatcher(
                NotificationsAwaitAdapter.isRejected,
                NotificationsAwaitAdapter.rejected()
            )
    },
})

export const NotificationsActions = {
    get,
}
export default notificationsStore.reducer
