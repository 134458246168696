import { PayloadAction } from '@reduxjs/toolkit'

export type TEntity<T extends Record<string, unknown>, UUID extends keyof T> = {
    [K in keyof T]: T[K]
} &
    {
        [key in UUID]: T[UUID]
    }

export interface IListState<T extends TEntity<T, keyof T>> {
    list: T[]
    count: number
    page: number
    total: number
    info: Record<string, unknown> | null
}

export type TSetPayload<T extends TEntity<T, keyof T>> = Partial<
    IListState<T>
> &
    Pick<IListState<T>, 'list'>

export type TEditPayload<
    T extends TEntity<T, UUID>,
    UUID extends keyof T
> = (Partial<T> & { [key in UUID]: T[key] })[]

export type TRemovePayload<T extends TEntity<T, keyof T>> = T[keyof T][]

export interface IListHandlers<
    T extends TEntity<T, UUID>,
    UUID extends keyof T
> {
    getInitialState<INJECT extends Record<string, unknown>>(
        inject?: INJECT
    ): IListState<T> & INJECT
    set<S extends IListState<T>>(
        state: S,
        action: PayloadAction<TSetPayload<T>>
    ): void
    add<S extends IListState<T>>(state: S, action: PayloadAction<T[]>): void
    edit<S extends IListState<T>>(
        state: S,
        action: PayloadAction<TEditPayload<T, UUID>>
    ): void
    remove<S extends IListState<T>>(
        state: S,
        action: PayloadAction<TRemovePayload<T>>
    ): void
}

const createListAdapter = <T extends Record<string, unknown>>(
    uuid: keyof T = 'id'
): IListHandlers<T, typeof uuid> => ({
    getInitialState: <INJECT extends Record<string, unknown>>(
        inject = {} as INJECT
    ) => ({
        list: [],
        count: 0,
        info: {},
        page: 0,
        total: 0,
        ...(inject || {}),
    }),
    set: (state, { payload }) => {
        const {
            list = [],
            total = list.length,
            page = 0,
            // TODO: Тут нужно подумать
            count = total,
            info = null,
        } = payload
        state.list = list
        state.total = total
        state.page = page
        state.count = count
        state.info = info
    },
    /**
     * Добавление элементов или элемента
     *
     * @param payload - Элементы который нужно добавить, принимает массив, если нужно добавить один элемент то оберните его в массив
     */
    add: (state, { payload }) => {
        // const count = state.list.length
        // if (count < state.count)
        state.list.concat(payload)
    },
    /**
     * Редактирование элементов или элемента
     *
     * @param payload - Элементы который нужно отредактировать, принимает массив, если нужно изменить один элемент то оберните его в массив
     */
    edit: (state, { payload }) => {
        payload.forEach((item) => {
            const id = item[uuid]
            state.list.forEach((value, idx) => {
                if (value[uuid] === id)
                    state.list[idx] = {
                        ...value,
                        ...item,
                    }
            })
        })
    },
    remove: (state, { payload }) => {
        const ids = payload
        state.list = state.list.filter((item) => !ids.includes(item[uuid]))
    },
})

export { createListAdapter }
