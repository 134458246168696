import { createMuiTheme, lighten } from '@material-ui/core'
import moment from 'moment-ru'
// eslint-disable-next-line no-restricted-imports
import { BreakpointValues } from '@material-ui/core/styles/createBreakpoints'

moment.locale('ru')

const values: BreakpointValues = {
    xs: 0,
    sm: 375,
    md: 768,
    lg: 1024,
    xl: 1920,
}
const themeBreakpoints = createMuiTheme({
    breakpoints: {
        values,
    },
})

const { breakpoints } = themeBreakpoints

const palette = {
    primary: {
        main: '#3AB2C9',
        // для ховеров main цвета
        light: lighten('#3AB2C9', 0.2),
        contrastText: '#FFF',
    },
    secondary: {
        main: '#00A8D7',
    },
    success: {
        main: '#6BD689',
    },
    text: {
        // для заголовков
        primary: '#000000',
        // для body-text
        secondary: '#4C4C52',
        // для subtitle
        hint: '#B8B8C3',
    },
    error: {
        main: '#F5075A',
    },
}

const theme = createMuiTheme({
    breakpoints,
    palette,
    typography: {
        button: {
            fontFamily: 'Graphik LC TT, sans-serif  ',
            textTransform: 'none',
        },
        h1: {
            fontFamily: 'Graphik LC TT, sans-serif ',
            fontWeight: 500,
            fontSize: 57,
            color: palette.text.primary,
            [breakpoints.down(768)]: {
                fontSize: 32,
            },
        },
        h2: {
            fontFamily: 'Graphik LC TT, sans-serif ',
            fontWeight: 500,
            fontSize: 42,
            color: palette.text.primary,
            [breakpoints.down(1024)]: {
                fontSize: 32,
            },
        },
        h3: {
            fontFamily: 'Graphik LC TT, sans-serif ',
            fontWeight: 500,
            fontSize: 32,
            color: palette.text.primary,
            [breakpoints.down(768)]: {
                fontSize: 24,
                letterSpacing: -0.2,
            },
        },
        h4: {
            fontFamily: 'Graphik LC TT, sans-serif ',
            fontWeight: 500,
            fontSize: 20,
            color: palette.text.primary,
            lineHeight: '160%',
            [breakpoints.down(768)]: {
                fontSize: 15,
            },
        },
        subtitle1: {
            fontFamily: 'TT Norms Pro, sans-serif',
            fontWeight: 500,
            fontSize: 15,
            color: palette.text.hint,
        },
        subtitle2: {
            fontFamily: 'Graphik LC TT, sans-serif ',
            fontWeight: 500,
            fontSize: 15,
            color: palette.text.hint,
            [breakpoints.down(768)]: {
                fontSize: 13,
            },
        },
        body1: {
            fontFamily: 'NormsPro Regular TT, sans-serif ',
            fontWeight: 'normal',
            fontSize: 20,
            color: palette.text.secondary,
            [breakpoints.down(768)]: {
                fontSize: 15,
                color: palette.text.primary,
            },
        },
        body2: {
            fontFamily: 'Graphik-Light, sans-serif ',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 20,
            lineHeight: '160%',
            color: palette.text.primary,
            [breakpoints.down(768)]: {
                fontSize: 15,
            },
        },
    },
})

export default theme
