import React, { useEffect } from 'react'

import UHeader from '@/sections/SHeader'
import { useFavoritesManager } from '@/services/favorites'
import SFavorites from '@/sections/SFavorites'
import SFooter from '@/sections/SFooter'

const FavoritesPage = (): JSX.Element => {
    const FavoritesManager = useFavoritesManager()
    useEffect(() => {
        FavoritesManager.get()
    }, [])
    return (
        <>
            <UHeader />
            <SFavorites />
            <SFooter />
        </>
    )
}

export default FavoritesPage
