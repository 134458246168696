import React from 'react'
import { useSpeakers } from '@/services/speakers'
import UTitle from '@/components/ui/UTitle'
import UGrid from '@/components/ui/UGrid'
import Speaker from '@/components/widget/WSpeakersScroll/components/Speaker'
import WSpeakersScroll from '@/components/widget/WSpeakersScroll'
import useStyles from './style'

interface IProps {
    isModule?: boolean
}

const SSpeakers = ({ isModule = false }: IProps): React.ReactElement => {
    const classes = useStyles({ isModule })
    const speakers = useSpeakers()
    return (
        <>
            <UGrid.UContainer>
                <div className={classes.root}>
                    <UTitle.H2 className={classes.title}>Спикеры</UTitle.H2>
                    <WSpeakersScroll>
                        {speakers.list.map((speaker) => {
                            const { id, name, image, departament } = speaker
                            return (
                                <Speaker
                                    key={id}
                                    name={name}
                                    roleName={departament.displayName || ''}
                                    image={image?.url || ''}
                                />
                            )
                        })}
                    </WSpeakersScroll>
                </div>
            </UGrid.UContainer>
        </>
    )
}
export default SSpeakers
