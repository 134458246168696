import React from 'react'

import UGrid from '@/components/ui/UGrid'
import { useLearningProgram } from '@/services/learningProgram'
import Runner from '@/utils/Runner'

const SLPContent = (): JSX.Element => {
    const LearningProgram = useLearningProgram()
    return (
        <>
            {LearningProgram.entity?.content && (
                <UGrid.UContainer>
                    <Runner content={LearningProgram.entity?.content} />
                </UGrid.UContainer>
            )}
        </>
    )
}

export default SLPContent
