import { useAppDispatch } from '@/store'
import { CollectionActions } from './collection.store'

const useCollectionsManager = () => {
    const dispatch = useAppDispatch()
    const get = (id: string) => dispatch(CollectionActions.get(id))
    return {
        get,
    }
}

export { useCollectionsManager }
