import { makeStyles, Theme } from '@material-ui/core/styles'

interface Props {
    isModule: boolean
}
const useStyles = makeStyles((theme: Theme) => ({
    root: (props: Props) => ({
        padding: '64px 0',
        [theme.breakpoints.up('md')]: {
            padding: '80px 16px',
            maxWidth: 'none',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '0',
            margin: '80px auto',
            maxWidth: props.isModule ? 'none' : '710px',
        },
        [theme.breakpoints.up('xl')]: {
            display: 'block',
            margin: '0 auto',
            padding: '132px 0',
            maxWidth: 'none',
        },
    }),
    title: (props: Props) => ({
        textAlign: props.isModule ? 'left' : 'center',
        marginBottom: 0,

        [theme.breakpoints.up('md')]: {
            marginBottom: 40,
            textAlign: 'left',
        },
    }),
}))
export default useStyles
