import { useAppDispatch } from '@/store'
import { LessonActions } from './lesson.store'

const useLessonManager = () => {
    const dispatch = useAppDispatch()
    const get = (id: string) => dispatch(LessonActions.get(id))
    const clear = () => dispatch(LessonActions.clear())
    return {
        get,
        clear,
    }
}

export { useLessonManager }
