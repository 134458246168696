import React from 'react'
import LTitle from '@/components/ui/UTitle'
import { Box, useMediaQuery } from '@material-ui/core'
import WCourseLessons from '@/components/widget/WCourseLessons'
import theme from '@/theme'
import WTitleCourseLink from '@/components/widget/WTitleCourseLink'
import WCardCourse from '@/components/widget/WCard/Course'
import UGrid from '@/components/ui/UGrid'
import { useModule } from '@/services/module'
import routes from '@/routes'
import { useCoursesManager } from '@/services/courses'
import useStyles from './style'

const SCourseModule: React.FC = () => {
    const view = useMediaQuery(theme.breakpoints.up('md'))
    const classes = useStyles({ view })
    const Module = useModule()
    const CoursesManager = useCoursesManager()

    if (!Module.entity?.courses) {
        return null
    }
    return (
        <div className={classes.section}>
            <UGrid.UContainer>
                <div className={classes.titleBlock}>
                    <LTitle.H2 variant="h2v3" className={classes.title}>
                        Курсы модуля
                    </LTitle.H2>
                </div>
                <div className={classes.content}>
                    <UGrid.URow className={classes.rowBlock}>
                        {Module.entity?.courses?.map((course) => {
                            const {
                                id,
                                title,
                                favorited,
                                lessons,
                                progress,
                                status,
                                dueDate,
                            } = course
                            return (
                                <UGrid.UCol
                                    key={id}
                                    xs={12}
                                    xl={6}
                                    className={classes.colBlock}
                                >
                                    <Box className={classes.cardWrapper}>
                                        <WCardCourse
                                            to={routes.course.to(id)}
                                            title={title}
                                            favorited={favorited}
                                            progress={progress}
                                            completed={status === 'passed'}
                                            deadline={dueDate?.date}
                                            onChangeFavorite={CoursesManager.toggleFavorite(
                                                course
                                            )}
                                        >
                                            <WCourseLessons>
                                                <>
                                                    {lessons &&
                                                        lessons.map(
                                                            (lesson) => (
                                                                <WTitleCourseLink
                                                                    to={routes.lesson.to(
                                                                        lesson.id
                                                                    )}
                                                                    key={
                                                                        lesson.id
                                                                    }
                                                                    status={
                                                                        lesson.status ===
                                                                        'passed'
                                                                            ? 'completed'
                                                                            : 'inProgress'
                                                                    }
                                                                    title={
                                                                        lesson.title
                                                                    }
                                                                />
                                                            )
                                                        )}
                                                </>
                                            </WCourseLessons>
                                        </WCardCourse>
                                    </Box>
                                </UGrid.UCol>
                            )
                        })}
                    </UGrid.URow>
                </div>
            </UGrid.UContainer>
        </div>
    )
}

export default SCourseModule
