import React from 'react'
import { Box } from '@material-ui/core'

import UEventCalendar, {
    UEvent,
    UEventLine,
} from '@/components/ui/UEventCalendar'
import WEvent from '@/components/widget/WEvent'
import UTitle from '@/components/ui/UTitle'
import UGrid from '@/components/ui/UGrid'
import WLegendLabelList from '@/components/widget/WLegendLabelList'
import { useCalendarEvents } from '@/services/calendarEvents'
import useStyles from './style'

const SCalendar = (): JSX.Element => {
    const classes = useStyles()
    const CalendarEvents = useCalendarEvents()

    return (
        <Box className={classes.borderBottom}>
            <Box className={classes.section}>
                <UGrid.UContainer>
                    <UTitle
                        mdFont={32}
                        lgFont={32}
                        xlFont={42}
                        className={classes.sectionTitle}
                    >
                        Календарь событий
                    </UTitle>
                    <div className={classes.wrap}>
                        <UEventCalendar>
                            {CalendarEvents.list.map((event) => {
                                const {
                                    description,
                                    title,
                                    start,
                                    end,
                                    id,
                                    speaker,
                                } = event
                                return (
                                    <UEventLine
                                        key={id}
                                        avatar={
                                            speaker.image && (
                                                <img
                                                    src={speaker.image.url}
                                                    alt=""
                                                />
                                            )
                                        }
                                    >
                                        <UEvent from={start.date} to={end.date}>
                                            {({ size }) => (
                                                <WEvent
                                                    title={title}
                                                    mini={size < 2}
                                                    progress={event.progress}
                                                    status={
                                                        event.status ||
                                                        event.specialization
                                                            ?.machineName
                                                    }
                                                >
                                                    {description}
                                                </WEvent>
                                            )}
                                        </UEvent>
                                    </UEventLine>
                                )
                            })}
                        </UEventCalendar>
                    </div>
                    <Box className={classes.legends}>
                        <WLegendLabelList />
                    </Box>
                </UGrid.UContainer>
            </Box>
        </Box>
    )
}

export default SCalendar
