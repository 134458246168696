/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, makeStyles, Theme } from '@material-ui/core'
import React, { Children, useMemo } from 'react'
import moment, { Moment, MomentInput } from 'moment-ru'
import clsx from 'clsx'
import UEvent from './UEvent'

/**
 * @constant STEP - ширина одного дня
 */
const STEP = 102

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        position: 'relative',
        border: '1px solid #EBEDEF',
        display: 'flex',
    },
    left: {
        backgroundColor: '#F6F7F9',
        flexShrink: 0,
        width: 68,
        [theme.breakpoints.up('md')]: {
            width: 98,
        },
    },
    main: {
        'overflow-x': 'scroll',
        '-ms-overflow-style': 'none',
        overflow: '-moz-scrollbars-none',
        '&::-webkit-scrollbar': {
            width: 0,
            height: 0,
        },
    },
    header: {
        display: 'flex',
        borderBottom: '1px solid #EBEDEF',
    },
    td: {
        width: STEP - STEP / 4,
        paddingTop: 32,
        paddingBottom: 22,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flexShrink: 0,
        [theme.breakpoints.up('md')]: {
            width: STEP,
        },
    },
    tdLeft: {
        width: 68,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            width: 98,
        },
    },
    tdTitle: {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 18,
        lineheight: '115%',
        letterspacing: -0.4,
        textTransform: 'uppercase',
        color: '#4C4C52',
    },
    tdSubtitle: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 15,
        lineHeight: '140%',
        letterSpacing: 0.1,
        color: '#B8B8C3',
        marginTop: 8,
    },
    fixed: {
        position: 'absolute',
        zIndex: 2,
        left: 0,
    },
    today: {
        position: 'relative',
        '&::before': {
            content: "''",
            backgroundColor: '#F6F7F9',
            position: 'absolute',
            display: 'block',
            top: 0,
            left: 0,
            right: 0,
            zIndex: -1,
            height: '100vh',
        },
    },
    dateBox: {
        display: 'flex',
    },
    content: ({ daysCount = 0 }: TUseStyleProps) => ({
        overflow: 'hidden',
        width: (STEP - STEP / 4) * daysCount,
        [theme.breakpoints.up('md')]: {
            width: STEP * daysCount,
        },
    }),
    lines: {},
    eventline: {
        display: 'flex',
        minHeight: 64,
        alignItems: 'center',
        '&:last-child': {
            '& $line': {
                borderBottom: 'none',
            },
        },
    },
    avatarTd: {
        paddingTop: 8,
        paddingBottom: 8,
        height: 'inherit',
    },
    lineAvatar: {
        width: 48,
        height: 48,
        borderRadius: 4,
        backgroundColor: '#B8B8C3',
        '& > *': {
            width: '100%',
            height: '100%',
        },
    },
    line: {
        paddingTop: 4,
        paddingBottom: 4,
        flexGrow: 1,
        display: 'flex',
        position: 'relative',
        minHeight: 'inherit',
        borderBottom: '1px solid #EBEDEF',
    },
    event: {
        position: 'relative',
        display: 'flex',
    },
}))

const today: Moment = moment().startOf('day')

interface IUEventCalendar {
    // TODO: Типизировать child
    children?: React.ReactNode
    daysCount?: number
    now?: Moment
}
type TUseStyleProps = Omit<IUEventCalendar, 'children'>

const UEventCalendar: React.FC = (props: IUEventCalendar) => {
    const { children, daysCount = 28, now = today } = props

    const classes = useStyles({ daysCount })
    const start: Moment = now.clone().subtract(2, 'day')
    const last: Moment = start.clone().add(daysCount, 'day')
    const days: Moment[] = []

    for (let i = 0; i < daysCount; i++) {
        const day = start.clone().add(i, 'day')
        days.push(day)
    }

    type TChildren = React.ComponentProps<typeof UEventLine>

    const lines = Children.map(children, (child) => {
        if (React.isValidElement<TChildren>(child)) {
            return React.cloneElement<TChildren>(child, {
                ...child.props,
                startOf: start,
                lastOf: last,
                now,
                stepCount: daysCount,
            })
        }
        return null
    })

    return (
        <div className={classes.root}>
            <div className={classes.left} />
            <div className={classes.main}>
                <Box className={classes.content}>
                    <div className={classes.header}>
                        <div
                            className={clsx(
                                classes.fixed,
                                classes.td,
                                classes.tdLeft
                            )}
                        >
                            <div className={classes.tdTitle}>{now.year()}</div>
                            <div className={classes.tdSubtitle}>
                                {now.format('MMM')}
                            </div>
                        </div>

                        <div className={classes.dateBox}>
                            {days.map((day) => (
                                <div
                                    className={clsx(classes.td, {
                                        [classes.today]:
                                            day.date() === now.date(),
                                    })}
                                    key={day.date()}
                                >
                                    <div className={classes.tdTitle}>
                                        {day.date()}
                                    </div>
                                    <div className={classes.tdSubtitle}>
                                        {day.format('ddd')}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={classes.lines}>{lines}</div>
                </Box>
            </div>
        </div>
    )
}

/**
 * Вычисляют кол-во дней (включая сами даты) между 2-мя датами и возвращают ее в днях
 *
 * @param from - Начальная дата
 * @param to - Конечная дата
 * @returns
 */
const diffDay = (from: Moment, to: Moment) => {
    let result: number = to.diff(from, 'day')
    if (result > 0) result++
    return result
}

/**
 * Задает промежуток для возвращаемой функции
 *
 * @param from
 * @param to
 * @returns
 */
const createBetween = (from: Moment, to: Moment) =>
    /**
     * Функция которая проверяет, входит ли данная в имеющиеся промежуток
     *
     * @param date - Проверяемая дата
     * @returns
     */
    (date: MomentInput): boolean =>
        moment(date).isBetween(from, to, 'day', '[]')

/**
 * линия на календаре событий
 *
 * @param avatar - Картинка слева  ( либо img либо svg )
 */

interface IEventLine {
    children?: React.ReactNode
    avatar?: React.ReactNode
    startOf?: Moment
    lastOf?: Moment
    now?: Moment
    stepCount?: number
}

export const UEventLine: React.FC<IEventLine> = ({
    children,
    avatar,
    now = moment(),
    startOf = now.startOf('day').subtract(2, 'day'),
    lastOf = now.clone().add(7, 'day'),
    stepCount = 0,
}: IEventLine) => {
    const classes = useStyles({})
    const isBetween = createBetween(startOf, lastOf)

    type TChildren = React.ComponentProps<typeof UEvent>

    const events: React.ReactNode = useMemo(
        () =>
            Children.map(children, (child) => {
                if (!React.isValidElement<TChildren>(child)) return null
                const from = moment(child.props.from)
                const to = moment(child.props.to)
                // Если не попадает под промежуток то не отдаем элемент
                if (!isBetween(from) && !isBetween(to)) return null

                const size = diffDay(from, to) || 1
                const offset: number = from.diff(startOf, 'day')
                return React.cloneElement<TChildren>(child, {
                    ...child.props,
                    size,
                    step: STEP,
                    offset,
                    stepCount,
                })
            }),
        [children, startOf, lastOf]
    )
    return (
        <div className={classes.eventline}>
            <div
                className={clsx(
                    classes.tdLeft,
                    classes.fixed,
                    classes.avatarTd
                )}
            >
                <div className={classes.lineAvatar}>{avatar}</div>
            </div>
            <div className={classes.line}>{events}</div>
        </div>
    )
}

export default UEventCalendar
export { UEvent }
