import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: 40,
    },
    wrap: {
        margin: `0 -${theme.spacing(6) / 2}px`,
        [theme.breakpoints.up('md')]: {
            margin: 0,
        },
    },
    legend: {
        marginTop: 40,
    },
}))
export default useStyles
