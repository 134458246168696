import { makeStyles, Theme } from '@material-ui/core/styles'

interface Props {
    adaptiveDark: boolean
    menuColor: string
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        padding: '48px 0 0 0',
        alignItems: 'center',
        zIndex: 1,
        [theme.breakpoints.down('md')]: {
            justifyContent: 'space-between',
        },
        [theme.breakpoints.down('md')]: {
            justifyContent: 'space-between',
            padding: '31px 0 0 0',
        },
    },
    overlay: {
        [theme.breakpoints.down('md')]: {
            backgroundColor: ({ menuColor, adaptiveDark }: Props) => {
                if (adaptiveDark) {
                    return ' '
                }
                return menuColor
            },
        },
    },

    logoblock: {
        marginRight: '56px',
    },

    headercontents: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'space-between',
            width: 'auto',
        },
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },

    nav: {
        display: 'flex',
        alignItems: 'center',
    },

    navlink: {
        marginRight: '8px',
        minWidth: '116px',

        a: {
            textDecoration: 'none',
            color: 'green',
        },
    },

    widenavlink: {
        minWidth: '199px',
    },

    allcourses: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },

    input: {
        marginRight: '29px',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },

    avatarblock: {
        marginLeft: '16px',
        order: 3,
    },
    arrowBack: {
        display: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'block',
        },
    },
    breakpointBtnLg: ({ adaptiveDark }: Props) => {
        if (adaptiveDark) {
            return {
                [theme.breakpoints.down('lg')]: {
                    display: 'block',
                    border: `1px solid ${theme.palette.primary.contrastText} !important`,
                    backgroundColor: 'transparent !important',
                    // TODO: изменить на цвет из theme
                    color: 'rgba(235, 237, 239, 0.6) !important',
                },
            }
        }
        // блок, меняющий цвет теста кнопок
        return {
            // [theme.breakpoints.down('lg')]: {
            //     color: `${theme.palette.text.secondary}!important `,
            // },
        }
    },
}))
export default useStyles
