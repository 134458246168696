import BaseApi from '@/api/BaseApi'
import { AxiosPromise } from 'axios'
import { TCollectionEntity } from '@/services/collection'

const CollectionsApi = {
    get(): AxiosPromise<TCollectionEntity[]> {
        return BaseApi.get('/collections')
    },
}

export { CollectionsApi }
