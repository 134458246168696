import React, { useEffect, useState } from 'react'
import {
    useHomeworkForChecking,
    useHomeworksForCheckingManager,
} from '@/services/homeworksForChecking'
import useStyles from './styleUp'
import UTabS, { UTab, UTabPanel } from '../../../components/ui/UTabs'
import CompletedTask from '../../../components/ui/UCompletedTask'
import WHomeworkCard from '../../../components/widget/WHomeworkCard'

const HomeworkLk = (): React.ReactElement => {
    const [pane, setPane] = useState<string>('1')
    const classes = useStyles()
    const HomeWorkForCheckingManager = useHomeworksForCheckingManager()
    const homeworkForChecking = useHomeworkForChecking()

    useEffect(() => {
        HomeWorkForCheckingManager.get()
    }, [])

    return (
        <div className={classes.root}>
            <h1 className={classes.title}>Проверка ДЗ </h1>
            <UTabS
                className={classes.tabPanel}
                value={pane}
                onChange={(e, value) => setPane(value)}
            >
                {homeworkForChecking.list.map((obj, index) => (
                    <UTab
                        key={obj.learningContent.id + String(Date.now)}
                        label={obj.learningContent.title}
                        value={String(index + 1)}
                    />
                ))}
            </UTabS>
            {homeworkForChecking.list.map((obj, index) => (
                <UTabPanel
                    key={obj.learningContent.id}
                    index={String(index + 1)}
                    value={pane}
                >
                    {obj.byTasks.map((item) => (
                        <div
                            key={item.task.id}
                            className={classes.programBlock}
                        >
                            <WHomeworkCard
                                categoryTask={{
                                    id: Number(item.task.id),
                                    title: item.task.title,
                                    description: item.task.description || '',
                                    countTasks: item.data.length,
                                }}
                                completedTasks={item.data.map(() => ({
                                    taskId: Number(item.task.id),
                                    categoryId: 1,
                                    fromId: Number(+item.task.mentor.id),
                                    fromName: item.task.mentor.name,
                                    fromSrc: item.task.mentor.image?.url || '',
                                    fromRole:
                                        item.task.mentor.departament
                                            .displayName || '',
                                    fromFile: '123',
                                    fromText: item.task.description || '',
                                }))}
                            >
                                <>
                                    {item.data.map((homework) => (
                                        <div
                                            style={{ marginBottom: 16 }}
                                            key={homework.id}
                                        >
                                            <CompletedTask
                                                name={homework.task.mentor.name}
                                                role={
                                                    homework.task.mentor
                                                        .departament
                                                        .displayName || ''
                                                }
                                                file="123"
                                                accompanyingText={
                                                    homework.commentary || ''
                                                }
                                                src={
                                                    item.task.mentor.image
                                                        ?.url || ''
                                                }
                                            />
                                        </div>
                                    ))}
                                </>
                            </WHomeworkCard>
                        </div>
                    ))}
                </UTabPanel>
            ))}
        </div>
    )
}
export default HomeworkLk
