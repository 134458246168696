import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import routes from '@/routes'
import UGrid from '@/components/ui/UGrid'
import SHeader from '@/sections/SHeader'
import SFooter from '@/sections/SFooter'
import BreadCrumbs from '@/components/ui/UBreadCrumbs'
import SCollectionCourse from '@/sections/SCollectionCourse'
import SCollectionLessons from '@/sections/SCollectionLessons'
import Runner from '@/utils/Runner'
import LArrowBack from '@/components/ui/UArrowBack'
import LTitle from '@/components/ui/UTitle'
import { Box } from '@material-ui/core'
import { useCollection, useCollectionsManager } from '@/services/collection'
import useStyles from './style'

// TODO: Нужно подправить верстку
const CollectionPage = (): JSX.Element => {
    const classes = useStyles()
    const params = useParams<{ id: string }>()
    const CollectionManager = useCollectionsManager()
    const Collection = useCollection()

    useEffect(() => {
        CollectionManager.get(params.id)
    }, [])

    return (
        <>
            <SHeader adaptiveMenuView="light" isGoBackForAdaptive />
            <UGrid.UContainer className={classes.wrapper}>
                <div className={classes.breadCrumbs}>
                    <BreadCrumbs>
                        <BreadCrumbs.Item to={routes.main.path}>
                            Главная
                        </BreadCrumbs.Item>
                        <BreadCrumbs.Item to="/">
                            Все коллекции
                        </BreadCrumbs.Item>
                        <BreadCrumbs.Item>
                            {Collection.entity?.title}
                        </BreadCrumbs.Item>
                    </BreadCrumbs>
                </div>
                <div className={classes.titleBlock}>
                    <div className={classes.arrowBackBlock}>
                        <LArrowBack to="/" color="#4C4C52 " />
                    </div>
                    <LTitle.H2 variant="h2v3">
                        {Collection.entity?.title}
                    </LTitle.H2>
                </div>
                {Collection.entity?.description && (
                    <span className={classes.description}>
                        {Collection.entity?.description}
                    </span>
                )}
            </UGrid.UContainer>
            {Collection.entity?.content && (
                <UGrid.UContainer>
                    <Box className={classes.editorWrapper}>
                        <Runner content={Collection.entity?.content} />
                    </Box>
                </UGrid.UContainer>
            )}
            <SCollectionCourse />
            <SCollectionLessons />
            <SFooter />
        </>
    )
}

export default CollectionPage
