import React from 'react'
import clsx from 'clsx'
import useStyles from './style'

interface DailyTotalProps {
    title: string
    progressHours: number
    totalHours?: number
    progressMinutes?: number
    total?: boolean
}

const DailyTotal: React.FC<DailyTotalProps> = ({
    title,
    progressHours,
    totalHours,
    progressMinutes,
    total,
}: DailyTotalProps): React.ReactElement => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <span className={classes.titleblock}>{title}</span>
            <div>
                {total ? (
                    <div className={classes.root}>
                        <span
                            className={clsx([[classes.large], [classes.total]])}
                        >
                            {progressHours}
                        </span>
                        <span
                            className={clsx([[classes.small], [classes.total]])}
                        >
                            часов
                        </span>
                        <span
                            className={clsx([[classes.large], [classes.total]])}
                            style={{ marginLeft: '16px' }}
                        >
                            {progressMinutes}
                        </span>
                        <span
                            className={clsx([[classes.small], [classes.total]])}
                        >
                            минуты
                        </span>
                    </div>
                ) : (
                    <>
                        <span
                            className={clsx([
                                [classes.large],
                                [classes.primary],
                            ])}
                        >
                            {progressHours}
                        </span>
                        <span
                            className={clsx([
                                [classes.small],
                                [classes.secondary],
                            ])}
                        >
                            ({totalHours})
                        </span>
                    </>
                )}
            </div>
        </div>
    )
}
export default DailyTotal
