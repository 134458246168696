import React from 'react'
import { useModule } from '@/services/module'
import { Box, useMediaQuery } from '@material-ui/core'
import theme from '../../theme'
import UGrid from '../../components/ui/UGrid'
import WInfoProgram from '../../components/widget/WInfoProgram'
import useStyles from './style'

const SInfoProgram = (): JSX.Element => {
    const classes = useStyles()
    const Module = useModule()
    const screenOver1080 = useMediaQuery(theme.breakpoints.up('xl'))
    return (
        <Box className={classes.section}>
            <UGrid.UContainer>
                <WInfoProgram
                    title={Module.entity?.title || ''}
                    deadline="Пройти до  13 марта 2000"
                    progress={80}
                    progressView={screenOver1080 ? 'asStatus' : 'asProgress'}
                    typeProgram={Module.entity?.specialization.title}
                >
                    {Module.entity?.description || null}
                </WInfoProgram>
            </UGrid.UContainer>
        </Box>
    )
}

export default SInfoProgram
