import React, { useEffect } from 'react'

import Header from '@/sections/SHeader'
import SLearningPrograms from '@/sections/SLearningPrograms'
import UFooter from '@/sections/SFooter'
import { useLearningProgramsManager } from '@/services/learningPrograms'

const MainPage = (): JSX.Element => {
    const coursesSettings = {
        type: 'all',
        title: 'Программы обучения',
        initialSelectedTab: '',
        tabs: [
            { id: '0', label: 'Активные', value: '' },
            { id: '1', label: 'Не активные', value: 'passed' },
        ],
    }
    const LearningProgramsManager = useLearningProgramsManager()
    useEffect(() => {
        LearningProgramsManager.get()
    }, [])
    return (
        <>
            <Header />
            <SLearningPrograms settings={coursesSettings} />
            <UFooter />
        </>
    )
}

export default MainPage
