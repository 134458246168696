import { makeStyles } from '@material-ui/core/styles'
// TODO звсенить цвета с темы
const useStyles = makeStyles(() => ({
    root: {
        marginBottom: 30,
    },
    deadline: {
        marginBottom: '32px',
    },
}))
export default useStyles
