import React, { useEffect } from 'react'

import { useSpeakersManager } from '@/services/speakers'
import { useCalendarEventsManager } from '@/services/calendarEvents'
import { useLessonsManager } from '@/services/lessons'
import MainContent from '@/sections/SMainContent'
import SProgress from '@/sections/SProgress'
import SCourses from '@/sections/SCourses'
import SCalendar from '@/sections/SCalendar'
import SSpeakers from '@/sections/SSpeakers'
import SFooter from '@/sections/SFooter'
import { useModulesManager } from '@/services/modules'

const MainPage = (): JSX.Element => {
    const speakersManager = useSpeakersManager()
    const calendarEventManager = useCalendarEventsManager()
    const LessonsManager = useLessonsManager()
    const ModulesManager = useModulesManager()

    const coursesSettings = {
        title: 'Курсы',
        initialSelectedTab: '',
        tabs: [
            { id: '0', label: 'Все', value: '' },
            { id: '1', label: 'Профильные', value: 'primary' },
            { id: '2', label: 'Надпрофильные', value: 'secondary' },
            { id: '3', label: 'Общие', value: 'common' },
        ],
    }
    useEffect(() => {
        speakersManager.get()
        calendarEventManager.get()
        LessonsManager.get()
        ModulesManager.get()
    }, [])
    return (
        <>
            <MainContent />
            <SProgress />
            <SCourses settings={coursesSettings} />
            <SCalendar />
            <SSpeakers />
            <SFooter />
        </>
    )
}

export default MainPage
