import { makeStyles } from '@material-ui/core/styles'
// TODO звсенить цвета с темы
const useStyles = makeStyles(() => ({
    root: {},
    deadline: {
        marginBottom: 32,
    },
    content: {},
    completed: {
        color: '#6BD689',
    },
    inProgress: {},
}))
export default useStyles
