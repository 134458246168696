export const btnAnimate = {
    downArrow: {
        rotate: 180,
    },
    upArrow: {
        rotate: 0,
    },
}
export const taskBlockAnimate = {
    show: {
        opacity: 1,
        rotateY: 0,
    },
    hidden: {
        opacity: 0,
        rotateY: -15,
    },
}
