import React from 'react'
import UNotification from '@/components/ui/UNotification'
import UButtonNotification from '@/components/ui/UButtonNotification'
import { useUser } from '@/services/user'
import { Link } from 'react-router-dom'
import useStyles from './style'

// TODO: Уведомления приходят из вне
const NotificationSection = () => {
    const classes = useStyles()
    const User = useUser()
    return (
        <div className={classes.root}>
            <div>
                <h1 className={classes.title}>Уведомления </h1>
                {User.notifications?.map((notification) => {
                    const {
                        date,
                        id,
                        message,
                        title,
                        action,
                        isRead,
                        type,
                    } = notification
                    return (
                        <div className={classes.notificationBlock} key={id}>
                            <UNotification
                                date={date}
                                status={type}
                                title={title || ''}
                                description={message}
                            >
                                {(action && (
                                    <Link to={action.url}>
                                        <UButtonNotification variant="check" />
                                    </Link>
                                )) ||
                                    (isRead && (
                                        <UButtonNotification variant="success" />
                                    ))}
                            </UNotification>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
export default NotificationSection
