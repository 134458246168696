import React from 'react'
import { Container, useMediaQuery, useTheme } from '@material-ui/core'
import { useWindowSize } from 'react-use'
import Chart from '../../ui/UChart'
import useStyles from './style'
import DailyTotal from './components/daily-progress-total'
import UTitle from '../../ui/UTitle'
// TODO Убрать внутренние компоненты
const mocData = {
    daily: [
        {
            id: 1,
            hours: 3,
            minutes: 50,
        },
        {
            id: 2,
            hours: 6,
            minutes: 0,
        },
        {
            id: 3,
            hours: 0,
            minutes: 10,
        },
        {
            id: 4,
            hours: 0,
            minutes: 15,
        },
        {
            id: 5,
            hours: 8,
            minutes: 10,
        },
        {
            id: 6,
            hours: 0,
            minutes: 0,
        },
        {
            id: 7,
            hours: 0,
            minutes: 20,
        },
    ],
    totalHours: 17,
    totalMinutes: 5,
    commonHours: 100,
    profileHours: 24,
    overProfileHours: 40,
    totalProfileHours: 15,
    totalOverProfileHours: 2,
}

// TODO: В будущем перенести в локальные утилиты
type MocDataType = typeof mocData
const turnIntoChartData = (moc: MocDataType) =>
    moc.daily.map((d) => ({
        id: d.id,
        x: d.id - 1,
        y: d.hours + +(d.minutes / 60).toFixed(1),
    }))
enum SpacesBetweenContainer {
    default = 33,
}
enum DefaultHeightChart {
    small = 226,
    medium = 310,
}
enum DefaultWidthChart {
    medium = 706,
}
enum DefaultSpaceChart {
    small = 0.93,
    medium = 0.8,
}
const DailyProgress = (): React.ReactElement => {
    const classes = useStyles()
    const theme = useTheme()
    const screenDown768 = useMediaQuery(theme.breakpoints.down('sm'))
    const screenDown1024 = useMediaQuery(theme.breakpoints.down('md'))
    const size = useWindowSize()

    const getChartWidth = () => {
        if (screenDown768) {
            return size.width - SpacesBetweenContainer.default
        }
        return DefaultWidthChart.medium
    }
    const getChartHeight = () => {
        if (screenDown768) {
            return DefaultHeightChart.medium
        }
        return DefaultHeightChart.small
    }
    const getChartSpace = () => {
        if (screenDown768) {
            return DefaultSpaceChart.medium
        }
        return DefaultSpaceChart.small
    }

    return (
        <Container>
            <div className={classes.root}>
                <UTitle.H2 variant="h2v1" className={classes.titleBlock}>
                    Ваш прогресс
                </UTitle.H2>
                <div className={classes.chartBlock}>
                    <Chart
                        height={getChartHeight()}
                        width={getChartWidth()}
                        data={turnIntoChartData(mocData)}
                        mainColor="#3AB2C9"
                        gridColor="#F6F7F9"
                        spaceBetweenColumns={getChartSpace()}
                        // TODO: (NotImportantNow) убрать center, когда будет контейнер
                        center={!!screenDown1024}
                    />
                </div>
                <div className={classes.totalCategory}>
                    <div className={classes.totalCategoryItem}>
                        <DailyTotal
                            title="Общие"
                            progressHours={mocData.totalHours}
                            totalHours={mocData.commonHours}
                        />
                    </div>
                    <div className={classes.totalCategoryItem}>
                        <DailyTotal
                            title="Профильные"
                            progressHours={mocData.totalProfileHours}
                            totalHours={mocData.profileHours}
                        />
                    </div>
                    <div className={classes.totalCategoryItem}>
                        <DailyTotal
                            title="Надпрофильные"
                            progressHours={mocData.totalOverProfileHours}
                            totalHours={mocData.overProfileHours}
                        />
                    </div>
                </div>
                <div className={classes.totalCommon}>
                    <DailyTotal
                        title="Всего времени в обучении "
                        progressHours={mocData.totalHours}
                        progressMinutes={mocData.totalMinutes}
                        total
                    />
                </div>
            </div>
        </Container>
    )
}
export default DailyProgress
