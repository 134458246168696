import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        height: '100vh',
        backgroundColor: '#3ab2c9',
        padding: '31px 26px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },

    menuHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        // marginBottom: '174.5px' ,
    },

    menuNav: {},
    // content: {
    //     position: 'relative',
    //     bottom: 110,
    // },
    menuNavList: {
        // marginBottom: '157px',
    },

    menuNavItem: {
        marginBottom: '25px',
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: '120%',
        letterSpacing: '-0.2px',

        '&:last-child': {
            marginBottom: '0px',
        },
    },

    menuNavItemLink: {
        color: '#fff',
        position: 'relative',
    },
}))
export default useStyles
