import { makeStyles, Theme } from '@material-ui/core/styles'

// TODO: breakpoints
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '191px',
        height: '296px',
        justifyContent: 'center',
        cursor: 'grab',
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#fff !important',
            width: '151px',
        },
        '&:hover': {
            backgroundColor: '#F6F7F9',
            '& $name': {
                color: '#3AB2C9',
            },
        },
        padding: '20px',
        [theme.breakpoints.up('md')]: {
            padding: '40px 32px',
        },
    },

    name: {
        fontSize: '15px',
        lineHeight: '140%',
        textAlign: 'center',
        fontWeight: 500,
        letterSpacing: '0.1px',
        fontStyle: 'normal',
        color: '#4C4C52',
        marginBottom: '10px',
        marginTop: '24px',
    },

    position: {
        fontSize: '10px',
        lineHeight: '140%',
        textAlign: 'center',
        letterSpacing: '0.2px',
        fontStyle: 'normal',
        color: '#b8b8c3',
    },
    adaptiveAvatar: {
        [theme.breakpoints.down('sm')]: {
            width: '108px ',
            height: '108px',
        },
    },
}))
export default useStyles
