import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
    section: {
        padding: '0',
        margin: '64px -16px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',

        [theme.breakpoints.up('md')]: {
            padding: '0',
            margin: '80px 16px',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: '710px',
            margin: '80px auto',
        },
        [theme.breakpoints.up('xl')]: {
            padding: '0',
            margin: '0',
            justifyContent: 'flex-start',
            width: '49%',
            borderBottom: 'none',
        },
    },
    progress: {
        margin: '0 -16px',
        [theme.breakpoints.up('md')]: {
            margin: '0 -24px',
        },
    },
    sectionTitle: {
        marginBottom: 42,
        alignSelf: 'center',
        [theme.breakpoints.up('md')]: {
            alignSelf: 'flex-start',
        },
    },
    borderBottom: {
        borderTop: '1px solid #EBEDEF',
        borderBottom: '1px solid #EBEDEF',
    },
    wrapper: {
        [theme.breakpoints.up('md')]: {
            padding: '0',
        },
        [theme.breakpoints.up('xl')]: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '132px 0 220px 0',
        },
    },
}))
export default useStyles
