/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { Box, Typography } from '@material-ui/core'
import WCardCollection from '@/components/widget/WCard/Collection'
import WCourseLessons from '@/components/widget/WCourseLessons'
import WTitleCourseLink from '@/components/widget/WTitleCourseLink'
import UGrid from '@/components/ui/UGrid'
import { useModule } from '@/services/module'
import { useCollectionsManager } from '@/services/collections'
import routes from '@/routes'
import useStyles from './style'

const SModuleCollection: React.FC = () => {
    const classes = useStyles()
    const Module = useModule()
    const CollectionsManager = useCollectionsManager()

    if (!Module.entity?.collections) {
        return null
    }
    return (
        <div className={classes.section}>
            <UGrid.UContainer>
                <div className={classes.content}>
                    <UGrid.URow className={classes.rowBlock}>
                        <UGrid.UCol
                            xs={12}
                            xl={12}
                            className={classes.colBlock}
                        >
                            <Box className={classes.collectionWrapper}>
                                {Module.entity?.collections?.map(
                                    (collection) => {
                                        const {
                                            id,
                                            title,
                                            favorited,
                                            lessons,
                                            courses,
                                            materials,
                                        } = collection
                                        return (
                                            <WCardCollection
                                                to={routes.collection.to(id)}
                                                key={id}
                                                title={title}
                                                favorited={favorited}
                                                onChangeFavorited={CollectionsManager.toggleFavorite(
                                                    collection
                                                )}
                                            >
                                                <>
                                                    {Module.entity
                                                        ?.description && (
                                                        <Typography
                                                            variant="body1"
                                                            style={{
                                                                paddingBottom: 16,
                                                            }}
                                                        >
                                                            {
                                                                Module.entity
                                                                    ?.description
                                                            }
                                                        </Typography>
                                                    )}
                                                    <WCourseLessons
                                                        className={
                                                            classes.lessonBlock
                                                        }
                                                    >
                                                        <>
                                                            {lessons &&
                                                                lessons.map(
                                                                    (
                                                                        lesson
                                                                    ) => (
                                                                        <WTitleCourseLink
                                                                            to={routes.lesson.to(
                                                                                lesson.id
                                                                            )}
                                                                            key={
                                                                                lesson.id
                                                                            }
                                                                            status={
                                                                                lesson.status ===
                                                                                'passed'
                                                                                    ? 'completed'
                                                                                    : 'inProgress'
                                                                            }
                                                                            title={
                                                                                lesson.title
                                                                            }
                                                                        />
                                                                    )
                                                                )}
                                                            {courses &&
                                                                courses.map(
                                                                    (
                                                                        course
                                                                    ) => (
                                                                        <WTitleCourseLink
                                                                            to={routes.course.to(
                                                                                course.id
                                                                            )}
                                                                            key={
                                                                                course.id
                                                                            }
                                                                            status={
                                                                                course.status ===
                                                                                'passed'
                                                                                    ? 'completed'
                                                                                    : 'inProgress'
                                                                            }
                                                                            title={
                                                                                course.title
                                                                            }
                                                                        />
                                                                    )
                                                                )}
                                                            {materials &&
                                                                materials.map(
                                                                    (
                                                                        material
                                                                    ) => (
                                                                        <WTitleCourseLink
                                                                            to={routes.material.to(
                                                                                material.id
                                                                            )}
                                                                            key={
                                                                                material.id
                                                                            }
                                                                            title={
                                                                                material.title
                                                                            }
                                                                        />
                                                                    )
                                                                )}
                                                        </>
                                                    </WCourseLessons>
                                                </>
                                            </WCardCollection>
                                        )
                                    }
                                )}
                            </Box>
                        </UGrid.UCol>
                    </UGrid.URow>
                </div>
            </UGrid.UContainer>
        </div>
    )
}

export default SModuleCollection
