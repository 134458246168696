import { withStyles, Grid } from '@material-ui/core'
import React from 'react'

const URow = withStyles(() => ({
    root: {
        marginTop: 0,
        marginBottom: 0,
    },
}))(({ children, ...rest }: React.ComponentProps<typeof Grid>) => (
    <Grid spacing={6} {...rest} container>
        {children}
    </Grid>
))

export default URow
