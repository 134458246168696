import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
    section: {
        [theme.breakpoints.up('lg')]: {
            padding: '95px 0 ',
        },
    },
}))
export default useStyles
