import React from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import useStyles from './style'

interface IWSpeakersScroll {
    children: React.ReactNode
}
const WSpeakersScroll: React.FC<IWSpeakersScroll> = ({
    children,
}: IWSpeakersScroll): React.ReactElement => {
    const classes = useStyles()
    return (
        <>
            <ScrollContainer className={classes.root}>
                {children}
            </ScrollContainer>
        </>
    )
}
export default WSpeakersScroll
