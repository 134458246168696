import React from 'react'
import UCard from '@/components/ui/UCard'
import { useHistory } from 'react-router-dom'
import useStyles from './style'

type IWCardCourse = {
    title: string
    children: React.ReactNode
    favorited: boolean
    onChangeFavorited?(favorited: boolean): void
    to?: string
}

const WCardCollection: React.FC<IWCardCourse> = ({
    title,
    children,
    favorited,
    onChangeFavorited,
    to,
}: IWCardCourse): React.ReactElement => {
    const classes = useStyles()
    const history = useHistory()

    const changeFavoritedHandler = (e: React.MouseEvent) => {
        e.stopPropagation()
        onChangeFavorited?.(favorited)
    }
    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            className={classes.root}
            onClick={(e) => {
                e.stopPropagation()
                if (to) history.push(to)
            }}
        >
            <UCard.TypeTwo
                onChange={changeFavoritedHandler}
                value={favorited}
                title={title}
            >
                <div>{children}</div>
            </UCard.TypeTwo>
        </div>
    )
}
export default WCardCollection
