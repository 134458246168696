import React from 'react'
import clsx from 'clsx'
import UHeader from '@/sections/SHeader'
import SFooter from '@/sections/SFooter'
import UGrid from '../../../components/ui/UGrid'
import useStyles from './style'
import LNavLinkNavigation from './components/LNavLinksNavigation'
import LNavLinkNavigationFixed from './components/LNavLinkNavigationFixed'
import LTitle from '../../../components/ui/UTitle'

interface IProfileModeratorLayout {
    children: React.ReactChild
}
const ProfileModeratorLayout: React.FC<IProfileModeratorLayout> = ({
    children,
}: IProfileModeratorLayout): JSX.Element => {
    const classes = useStyles()

    return (
        <>
            <UHeader isGoBackForAdaptive />
            <UGrid.UContainer>
                <div className={classes.root}>
                    <div className={classes.leftSide}>
                        <LTitle.H1
                            variant="h1v1"
                            xlFont={24}
                            className={classes.title}
                        >
                            Личный кабинет
                        </LTitle.H1>
                        <div className={classes.hiddenIfDown1920}>
                            <LNavLinkNavigation />
                        </div>
                    </div>
                    <div className={classes.rightSide}>{children}</div>
                </div>
            </UGrid.UContainer>
            <div className={clsx(classes.panelBar, classes.showIfDown1920)}>
                <LNavLinkNavigationFixed />
            </div>
            <SFooter />
        </>
    )
}
export default ProfileModeratorLayout
