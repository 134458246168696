import React from 'react'

import { Container } from '@material-ui/core'
import FooterLogo from '@/components/ui/ULogo/TypeTwo'
import facebookSVG from '@/components/svg/icons/social/facebook.svg'

import instSVG from '@/components/svg/icons/social/inst.svg'
import vkSVG from '@/components/svg/icons/social/vk.svg'
import useStyles from './style'

function SFooter(): React.ReactElement {
    const classes = useStyles()
    return (
        <div style={{ backgroundColor: '#F6F7F9', marginTop: 'auto' }}>
            <Container maxWidth="lg">
                <div className={classes.root}>
                    <div className={classes.footerblock}>
                        <div className={classes.logoBlock}>
                            <FooterLogo />
                        </div>
                        <div className={classes.links}>
                            <ul className={classes.linksitems}>
                                <li className={classes.linksitem}>
                                    <a
                                        href="https://vk.com"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img src={vkSVG} alt="vk" />
                                    </a>
                                </li>
                                <li className={classes.linksitem}>
                                    <a
                                        href="https://www.facebook.com/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img src={facebookSVG} alt="facebook" />
                                    </a>
                                </li>
                                <li className={classes.linksitem}>
                                    <a
                                        href="https://www.instagram.com/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img src={instSVG} alt="instagram" />
                                    </a>
                                </li>
                            </ul>
                            <a
                                className={classes.linksdom}
                                target="_blank"
                                href="https://www.legenda-dom.ru"
                                rel="noreferrer"
                            >
                                www.legenda-dom.ru
                            </a>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default SFooter
