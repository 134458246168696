import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {},
    title: {
        fontWeight: 500,
        fontSize: 32,
        lineHeight: '120%',
        letterSpacing: -0.3,
        color: '#000000',
        marginBottom: 56,
    },
    tabPanel: {
        marginBottom: 40,
    },
    notificationBlock: {
        margin: `0 -${theme.spacing(6) / 2}px`,
        marginBottom: 16,

        [theme.breakpoints.up('md')]: {
            margin: 0,
            marginBottom: 16,
        },
    },
}))
export default useStyles
