import React, { useEffect } from 'react'

import SCourseModule from '@/sections/SModuleCourse'
import SLessonsModule from '@/sections/SModuleLessons'

import SModuleCollection from '@/sections/SModuleCollection'
import SHeader from '@/sections/SHeader'
import SSpeakers from '@/sections/SSpeakers'
import SInfoProgram from '@/sections/SModuleInfoProgram'
import SFooter from '@/sections/SFooter'
import { useParams } from 'react-router-dom'
import { useModule, useModuleManager } from '@/services/module'
import Runner from '@/utils/Runner'
import UGrid from '@/components/ui/UGrid'
import { useSpeakersManager } from '@/services/speakers'

const ModulePage = (): JSX.Element => {
    const params = useParams<{ id: string }>()
    const ModuleManager = useModuleManager()
    const Speakers = useSpeakersManager()
    const Module = useModule()
    useEffect(() => {
        ModuleManager.get(params.id)
        Speakers.get()
        return () => {
            ModuleManager.clear()
        }
    }, [])

    return (
        <>
            <SHeader adaptiveMenuView="primary" isGoBackForAdaptive />
            <SInfoProgram />
            {/* // TODO Сделать секцию */}
            {Module.entity?.content && (
                <UGrid.UContainer>
                    <Runner content={Module.entity.content} />
                </UGrid.UContainer>
            )}
            <SModuleCollection />
            <SCourseModule />
            <SLessonsModule />
            <SSpeakers isModule />
            <SFooter />
        </>
    )
}

export default ModulePage
