import BaseApi, { TUserShort } from '@/api/BaseApi'
import { AxiosPromise } from 'axios'

const SpeakersApi = {
    get(): AxiosPromise<TUserShort[]> {
        return BaseApi.get('/speakers')
    },
}

export { SpeakersApi }
