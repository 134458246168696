import BaseApi from '@/api/BaseApi'
import { AxiosPromise } from 'axios'
import type { TActionLink } from '@/api/BaseApi'

export type TNotificationType =
    | 'common'
    | 'newCourse'
    | 'reminder'
    | 'taskAccepted'
    | 'taskNotAccepted'
    | 'deadline'
    | 'certificate'

export type TNotificationEntity = {
    id: string
    title?: string
    message: string
    action?: TActionLink
    date: string
    isRead: boolean
    type: TNotificationType
}

const NotificationsApi = {
    get(): AxiosPromise<TNotificationEntity[]> {
        return BaseApi.get('/notifications')
    },
}

export { NotificationsApi }
