import React from 'react'
import { Box } from '@material-ui/core'

import UGrid from '@/components/ui/UGrid'
import WInfoProgram from '@/components/widget/WInfoProgram'
import moment from 'moment-ru'
import { useCourse } from '@/services/course'
import useStyles from './style'

const SCourseHeader = (): JSX.Element => {
    const classes = useStyles()
    const Course = useCourse()
    return (
        <Box className={classes.section}>
            <UGrid.UContainer>
                <WInfoProgram
                    title={Course.entity?.title || ''}
                    deadline={`Пройти до ${moment().format('DD MMMM YYYY')}`}
                    progress={Course.entity?.progress || 0}
                    typeProgram={Course.entity?.specialization.title}
                >
                    <>{Course.entity?.description}</>
                </WInfoProgram>
            </UGrid.UContainer>
        </Box>
    )
}

export default SCourseHeader
