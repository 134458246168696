import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
    root: {},
    XAxis: {
        backgroundColor: 'red',
        color: 'red',
    },
    hint: {
        backgroundColor: 'black',
        borderRadius: '10px',
        padding: '10px',
        color: '#fff',
        fontSize: 10,
    },
}))
export default useStyles
